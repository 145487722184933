:root {
    font-family: "Inter var", sans-serif;
    font-feature-settings: "cv02", "cv03", "cv04", "cv11";
    font-variation-settings: normal;
    --font-family: "Inter var", sans-serif;
    --font-feature-settings: "cv02", "cv03", "cv04", "cv11";
    --text-color: #334155;
    --text-color-secondary: #64748b;
    --primary-color: #FF6A2A;
    --primary-color-text: #ffffff;
    --surface-0: #ffffff;
    --surface-50: #f8fafc;
    --surface-100: #f1f5f9;
    --surface-200: #e2e8f0;
    --surface-300: #cbd5e1;
    --surface-400: #94a3b8;
    --surface-500: #64748b;
    --surface-600: #475569;
    --surface-700: #334155;
    --surface-800: #1e293b;
    --surface-900: #0f172a;
    --surface-950: #020617;
    --gray-0: #ffffff;
    --gray-50: #f8fafc;
    --gray-100: #f1f5f9;
    --gray-200: #e2e8f0;
    --gray-300: #cbd5e1;
    --gray-400: #94a3b8;
    --gray-500: #64748b;
    --gray-600: #475569;
    --gray-700: #334155;
    --gray-800: #1e293b;
    --gray-900: #0f172a;
    --gray-950: #020617;
    --content-padding: 1.125rem;
    --inline-spacing: 0.5rem;
    --border-radius: 6px;
    --surface-ground: #f8fafc;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #e2e8f0;
    --surface-hover: #f1f5f9;
    --focus-ring: none;
    --maskbg: rgba(0, 0, 0, 0.4);
    --highlight-bg: #EFF6FF;
    --highlight-text-color: #FF6A2A;
    --p-anchor-gutter: 2px;
    color-scheme: light;
}

:root {
    --p-focus-ring-color: var(--primary-color);
}

:root {
    --blue-50: #f5f9ff;
    --blue-100: #d0e1fd;
    --blue-200: #abc9fb;
    --blue-300: #85b2f9;
    --blue-400: #609af8;
    --blue-500: #3b82f6;
    --blue-600: #326fd1;
    --blue-700: #295bac;
    --blue-800: #204887;
    --blue-900: #183462;
    --green-50: #f4fcf7;
    --green-100: #caf1d8;
    --green-200: #a0e6ba;
    --green-300: #76db9b;
    --green-400: #4cd07d;
    --green-500: #22c55e;
    --green-600: #1da750;
    --green-700: #188a42;
    --green-800: #136c34;
    --green-900: #0e4f26;
    --yellow-50: #fefbf3;
    --yellow-100: #faedc4;
    --yellow-200: #f6de95;
    --yellow-300: #f2d066;
    --yellow-400: #eec137;
    --yellow-500: #eab308;
    --yellow-600: #c79807;
    --yellow-700: #a47d06;
    --yellow-800: #816204;
    --yellow-900: #5e4803;
    --cyan-50: #f3fbfd;
    --cyan-100: #c3edf5;
    --cyan-200: #94e0ed;
    --cyan-300: #65d2e4;
    --cyan-400: #35c4dc;
    --cyan-500: #06b6d4;
    --cyan-600: #059bb4;
    --cyan-700: #047f94;
    --cyan-800: #036475;
    --cyan-900: #024955;
    --pink-50: #fef6fa;
    --pink-100: #fad3e7;
    --pink-200: #f7b0d3;
    --pink-300: #f38ec0;
    --pink-400: #f06bac;
    --pink-500: #ec4899;
    --pink-600: #c93d82;
    --pink-700: #a5326b;
    --pink-800: #822854;
    --pink-900: #5e1d3d;
    --indigo-50: #f7f7fe;
    --indigo-100: #dadafc;
    --indigo-200: #bcbdf9;
    --indigo-300: #9ea0f6;
    --indigo-400: #8183f4;
    --indigo-500: #6366f1;
    --indigo-600: #5457cd;
    --indigo-700: #4547a9;
    --indigo-800: #363885;
    --indigo-900: #282960;
    --teal-50: #f3fbfb;
    --teal-100: #c7eeea;
    --teal-200: #9ae0d9;
    --teal-300: #6dd3c8;
    --teal-400: #41c5b7;
    --teal-500: #14b8a6;
    --teal-600: #119c8d;
    --teal-700: #0e8174;
    --teal-800: #0b655b;
    --teal-900: #084a42;
    --orange-50: #fff8f3;
    --orange-100: #feddc7;
    --orange-200: #fcc39b;
    --orange-300: #fba86f;
    --orange-400: #fa8e42;
    --orange-500: #f97316;
    --orange-600: #d46213;
    --orange-700: #ae510f;
    --orange-800: #893f0c;
    --orange-900: #642e09;
    --bluegray-50: #f7f8f9;
    --bluegray-100: #dadee3;
    --bluegray-200: #bcc3cd;
    --bluegray-300: #9fa9b7;
    --bluegray-400: #818ea1;
    --bluegray-500: #64748b;
    --bluegray-600: #556376;
    --bluegray-700: #465161;
    --bluegray-800: #37404c;
    --bluegray-900: #282e38;
    --purple-50: #fbf7ff;
    --purple-100: #ead6fd;
    --purple-200: #dab6fc;
    --purple-300: #c996fa;
    --purple-400: #b975f9;
    --purple-500: #a855f7;
    --purple-600: #8f48d2;
    --purple-700: #763cad;
    --purple-800: #5c2f88;
    --purple-900: #432263;
    --red-50: #fff5f5;
    --red-100: #ffd0ce;
    --red-200: #ffaca7;
    --red-300: #ff8780;
    --red-400: #ff6259;
    --red-500: #ff3d32;
    --red-600: #d9342b;
    --red-700: #b32b23;
    --red-800: #8c221c;
    --red-900: #661814;
    --primary-50: rgba(255, 106, 42, 0.05);
    --primary-100: rgba(255, 106, 42, 0.1);
    --primary-200: rgba(255, 106, 42, 0.2);
    --primary-300: rgba(255, 106, 42, 0.3);
    --primary-400: rgba(255, 106, 42, 0.4);
    --primary-500: rgba(255, 106, 42, 0.5);
    --primary-600: rgba(255, 106, 42, 0.6);
    --primary-700: rgba(255, 106, 42, 0.7);
    --primary-800: rgba(255, 106, 42, 0.8);
    --primary-900: rgba(255, 106, 42, 0.9);
}

.p-editor-container .p-editor-toolbar {
    background: #ffffff;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid #e2e8f0;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: #64748b;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: #64748b;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: #64748b;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
    color: #334155;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
    stroke: #334155;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
    fill: #334155;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #334155;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #334155;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #334155;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 0.25rem 0.25rem;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: #334155;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    color: #1e293b;
    background: #f1f5f9;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: 0.5rem 0.75rem;
}

.p-editor-container .p-editor-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-editor-container .p-editor-content.ql-snow {
    border: 1px solid #e2e8f0;
}

.p-editor-container .p-editor-content .ql-editor {
    background: #ffffff;
    color: #334155;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
    color: #334155;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: #334155;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: #334155;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: #3B82F6;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: #3B82F6;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: #3B82F6;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: #3B82F6;
}

@layer primeng {
    * {
        box-sizing: border-box;
    }

    .p-component {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
        font-weight: normal;
    }

    .p-component-overlay {
        background-color: rgba(0, 0, 0, 0.4);
        transition-duration: 0.2s;
    }

    .p-disabled,
    .p-component:disabled {
        opacity: 0.6;
    }

    .p-error {
        color: #f87171;
    }

    .p-text-secondary {
        color: #64748b;
    }

    .pi {
        font-size: 1rem;
    }

    .p-icon {
        width: 1rem;
        height: 1rem;
    }

    .p-link {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
        border-radius: 6px;
    }

    .p-link:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-component-overlay-enter {
        animation: p-component-overlay-enter-animation 150ms forwards;
    }

    .p-component-overlay-leave {
        animation: p-component-overlay-leave-animation 150ms forwards;
    }

    @keyframes p-component-overlay-enter-animation {
        from {
            background-color: transparent;
        }

        to {
            background-color: var(--maskbg);
        }
    }

    @keyframes p-component-overlay-leave-animation {
        from {
            background-color: var(--maskbg);
        }

        to {
            background-color: transparent;
        }
    }

    .p-autocomplete .p-autocomplete-loader {
        right: 0.75rem;
    }

    .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
        right: 3.25rem;
    }

    .p-autocomplete:not(.p-disabled):hover .p-autocomplete-multiple-container {
        border-color: #94a3b8;
    }

    .p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-autocomplete .p-autocomplete-multiple-container {
        padding: 0.25rem 0.75rem;
        gap: 0.5rem;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
        padding: 0.25rem 0;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
        color: #334155;
        padding: 0;
        margin: 0;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
        padding: 0.25rem 0.75rem;
        background: #f1f5f9;
        color: #1e293b;
        border-radius: 16px;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
        margin-left: 0.5rem;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token.p-focus {
        background: #e2e8f0;
        color: #0f172a;
    }

    .p-autocomplete.p-invalid.p-component>.p-inputtext {
        border-color: #f87171;
    }

    .p-autocomplete-panel {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-autocomplete-panel .p-autocomplete-items {
        padding: 0.25rem 0.25rem;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
        margin: 2px 0;
        padding: 0.5rem 0.75rem;
        border: 0 none;
        color: #334155;
        background: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:first-child {
        margin-top: 0;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight.p-focus {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled).p-focus {
        color: #0f172a;
        background: #e2e8f0;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled):hover {
        color: #1e293b;
        background: #f1f5f9;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
        margin: 0;
        padding: 0.5rem 0.75rem;
        color: #94a3b8;
        background: #ffffff;
        font-weight: 600;
    }

    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
        padding: 0.5rem 0.75rem;
        color: #334155;
        background: transparent;
    }

    p-autocomplete.ng-dirty.ng-invalid>.p-autocomplete>.p-inputtext {
        border-color: #f87171;
    }

    p-autocomplete.p-autocomplete-clearable .p-inputtext {
        padding-right: 2.5rem;
    }

    p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
        color: #94a3b8;
        right: 0.75rem;
    }

    p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon {
        color: #94a3b8;
        right: 3.25rem;
    }

    p-calendar.ng-dirty.ng-invalid>.p-calendar>.p-inputtext {
        border-color: #f87171;
    }

    .p-calendar:not(.p-calendar-disabled).p-focus>.p-inputtext {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-datepicker {
        padding: 0.75rem;
        background: #ffffff;
        color: #334155;
        border: 1px solid #cbd5e1;
        border-radius: 6px;
    }

    .p-datepicker:not(.p-datepicker-inline) {
        background: #ffffff;
        border: 1px solid #e2e8f0;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
        background: #ffffff;
    }

    .p-datepicker .p-datepicker-header {
        padding: 0 0 0.5rem 0;
        color: #334155;
        background: #ffffff;
        font-weight: 500;
        margin: 0 0 0 0;
        border-bottom: 1px solid #e2e8f0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-prev,
    .p-datepicker .p-datepicker-header .p-datepicker-next {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
    .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
    .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title {
        line-height: 1.75rem;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        font-weight: 500;
        padding: 0.25rem;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
        color: #3B82F6;
    }

    .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
        margin-right: 0.5rem;
    }

    .p-datepicker table {
        font-size: 1rem;
        margin: 0.5rem 0 0 0;
    }

    .p-datepicker table th {
        padding: 0.25rem;
    }

    .p-datepicker table th>span {
        width: 2rem;
        height: 2rem;
    }

    .p-datepicker table td {
        padding: 0.25rem;
    }

    .p-datepicker table td>span {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border: 1px solid transparent;
    }

    .p-datepicker table td>span.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-datepicker table td>span:focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-datepicker table td.p-datepicker-today>span {
        background: #e2e8f0;
        color: #0f172a;
        border-color: transparent;
    }

    .p-datepicker table td.p-datepicker-today>span.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-datepicker .p-datepicker-buttonbar {
        padding: 0.5rem 0 0 0;
        border-top: 1px solid #e2e8f0;
    }

    .p-datepicker .p-datepicker-buttonbar .p-button {
        width: auto;
    }

    .p-datepicker .p-timepicker {
        border-top: 1px solid #e2e8f0;
        padding: 0;
    }

    .p-datepicker .p-timepicker button {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-datepicker .p-timepicker button:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-datepicker .p-timepicker button:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-datepicker .p-timepicker button:last-child {
        margin-top: 0.2em;
    }

    .p-datepicker .p-timepicker span {
        font-size: 1rem;
    }

    .p-datepicker .p-timepicker>div {
        padding: 0 0.5rem;
    }

    .p-datepicker.p-datepicker-timeonly .p-timepicker {
        border-top: 0 none;
    }

    .p-datepicker .p-monthpicker {
        margin: 0.5rem 0 0 0;
    }

    .p-datepicker .p-monthpicker .p-monthpicker-month {
        padding: 0.25rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
    }

    .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-datepicker .p-yearpicker {
        margin: 0.5rem 0 0 0;
    }

    .p-datepicker .p-yearpicker .p-yearpicker-year {
        padding: 0.25rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
    }

    .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
        border-left: 1px solid #e2e8f0;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        padding-top: 0;
        padding-bottom: 0;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
        padding-left: 0;
        border-left: 0 none;
    }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
        padding-right: 0;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
        background: #f1f5f9;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
        background: #f1f5f9;
    }

    .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
        background: #f1f5f9;
    }

    .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    p-calendar.p-calendar-clearable .p-inputtext {
        padding-right: 2.5rem;
    }

    p-calendar.p-calendar-clearable .p-calendar-clear-icon {
        color: #94a3b8;
        right: 0.75rem;
    }

    p-calendar.p-calendar-clearable .p-calendar-w-btn .p-calendar-clear-icon {
        color: #94a3b8;
        right: 3.25rem;
    }

    @media screen and (max-width: 769px) {

        .p-datepicker table th,
        .p-datepicker table td {
            padding: 0.25rem;
        }
    }

    .p-cascadeselect {
        background: #ffffff;
        border: 1px solid #cbd5e1;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
    }

    .p-cascadeselect:not(.p-disabled):hover {
        border-color: #94a3b8;
    }

    .p-cascadeselect:not(.p-disabled).p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-cascadeselect .p-cascadeselect-label {
        background: transparent;
        border: 0 none;
        padding: 0.5rem 0.75rem;
    }

    .p-cascadeselect .p-cascadeselect-label.p-placeholder {
        color: #64748b;
    }

    .p-cascadeselect .p-cascadeselect-label:enabled:focus {
        outline: 0 none;
        box-shadow: none;
    }

    .p-cascadeselect .p-cascadeselect-trigger {
        background: transparent;
        color: #94a3b8;
        width: 2.5rem;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-cascadeselect.p-invalid.p-component {
        border-color: #f87171;
    }

    .p-cascadeselect.p-variant-filled {
        background-color: #f8fafc;
    }

    .p-cascadeselect.p-variant-filled:enabled:hover {
        background-color: #f8fafc;
    }

    .p-cascadeselect.p-variant-filled:enabled:focus {
        background-color: #ffffff;
    }

    .p-cascadeselect-panel {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-cascadeselect-panel .p-cascadeselect-items {
        padding: 0.25rem 0.25rem;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
        margin: 2px 0;
        border: 0 none;
        color: #334155;
        background: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:first-child {
        margin-top: 0;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight.p-focus {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled).p-focus {
        color: #0f172a;
        background: #e2e8f0;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
        color: #1e293b;
        background: #f1f5f9;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
        padding: 0.5rem 0.75rem;
    }

    .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
        font-size: 0.875rem;
    }

    .p-input-filled .p-cascadeselect {
        background: #f8fafc;
    }

    .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
        background-color: #f8fafc;
    }

    .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }

    p-cascadeselect.ng-dirty.ng-invalid>.p-cascadeselect {
        border-color: #f87171;
    }

    p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
        padding-right: 0.75rem;
    }

    p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
        color: #94a3b8;
        right: 2.5rem;
    }

    .p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel {
        box-shadow: none;
        border-radius: 0;
        padding: 0.25rem 0 0.25rem 0.5rem;
    }

    .p-overlay-modal .p-cascadeselect-item-active>.p-cascadeselect-item-content .p-cascadeselect-group-icon {
        transform: rotate(90deg);
    }

    .p-checkbox {
        width: 1.25rem;
        height: 1.25rem;
    }

    .p-checkbox .p-checkbox-box {
        border: 1px solid #cbd5e1;
        border-width: 1px !important;
        background: #ffffff;
        width: 1.25rem;
        height: 1.25rem;
        color: #334155;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        outline-color: transparent;
    }

    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        transition-duration: 0.2s;
        color: #ffffff;
        font-size: 0.875rem;
    }

    .p-checkbox .p-checkbox-box .p-icon {
        width: 0.875rem;
        height: 0.875rem;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: var(--orange-500);
        background: var(--orange-500);
    }

    // .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    //     border-color: #94a3b8;
    // }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        // border-color: #2563eb;
        // background: #2563eb;
        color: #ffffff;
    }

    .p-checkbox.p-variant-filled .p-checkbox-box {
        background-color: #f8fafc;
    }

    .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
        background: #3B82F6;
    }

    .p-checkbox.p-variant-filled:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        background-color: #f8fafc;
    }

    .p-checkbox.p-variant-filled:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #2563eb;
    }

    p-checkbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
        border-color: #f87171;
    }

    .p-input-filled .p-checkbox .p-checkbox-box {
        background-color: #f8fafc;
    }

    .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
        background: #3B82F6;
    }

    .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        background-color: #f8fafc;
    }

    .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #2563eb;
    }

    .p-checkbox-label {
        margin-left: 0.5rem;
    }

    p-tristatecheckbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
        border-color: #f87171;
    }

    .p-chips:not(.p-disabled):hover .p-chips-multiple-container {
        border-color: #94a3b8;
    }

    .p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-chips .p-chips-multiple-container {
        padding: 0.25rem 0.75rem;
        gap: 0.5rem;
    }

    .p-chips .p-chips-multiple-container .p-chips-token {
        padding: 0.25rem 0.75rem;
        margin-right: 0.5rem;
        background: #f1f5f9;
        color: #1e293b;
        border-radius: 16px;
    }

    .p-chips .p-chips-multiple-container .p-chips-token.p-focus {
        background: #e2e8f0;
        color: #0f172a;
    }

    .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
        margin-left: 0.5rem;
    }

    .p-chips .p-chips-multiple-container .p-chips-input-token {
        padding: 0.25rem 0;
    }

    .p-chips .p-chips-multiple-container .p-chips-input-token input {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
        color: #334155;
        padding: 0;
        margin: 0;
    }

    p-chips.ng-dirty.ng-invalid>.p-chips>.p-inputtext {
        border-color: #f87171;
    }

    p-chips.p-chips-clearable .p-inputtext {
        padding-right: 1.75rem;
    }

    p-chips.p-chips-clearable .p-chips-clear-icon {
        color: #94a3b8;
        right: 0.75rem;
    }

    .p-colorpicker-preview,
    .p-fluid .p-colorpicker-preview.p-inputtext {
        width: 1.5rem;
        height: 1.5rem;
    }

    .p-colorpicker-panel {
        background: #323232;
        border: 1px solid #191919;
    }

    .p-colorpicker-panel .p-colorpicker-color-handle,
    .p-colorpicker-panel .p-colorpicker-hue-handle {
        border-color: #ffffff;
    }

    .p-colorpicker-overlay-panel {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-dropdown {
        background: #ffffff;
        border: 1px solid #cbd5e1;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
    }

    .p-dropdown:not(.p-disabled):hover {
        border-color: #94a3b8;
    }

    .p-dropdown:not(.p-disabled).p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-dropdown.p-dropdown-clearable .p-dropdown-label {
        padding-right: 1.75rem;
    }

    .p-dropdown .p-dropdown-label {
        background: transparent;
        border: 0 none;
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
        color: #64748b;
    }

    .p-dropdown .p-dropdown-label:focus,
    .p-dropdown .p-dropdown-label:enabled:focus {
        outline: 0 none;
        box-shadow: none;
    }

    .p-dropdown .p-dropdown-trigger {
        background: transparent;
        color: #94a3b8;
        width: 2.5rem;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-dropdown .p-dropdown-clear-icon {
        color: #94a3b8;
        right: 2.5rem;
    }

    .p-dropdown.p-invalid.p-component {
        border-color: #f87171;
    }

    .p-dropdown.p-variant-filled {
        background-color: #f8fafc;
    }

    .p-dropdown.p-variant-filled:enabled:hover {
        background-color: #f8fafc;
    }

    .p-dropdown.p-variant-filled:enabled:focus {
        background-color: #ffffff;
    }

    .p-dropdown-panel {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-dropdown-panel .p-dropdown-header {
        padding: 0.5rem 0.5rem 0 0.5rem;
        border-bottom: 0 none;
        color: #334155;
        background: #ffffff;
        margin: 0 0 0 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
        padding-right: 1.75rem;
        margin-right: -1.75rem;
    }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
        right: 0.75rem;
        color: #94a3b8;
    }

    .p-dropdown-panel .p-dropdown-items {
        padding: 0.25rem 0.25rem;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        margin: 2px 0;
        padding: 0.5rem 0.75rem;
        border: 0 none;
        color: #334155;
        background: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
        margin-top: 0;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
        color: #0f172a;
        background: #e2e8f0;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: #1e293b;
        background: #f1f5f9;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
        margin: 0;
        padding: 0.5rem 0.75rem;
        color: #94a3b8;
        background: #ffffff;
        font-weight: 600;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
        padding: 0.5rem 0.75rem;
        color: #334155;
        background: transparent;
    }

    .p-input-filled .p-dropdown {
        background: #f8fafc;
    }

    .p-input-filled .p-dropdown:not(.p-disabled):hover {
        background-color: #f8fafc;
    }

    .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }

    .p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
        background-color: transparent;
    }

    p-dropdown.ng-dirty.ng-invalid>.p-dropdown {
        border-color: #f87171;
    }

    .p-icon-field .p-input-icon {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
    }

    .p-inputgroup-addon {
        background: #ffffff;
        color: #64748b;
        border-top: 1px solid #cbd5e1;
        border-left: 1px solid #cbd5e1;
        border-bottom: 1px solid #cbd5e1;
        padding: 0.5rem 0.75rem;
        min-width: 2.5rem;
    }

    .p-inputgroup-addon:last-child {
        border-right: 1px solid #cbd5e1;
    }

    .p-inputgroup>.p-component,
    .p-inputgroup>.p-inputwrapper>.p-inputtext,
    .p-inputgroup>.p-float-label>.p-component {
        border-radius: 0;
        margin: 0;
    }

    .p-inputgroup>.p-component+.p-inputgroup-addon,
    .p-inputgroup>.p-inputwrapper>.p-inputtext+.p-inputgroup-addon,
    .p-inputgroup>.p-float-label>.p-component+.p-inputgroup-addon {
        border-left: 0 none;
    }

    .p-inputgroup>.p-component:focus,
    .p-inputgroup>.p-inputwrapper>.p-inputtext:focus,
    .p-inputgroup>.p-float-label>.p-component:focus {
        z-index: 1;
    }

    .p-inputgroup>.p-component:focus~label,
    .p-inputgroup>.p-inputwrapper>.p-inputtext:focus~label,
    .p-inputgroup>.p-float-label>.p-component:focus~label {
        z-index: 1;
    }

    .p-inputgroup-addon:first-child,
    .p-inputgroup button:first-child,
    .p-inputgroup input:first-child,
    .p-inputgroup>.p-inputwrapper:first-child>.p-component,
    .p-inputgroup>.p-inputwrapper:first-child>.p-component>.p-inputtext {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-inputgroup .p-float-label:first-child input {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-inputgroup-addon:last-child,
    .p-inputgroup button:last-child,
    .p-inputgroup input:last-child,
    .p-inputgroup>.p-inputwrapper:last-child>.p-component,
    .p-inputgroup>.p-inputwrapper:last-child>.p-component>.p-inputtext {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-inputgroup .p-float-label:last-child input {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-fluid .p-inputgroup .p-button {
        width: auto;
    }

    .p-fluid .p-inputgroup .p-button.p-button-icon-only {
        width: 2.5rem;
    }

    .p-icon-field-left .p-input-icon:first-of-type {
        left: 0.75rem;
        color: #94a3b8;
    }

    .p-icon-field-right .p-input-icon:last-of-type {
        right: 0.75rem;
        color: #94a3b8;
    }

    p-inputmask.ng-dirty.ng-invalid>.p-inputtext {
        border-color: #f87171;
    }

    p-inputmask.p-inputmask-clearable .p-inputtext {
        padding-right: 2.5rem;
    }

    p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
        color: #94a3b8;
        right: 0.75rem;
    }

    .p-inputmask.p-variant-filled {
        background-color: #f8fafc;
    }

    .p-inputmask.p-variant-filled:enabled:hover {
        background-color: #f8fafc;
    }

    .p-inputmask.p-variant-filled:enabled:focus {
        background-color: #ffffff;
    }

    p-inputnumber.ng-dirty.ng-invalid>.p-inputnumber>.p-inputtext {
        border-color: #f87171;
    }

    p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
        padding-right: 2.5rem;
    }

    p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
        color: #94a3b8;
        right: 0.75rem;
    }

    p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon {
        right: 3.25rem;
    }

    p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
        right: 3.25rem;
    }

    p-inputnumber.p-inputnumber.p-variant-filled>.p-inputnumber-input {
        background-color: #f8fafc;
    }

    p-inputnumber.p-inputnumber.p-variant-filled>.p-inputnumber-input:enabled:hover {
        background-color: #f8fafc;
    }

    p-inputnumber.p-inputnumber.p-variant-filled>.p-inputnumber-input:enabled:focus {
        background-color: #ffffff;
    }

    .p-inputotp {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .p-inputotp-input {
        text-align: center;
        width: 2.5rem;
        height: 2.5rem;
    }

    .p-inputswitch {
        width: 2.5rem;
        height: 1.5rem;
    }

    .p-inputswitch .p-inputswitch-slider {
        background: #cbd5e1;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 30px;
    }

    .p-inputswitch .p-inputswitch-slider:before {
        background: #ffffff;
        width: 1rem;
        height: 1rem;
        left: 0.25rem;
        margin-top: -0.5rem;
        border-radius: 50%;
        transition-duration: 0.2s;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(1rem);
    }

    .p-inputswitch.p-focus .p-inputswitch-slider {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
        background: #94a3b8;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #3B82F6;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        background: #ffffff;
    }

    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #2563eb;
    }

    p-inputswitch.ng-dirty.ng-invalid>.p-inputswitch>.p-inputswitch-slider {
        border-color: #f87171;
    }

    .p-inputtext {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
        color: #334155;
        background: #ffffff;
        padding: 0.5rem 0.75rem;
        border: 1px solid #cbd5e1;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        appearance: none;
        border-radius: 6px;
    }

    .p-inputtext:enabled:hover {
        border-color: #94a3b8;
    }

    .p-inputtext:enabled:focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-inputtext.ng-dirty.ng-invalid {
        border-color: #f87171;
    }

    .p-inputtext.p-variant-filled {
        background-color: #f8fafc;
    }

    .p-inputtext.p-variant-filled:enabled:hover {
        background-color: #f8fafc;
    }

    .p-inputtext.p-variant-filled:enabled:focus {
        background-color: #ffffff;
    }

    .p-inputtext.p-inputtext-sm {
        font-size: 0.875rem;
        padding: 0.4375rem 0.65625rem;
    }

    .p-inputtext.p-inputtext-lg {
        font-size: 1.25rem;
        padding: 0.625rem 0.9375rem;
    }

    .p-float-label>label {
        left: 0.75rem;
        color: #64748b;
        transition-duration: 0.2s;
    }

    .p-float-label>.ng-invalid.ng-dirty+label {
        color: #f87171;
    }

    .p-input-icon-left>.p-icon-wrapper.p-icon,
    .p-input-icon-left>i:first-of-type {
        left: 0.75rem;
        color: #94a3b8;
    }

    .p-input-icon-left>.p-inputtext {
        padding-left: 2.5rem;
    }

    .p-input-icon-left.p-float-label>label {
        left: 2.5rem;
    }

    .p-input-icon-right>.p-icon-wrapper,
    .p-input-icon-right>i:last-of-type {
        right: 0.75rem;
        color: #94a3b8;
    }

    .p-input-icon-right>.p-inputtext {
        padding-right: 2.5rem;
    }

    .p-icon-field-left>.p-inputtext {
        padding-left: 2.5rem;
    }

    .p-icon-field-left.p-float-label>label {
        left: 2.5rem;
    }

    .p-icon-field-right>.p-inputtext {
        padding-right: 2.5rem;
    }

    ::-webkit-input-placeholder {
        color: #64748b;
    }

    :-moz-placeholder {
        color: #64748b;
    }

    ::-moz-placeholder {
        color: #64748b;
    }

    :-ms-input-placeholder {
        color: #64748b;
    }

    .p-input-filled .p-inputtext {
        background-color: #f8fafc;
    }

    .p-input-filled .p-inputtext:enabled:hover {
        background-color: #f8fafc;
    }

    .p-input-filled .p-inputtext:enabled:focus {
        background-color: #ffffff;
    }

    .p-inputtext-sm .p-inputtext {
        font-size: 0.875rem;
        padding: 0.4375rem 0.65625rem;
    }

    .p-inputtext-lg .p-inputtext {
        font-size: 1.25rem;
        padding: 0.625rem 0.9375rem;
    }

    .p-listbox {
        background: #ffffff;
        color: #334155;
        border: 1px solid #cbd5e1;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-listbox .p-listbox-header {
        padding: 0.5rem 0.5rem 0 0.5rem;
        border-bottom: 0 none;
        color: #334155;
        background: #ffffff;
        margin: 0 0 0 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-listbox .p-listbox-header .p-listbox-filter {
        padding-right: 1.75rem;
    }

    .p-listbox .p-listbox-header .p-listbox-filter-icon {
        right: 0.75rem;
        color: #94a3b8;
    }

    .p-listbox .p-listbox-header .p-checkbox {
        margin-right: 0.5rem;
    }

    .p-listbox .p-listbox-list {
        padding: 0.25rem 0.25rem;
        outline: 0 none;
    }

    .p-listbox .p-listbox-list .p-listbox-item {
        margin: 2px 0;
        padding: 0.5rem 0.75rem;
        border: 0 none;
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-listbox .p-listbox-list .p-listbox-item:first-child {
        margin-top: 0;
    }

    .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
        margin-right: 0.5rem;
    }

    .p-listbox .p-listbox-list .p-listbox-item-group {
        margin: 0;
        padding: 0.5rem 0.75rem;
        color: #94a3b8;
        background: #ffffff;
        font-weight: 600;
    }

    .p-listbox .p-listbox-list .p-listbox-empty-message {
        padding: 0.5rem 0.75rem;
        color: #334155;
        background: transparent;
    }

    .p-listbox:not(.p-disabled) .p-listbox-item.p-highlight.p-focus {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
        color: #1e293b;
        background: #f1f5f9;
    }

    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
        color: #1e293b;
        background: #f1f5f9;
    }

    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover.p-focus {
        color: #1e293b;
        background: #f1f5f9;
    }

    .p-listbox.p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    p-listbox.ng-dirty.ng-invalid>.p-listbox {
        border-color: #f87171;
    }

    .p-multiselect {
        background: #ffffff;
        border: 1px solid #cbd5e1;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
    }

    .p-multiselect:not(.p-disabled):hover {
        border-color: #94a3b8;
    }

    .p-multiselect:not(.p-disabled).p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-multiselect .p-multiselect-label {
        padding: 0.5rem 0.75rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-multiselect .p-multiselect-label.p-placeholder {
        color: #64748b;
    }

    .p-multiselect.p-multiselect-chip .p-multiselect-token {
        padding: 0.25rem 0.75rem;
        margin-right: 0.5rem;
        background: #f1f5f9;
        color: #1e293b;
        border-radius: 16px;
    }

    .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
        margin-left: 0.5rem;
    }

    .p-multiselect .p-multiselect-trigger {
        background: transparent;
        color: #94a3b8;
        width: 2.5rem;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-multiselect.p-variant-filled {
        background: #f8fafc;
    }

    .p-multiselect.p-variant-filled:not(.p-disabled):hover {
        background-color: #f8fafc;
    }

    .p-multiselect.p-variant-filled:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }

    .p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
        padding: 0.25rem 0.75rem;
    }

    .p-multiselect-clearable .p-multiselect-label-container {
        padding-right: 1.75rem;
    }

    .p-multiselect-clearable .p-multiselect-clear-icon {
        color: #94a3b8;
        right: 2.5rem;
    }

    .p-multiselect-panel {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-multiselect-panel .p-multiselect-header {
        padding: 0.5rem 0.5rem 0 0.5rem;
        border-bottom: 0 none;
        color: #334155;
        background: #ffffff;
        margin: 0 0 0 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
        padding-right: 1.75rem !important;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
        right: 0.75rem !important;
        color: #94a3b8 !important;
    }

    .p-multiselect-panel .p-multiselect-header .p-checkbox {
        margin-right: 0.5rem;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
        margin-left: 0.5rem;
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-multiselect-panel .p-multiselect-items {
        padding: 0.25rem 0.25rem;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        margin: 2px 0;
        padding: 0.5rem 0.75rem;
        border: 0 none;
        color: #334155;
        background: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:first-child {
        margin-top: 0;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
        color: #0f172a;
        background: #f1f5f9;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        color: #1e293b;
        background: #f1f5f9;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
        margin-right: 0.5rem;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
        margin: 0;
        padding: 0.5rem 0.75rem;
        color: #94a3b8;
        background: #ffffff;
        font-weight: 600;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
        padding: 0.5rem 0.75rem;
        color: #334155;
        background: transparent;
    }

    .p-input-filled .p-multiselect {
        background: #f8fafc;
    }

    .p-input-filled .p-multiselect:not(.p-disabled):hover {
        background-color: #f8fafc;
    }

    .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }

    p-multiselect.ng-dirty.ng-invalid>.p-multiselect {
        border-color: #f87171;
    }

    p-password.ng-invalid.ng-dirty>.p-password>.p-inputtext {
        border-color: #f87171;
    }

    .p-password-panel {
        padding: 0 1.125rem 1.125rem 1.125rem;
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }

    .p-password-panel .p-password-meter {
        margin-bottom: 0.5rem;
        background: #e2e8f0;
    }

    .p-password-panel .p-password-meter .p-password-strength.weak {
        background: #ef4444;
    }

    .p-password-panel .p-password-meter .p-password-strength.medium {
        background: #f59e0b;
    }

    .p-password-panel .p-password-meter .p-password-strength.strong {
        background: #22c55e;
    }

    p-password.p-password-clearable .p-password-input {
        padding-right: 2.5rem;
    }

    p-password.p-password-clearable .p-password-clear-icon {
        color: #94a3b8;
        right: 0.75rem;
    }

    p-password.p-password-clearable.p-password-mask .p-password-input {
        padding-right: 4.25rem;
    }

    p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
        color: #94a3b8;
        right: 2.5rem;
    }

    .p-radiobutton {
        width: 1.25rem;
        height: 1.25rem;
    }

    .p-radiobutton .p-radiobutton-box {
        border: 1px solid #cbd5e1;
        background: #ffffff;
        width: 1.25rem;
        height: 1.25rem;
        color: #334155;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        outline-color: transparent;
    }

    .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
        border-color: #94a3b8;
    }

    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        width: 0.75rem;
        height: 0.75rem;
        transition-duration: 0.2s;
        background-color: #3B82F6;
    }

    .p-radiobutton .p-radiobutton-box.p-highlight {
        border-color: #3B82F6;
        background: #ffffff;
    }

    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #2563eb;
        background: #ffffff;
        color: #2563eb;
    }

    .p-radiobutton.p-variant-filled .p-radiobutton-box {
        background-color: #f8fafc;
    }

    .p-radiobutton.p-variant-filled .p-radiobutton-box:not(.p-disabled):hover {
        background-color: #f8fafc;
    }

    .p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight {
        background: #ffffff;
    }

    .p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        background: #ffffff;
    }

    p-radiobutton.ng-dirty.ng-invalid>.p-radiobutton>.p-radiobutton-box {
        border-color: #f87171;
    }

    .p-input-filled .p-radiobutton .p-radiobutton-box {
        background-color: #f8fafc;
    }

    .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
        background-color: #f8fafc;
    }

    .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #ffffff;
    }

    .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        background: #ffffff;
    }

    .p-radiobutton-label {
        margin-left: 0.5rem;
    }

    .p-rating {
        gap: 0.5rem;
    }

    .p-rating .p-rating-item {
        border-radius: 50%;
        outline-color: transparent;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .p-rating .p-rating-item .p-rating-icon {
        color: #64748b;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        font-size: 1rem;
    }

    .p-rating .p-rating-item .p-rating-icon.p-icon {
        width: 1rem;
        height: 1rem;
    }

    .p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
        color: #f87171;
    }

    .p-rating .p-rating-item.p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
        color: #3B82F6;
    }

    .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
        color: #3B82F6;
    }

    .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
        color: #ef4444;
    }

    .p-selectbutton .p-button {
        background: #f1f5f9;
        border: 1px solid #f1f5f9;
        color: #64748b;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-selectbutton .p-button .p-button-icon-left,
    .p-selectbutton .p-button .p-button-icon-right {
        color: #64748b;
    }

    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
        background: #f1f5f9;
        border-color: #f1f5f9;
        color: #334155;
    }

    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
        color: #334155;
    }

    .p-selectbutton .p-button.p-highlight {
        background: #f1f5f9;
        border-color: #f1f5f9;
        color: #0f172a;
    }

    .p-selectbutton .p-button.p-highlight .p-button-icon-left,
    .p-selectbutton .p-button.p-highlight .p-button-icon-right {
        color: #0f172a;
    }

    .p-selectbutton .p-button.p-highlight:hover {
        background: #f1f5f9;
        border-color: #f1f5f9;
        color: #0f172a;
    }

    .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
    .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
        color: #0f172a;
    }

    p-selectbutton.ng-dirty.ng-invalid>.p-selectbutton>.p-button {
        border-color: #f87171;
    }

    .p-slider {
        background: #e2e8f0;
        border: 0 none;
        border-radius: 6px;
    }

    .p-slider.p-slider-horizontal {
        height: 3px;
    }

    .p-slider.p-slider-horizontal .p-slider-handle {
        margin-top: -10px;
        margin-left: -10px;
    }

    .p-slider.p-slider-vertical {
        height: 100%;
        width: 3px;
    }

    .p-slider.p-slider-vertical .p-slider-handle {
        height: 20px;
        width: 20px;
        margin-left: -10px;
        margin-bottom: -10px;
    }

    .p-slider .p-slider-handle {
        height: 20px;
        width: 20px;
        background: #e2e8f0;
        border: 0 none;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-slider .p-slider-handle:focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-slider .p-slider-range {
        background: #3B82F6;
    }

    .p-slider:not(.p-disabled) .p-slider-handle:hover {
        background: #e2e8f0;
        border-color: transparent;
    }

    .p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s, left 0.2s;
    }

    .p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
        transition: width 0.2s;
    }

    .p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s, bottom 0.2s;
    }

    .p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
        transition: height 0.2s;
    }

    .p-togglebutton.p-button {
        background: #f1f5f9;
        border: 1px solid #f1f5f9;
        color: #64748b;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-togglebutton.p-button .p-button-icon-left,
    .p-togglebutton.p-button .p-button-icon-right {
        color: #64748b;
    }

    .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
        background: #f1f5f9;
        border-color: #f1f5f9;
        color: #334155;
    }

    .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
    .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
        color: #334155;
    }

    .p-togglebutton.p-button.p-highlight {
        background: #f1f5f9;
        border-color: #f1f5f9;
        color: #0f172a;
    }

    .p-togglebutton.p-button.p-highlight .p-button-icon-left,
    .p-togglebutton.p-button.p-highlight .p-button-icon-right {
        color: #0f172a;
    }

    .p-togglebutton.p-button.p-highlight:hover {
        background: #f1f5f9;
        border-color: #f1f5f9;
        color: #0f172a;
    }

    .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
    .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
        color: #0f172a;
    }

    p-togglebutton.ng-dirty.ng-invalid>.p-togglebutton.p-button {
        border-color: #f87171;
    }

    .p-treeselect {
        background: #ffffff;
        border: 1px solid #cbd5e1;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
    }

    .p-treeselect:not(.p-disabled):hover {
        border-color: #94a3b8;
    }

    .p-treeselect:not(.p-disabled).p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-treeselect .p-treeselect-label {
        padding: 0.5rem 0.75rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-treeselect .p-treeselect-label.p-placeholder {
        color: #64748b;
    }

    .p-treeselect.p-treeselect-chip .p-treeselect-token {
        padding: 0.25rem 0.75rem;
        margin-right: 0.5rem;
        background: #f1f5f9;
        color: #1e293b;
        border-radius: 16px;
    }

    .p-treeselect .p-treeselect-trigger {
        background: transparent;
        color: #94a3b8;
        width: 2.5rem;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-treeselect.p-variant-filled {
        background-color: #f8fafc;
    }

    .p-treeselect.p-variant-filled:enabled:hover {
        background-color: #f8fafc;
    }

    .p-treeselect.p-variant-filled:enabled:focus {
        background-color: #ffffff;
    }

    p-treeselect.ng-invalid.ng-dirty>.p-treeselect {
        border-color: #f87171;
    }

    .p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
        padding: 0.25rem 0.75rem;
    }

    .p-treeselect-panel {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-treeselect-panel .p-treeselect-header {
        padding: 0.5rem 0.5rem 0 0.5rem;
        border-bottom: 0 none;
        color: #334155;
        background: #ffffff;
        margin: 0 0 0 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
        margin-right: 0.5rem;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
        padding-right: 1.75rem;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
        right: 0.75rem;
        color: #94a3b8;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
        padding-right: 3.5rem;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
        right: 2.5rem;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-close {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
        border: 0 none;
    }

    .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
        padding: 0.5rem 0.75rem;
        color: #334155;
        background: transparent;
    }

    .p-input-filled .p-treeselect {
        background: #f8fafc;
    }

    .p-input-filled .p-treeselect:not(.p-disabled):hover {
        background-color: #f8fafc;
    }

    .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }

    p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
        padding-right: 1.75rem;
    }

    p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
        color: #94a3b8;
        right: 2.5rem;
    }

    .p-button {
        color: #ffffff;
        background: #3B82F6;
        border: 1px solid #3B82F6;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
        outline-color: transparent;
    }

    .p-button:not(:disabled):hover {
        background: #2563eb;
        color: #ffffff;
        border-color: #2563eb;
    }

    .p-button:not(:disabled):active {
        background: var(--primary-color);
        color: #ffffff;
        border-color: var(--primary-color);
    }

    .p-button.p-button-outlined {
        background-color: transparent;
        border: 1px solid #E6E6E6 !important;
    }

    .p-button.p-button-outlined:not(:disabled):hover {
        background: rgba(59, 130, 246, 0.04);
        color: #3B82F6;
        border: 1px solid;
    }

    .p-button.p-button-outlined:not(:disabled):active {
        background: rgba(59, 130, 246, 0.16);
        color: #3B82F6;
        border: 1px solid;
    }

    .p-button.p-button-outlined.p-button-plain {
        color: #334155;
        border-color: #334155;
    }

    .p-button.p-button-outlined.p-button-plain:not(:disabled):hover {
        background: #f1f5f9;
        color: #334155;
    }

    .p-button.p-button-outlined.p-button-plain:not(:disabled):active {
        background: #e2e8f0;
        color: #334155;
    }

    .p-button.p-button-text {
        background-color: transparent;
        color: #3B82F6;
        border-color: transparent;
    }

    .p-button.p-button-text:not(:disabled):hover {
        background: rgba(59, 130, 246, 0.04);
        color: #3B82F6;
        border-color: transparent;
    }

    .p-button.p-button-text:not(:disabled):active {
        background: rgba(59, 130, 246, 0.16);
        color: #3B82F6;
        border-color: transparent;
    }

    .p-button.p-button-text.p-button-plain {
        color: #334155;
    }

    .p-button.p-button-text.p-button-plain:not(:disabled):hover {
        background: #f1f5f9;
        color: #334155;
    }

    .p-button.p-button-text.p-button-plain:not(:disabled):active {
        background: #e2e8f0;
        color: #334155;
    }

    .p-button:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-button .p-button-label {
        transition-duration: 0.2s;
    }

    .p-button .p-button-icon-left {
        margin-right: 0.5rem;
    }

    .p-button .p-button-icon-right {
        margin-left: 0.5rem;
    }

    .p-button .p-button-icon-bottom {
        margin-top: 0.5rem;
    }

    .p-button .p-button-icon-top {
        margin-bottom: 0.5rem;
    }

    .p-button .p-badge {
        margin-left: 0.5rem;
        min-width: 1rem;
        height: 1rem;
        line-height: 1rem;
        color: #3B82F6;
        background-color: #ffffff;
    }

    .p-button.p-button-raised {
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }

    .p-button.p-button-rounded {
        border-radius: 2rem;
    }

    .p-button.p-button-icon-only {
        width: 2.5rem;
        padding: 0.5rem 0;
    }

    .p-button.p-button-icon-only .p-button-icon-left,
    .p-button.p-button-icon-only .p-button-icon-right {
        margin: 0;
    }

    .p-button.p-button-icon-only.p-button-rounded {
        border-radius: 50%;
        height: 2.5rem;
    }

    .p-button.p-button-sm {
        font-size: 0.875rem;
        padding: 0.4375rem 0.875rem;
    }

    .p-button.p-button-sm .p-button-icon {
        font-size: 0.875rem;
    }

    .p-button.p-button-lg {
        font-size: 1.25rem;
        padding: 0.625rem 1.25rem;
    }

    .p-button.p-button-lg .p-button-icon {
        font-size: 1.25rem;
    }

    .p-button.p-button-loading-label-only .p-button-label {
        margin-left: 0.5rem;
    }

    .p-button.p-button-loading-label-only .p-button-loading-icon {
        margin-right: 0;
    }

    .p-fluid .p-button {
        width: 100%;
    }

    .p-fluid .p-button-icon-only {
        width: 2.5rem;
    }

    .p-fluid .p-button-group {
        display: flex;
    }

    .p-fluid .p-button-group .p-button {
        flex: 1;
    }

    .p-button.p-button-secondary,
    .p-button-group.p-button-secondary>.p-button,
    .p-splitbutton.p-button-secondary>.p-button {
        color: #475569;
        background: #f1f5f9;
        border: 1px solid #f1f5f9;
    }

    .p-button.p-button-secondary:not(:disabled):hover,
    .p-button-group.p-button-secondary>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-secondary>.p-button:not(:disabled):hover {
        background: #e2e8f0;
        color: #334155;
        border-color: #e2e8f0;
    }

    .p-button.p-button-secondary:not(:disabled):focus,
    .p-button-group.p-button-secondary>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-secondary>.p-button:not(:disabled):focus {
        box-shadow: none;
    }

    .p-button.p-button-secondary:not(:disabled):active,
    .p-button-group.p-button-secondary>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-secondary>.p-button:not(:disabled):active {
        background: #cbd5e1;
        color: #1e293b;
        border-color: #cbd5e1;
    }

    .p-button.p-button-secondary.p-button-outlined,
    .p-button-group.p-button-secondary>.p-button.p-button-outlined,
    .p-splitbutton.p-button-secondary>.p-button.p-button-outlined {
        background-color: transparent;
        color: #f1f5f9;
        border: 1px solid;
    }

    .p-button.p-button-secondary.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-secondary>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(:disabled):hover {
        background: rgba(241, 245, 249, 0.04);
        color: #f1f5f9;
        border: 1px solid;
    }

    .p-button.p-button-secondary.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-secondary>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(:disabled):active {
        background: rgba(241, 245, 249, 0.16);
        color: #f1f5f9;
        border: 1px solid;
    }

    .p-button.p-button-secondary.p-button-text,
    .p-button-group.p-button-secondary>.p-button.p-button-text,
    .p-splitbutton.p-button-secondary>.p-button.p-button-text {
        background-color: transparent;
        color: #f1f5f9;
        border-color: transparent;
    }

    .p-button.p-button-secondary.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-secondary>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-secondary>.p-button.p-button-text:not(:disabled):hover {
        background: rgba(241, 245, 249, 0.04);
        border-color: transparent;
        color: #f1f5f9;
    }

    .p-button.p-button-secondary.p-button-text:not(:disabled):active,
    .p-button-group.p-button-secondary>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-secondary>.p-button.p-button-text:not(:disabled):active {
        background: rgba(241, 245, 249, 0.16);
        border-color: transparent;
        color: #f1f5f9;
    }

    .p-button.p-button-info,
    .p-button-group.p-button-info>.p-button,
    .p-splitbutton.p-button-info>.p-button {
        color: #ffffff;
        background: #0ea5e9;
        border: 1px solid #0ea5e9;
    }

    .p-button.p-button-info:not(:disabled):hover,
    .p-button-group.p-button-info>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-info>.p-button:not(:disabled):hover {
        background: #0284c7;
        color: #ffffff;
        border-color: #0284c7;
    }

    .p-button.p-button-info:not(:disabled):focus,
    .p-button-group.p-button-info>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-info>.p-button:not(:disabled):focus {
        box-shadow: none;
    }

    .p-button.p-button-info:not(:disabled):active,
    .p-button-group.p-button-info>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-info>.p-button:not(:disabled):active {
        background: #0369a1;
        color: #ffffff;
        border-color: #0369a1;
    }

    .p-button.p-button-info.p-button-outlined,
    .p-button-group.p-button-info>.p-button.p-button-outlined,
    .p-splitbutton.p-button-info>.p-button.p-button-outlined {
        background-color: transparent;
        color: #0ea5e9;
        border: 1px solid;
    }

    .p-button.p-button-info.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-info>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-info>.p-button.p-button-outlined:not(:disabled):hover {
        background: rgba(14, 165, 233, 0.04);
        color: #0ea5e9;
        border: 1px solid;
    }

    .p-button.p-button-info.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-info>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-info>.p-button.p-button-outlined:not(:disabled):active {
        background: rgba(14, 165, 233, 0.16);
        color: #0ea5e9;
        border: 1px solid;
    }

    .p-button.p-button-info.p-button-text,
    .p-button-group.p-button-info>.p-button.p-button-text,
    .p-splitbutton.p-button-info>.p-button.p-button-text {
        background-color: transparent;
        color: #0ea5e9;
        border-color: transparent;
    }

    .p-button.p-button-info.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-info>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-info>.p-button.p-button-text:not(:disabled):hover {
        background: rgba(14, 165, 233, 0.04);
        border-color: transparent;
        color: #0ea5e9;
    }

    .p-button.p-button-info.p-button-text:not(:disabled):active,
    .p-button-group.p-button-info>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-info>.p-button.p-button-text:not(:disabled):active {
        background: rgba(14, 165, 233, 0.16);
        border-color: transparent;
        color: #0ea5e9;
    }

    .p-button.p-button-success,
    .p-button-group.p-button-success>.p-button,
    .p-splitbutton.p-button-success>.p-button {
        color: #ffffff;
        background: #22c55e;
        border: 1px solid #22c55e;
    }

    .p-button.p-button-success:not(:disabled):hover,
    .p-button-group.p-button-success>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-success>.p-button:not(:disabled):hover {
        background: #16a34a;
        color: #ffffff;
        border-color: #16a34a;
    }

    .p-button.p-button-success:not(:disabled):focus,
    .p-button-group.p-button-success>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-success>.p-button:not(:disabled):focus {
        box-shadow: none;
    }

    .p-button.p-button-success:not(:disabled):active,
    .p-button-group.p-button-success>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-success>.p-button:not(:disabled):active {
        background: #15803d;
        color: #ffffff;
        border-color: #15803d;
    }

    .p-button.p-button-success.p-button-outlined,
    .p-button-group.p-button-success>.p-button.p-button-outlined,
    .p-splitbutton.p-button-success>.p-button.p-button-outlined {
        background-color: transparent;
        color: #22c55e;
        border: 1px solid;
    }

    .p-button.p-button-success.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-success>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-success>.p-button.p-button-outlined:not(:disabled):hover {
        background: rgba(34, 197, 94, 0.04);
        color: #22c55e;
        border: 1px solid;
    }

    .p-button.p-button-success.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-success>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-success>.p-button.p-button-outlined:not(:disabled):active {
        background: rgba(34, 197, 94, 0.16);
        color: #22c55e;
        border: 1px solid;
    }

    .p-button.p-button-success.p-button-text,
    .p-button-group.p-button-success>.p-button.p-button-text,
    .p-splitbutton.p-button-success>.p-button.p-button-text {
        background-color: transparent;
        color: #22c55e;
        border-color: transparent;
    }

    .p-button.p-button-success.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-success>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-success>.p-button.p-button-text:not(:disabled):hover {
        background: rgba(34, 197, 94, 0.04);
        border-color: transparent;
        color: #22c55e;
    }

    .p-button.p-button-success.p-button-text:not(:disabled):active,
    .p-button-group.p-button-success>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-success>.p-button.p-button-text:not(:disabled):active {
        background: rgba(34, 197, 94, 0.16);
        border-color: transparent;
        color: #22c55e;
    }

    .p-button.p-button-warning,
    .p-button-group.p-button-warning>.p-button,
    .p-splitbutton.p-button-warning>.p-button {
        color: #ffffff;
        background: #f97316;
        border: 1px solid #f97316;
    }

    .p-button.p-button-warning:not(:disabled):hover,
    .p-button-group.p-button-warning>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-warning>.p-button:not(:disabled):hover {
        background: #ea580c;
        color: #ffffff;
        border-color: #ea580c;
    }

    .p-button.p-button-warning:not(:disabled):focus,
    .p-button-group.p-button-warning>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-warning>.p-button:not(:disabled):focus {
        box-shadow: none;
    }

    .p-button.p-button-warning:not(:disabled):active,
    .p-button-group.p-button-warning>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-warning>.p-button:not(:disabled):active {
        background: #c2410c;
        color: #ffffff;
        border-color: #c2410c;
    }

    .p-button.p-button-warning.p-button-outlined,
    .p-button-group.p-button-warning>.p-button.p-button-outlined,
    .p-splitbutton.p-button-warning>.p-button.p-button-outlined {
        background-color: transparent;
        color: #f97316;
        border: 1px solid;
    }

    .p-button.p-button-warning.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-warning>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(:disabled):hover {
        background: rgba(249, 115, 22, 0.04);
        color: #f97316;
        border: 1px solid;
    }

    .p-button.p-button-warning.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-warning>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(:disabled):active {
        background: rgba(249, 115, 22, 0.16);
        color: #f97316;
        border: 1px solid;
    }

    .p-button.p-button-warning.p-button-text,
    .p-button-group.p-button-warning>.p-button.p-button-text,
    .p-splitbutton.p-button-warning>.p-button.p-button-text {
        background-color: transparent;
        color: #f97316;
        border-color: transparent;
    }

    .p-button.p-button-warning.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-warning>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-warning>.p-button.p-button-text:not(:disabled):hover {
        background: rgba(249, 115, 22, 0.04);
        border-color: transparent;
        color: #f97316;
    }

    .p-button.p-button-warning.p-button-text:not(:disabled):active,
    .p-button-group.p-button-warning>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-warning>.p-button.p-button-text:not(:disabled):active {
        background: rgba(249, 115, 22, 0.16);
        border-color: transparent;
        color: #f97316;
    }

    .p-button.p-button-help,
    .p-button-group.p-button-help>.p-button,
    .p-splitbutton.p-button-help>.p-button {
        color: #ffffff;
        background: #a855f7;
        border: 1px solid #a855f7;
    }

    .p-button.p-button-help:not(:disabled):hover,
    .p-button-group.p-button-help>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-help>.p-button:not(:disabled):hover {
        background: #9333ea;
        color: #ffffff;
        border-color: #9333ea;
    }

    .p-button.p-button-help:not(:disabled):focus,
    .p-button-group.p-button-help>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-help>.p-button:not(:disabled):focus {
        box-shadow: none;
    }

    .p-button.p-button-help:not(:disabled):active,
    .p-button-group.p-button-help>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-help>.p-button:not(:disabled):active {
        background: #7e22ce;
        color: #ffffff;
        border-color: #7e22ce;
    }

    .p-button.p-button-help.p-button-outlined,
    .p-button-group.p-button-help>.p-button.p-button-outlined,
    .p-splitbutton.p-button-help>.p-button.p-button-outlined {
        background-color: transparent;
        color: #a855f7;
        border: 1px solid;
    }

    .p-button.p-button-help.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-help>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-help>.p-button.p-button-outlined:not(:disabled):hover {
        background: rgba(168, 85, 247, 0.04);
        color: #a855f7;
        border: 1px solid;
    }

    .p-button.p-button-help.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-help>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-help>.p-button.p-button-outlined:not(:disabled):active {
        background: rgba(168, 85, 247, 0.16);
        color: #a855f7;
        border: 1px solid;
    }

    .p-button.p-button-help.p-button-text,
    .p-button-group.p-button-help>.p-button.p-button-text,
    .p-splitbutton.p-button-help>.p-button.p-button-text {
        background-color: transparent;
        color: #a855f7;
        border-color: transparent;
    }

    .p-button.p-button-help.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-help>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-help>.p-button.p-button-text:not(:disabled):hover {
        background: rgba(168, 85, 247, 0.04);
        border-color: transparent;
        color: #a855f7;
    }

    .p-button.p-button-help.p-button-text:not(:disabled):active,
    .p-button-group.p-button-help>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-help>.p-button.p-button-text:not(:disabled):active {
        background: rgba(168, 85, 247, 0.16);
        border-color: transparent;
        color: #a855f7;
    }

    .p-button.p-button-danger,
    .p-button-group.p-button-danger>.p-button,
    .p-splitbutton.p-button-danger>.p-button {
        color: #ffffff;
        background: #ef4444;
        border: 1px solid #ef4444;
    }

    .p-button.p-button-danger:not(:disabled):hover,
    .p-button-group.p-button-danger>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-danger>.p-button:not(:disabled):hover {
        background: #dc2626;
        color: #ffffff;
        border-color: #dc2626;
    }

    .p-button.p-button-danger:not(:disabled):focus,
    .p-button-group.p-button-danger>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-danger>.p-button:not(:disabled):focus {
        box-shadow: none;
    }

    .p-button.p-button-danger:not(:disabled):active,
    .p-button-group.p-button-danger>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-danger>.p-button:not(:disabled):active {
        background: #b91c1c;
        color: #ffffff;
        border-color: #b91c1c;
    }

    .p-button.p-button-danger.p-button-outlined,
    .p-button-group.p-button-danger>.p-button.p-button-outlined,
    .p-splitbutton.p-button-danger>.p-button.p-button-outlined {
        background-color: transparent;
        color: #ef4444;
        border: 1px solid;
    }

    .p-button.p-button-danger.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-danger>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(:disabled):hover {
        background: rgba(239, 68, 68, 0.04);
        color: #ef4444;
        border: 1px solid;
    }

    .p-button.p-button-danger.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-danger>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(:disabled):active {
        background: rgba(239, 68, 68, 0.16);
        color: #ef4444;
        border: 1px solid;
    }

    .p-button.p-button-danger.p-button-text,
    .p-button-group.p-button-danger>.p-button.p-button-text,
    .p-splitbutton.p-button-danger>.p-button.p-button-text {
        background-color: transparent;
        color: #ef4444;
        border-color: transparent;
    }

    .p-button.p-button-danger.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-danger>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-danger>.p-button.p-button-text:not(:disabled):hover {
        background: rgba(239, 68, 68, 0.04);
        border-color: transparent;
        color: #ef4444;
    }

    .p-button.p-button-danger.p-button-text:not(:disabled):active,
    .p-button-group.p-button-danger>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-danger>.p-button.p-button-text:not(:disabled):active {
        background: rgba(239, 68, 68, 0.16);
        border-color: transparent;
        color: #ef4444;
    }

    .p-button.p-button-contrast,
    .p-button-group.p-button-contrast>.p-button,
    .p-splitbutton.p-button-contrast>.p-button {
        color: #ffffff;
        background: #020617;
        border: 1px solid #020617;
    }

    .p-button.p-button-contrast:not(:disabled):hover,
    .p-button-group.p-button-contrast>.p-button:not(:disabled):hover,
    .p-splitbutton.p-button-contrast>.p-button:not(:disabled):hover {
        background: #1e293b;
        color: #ffffff;
        border-color: #1e293b;
    }

    .p-button.p-button-contrast:not(:disabled):focus,
    .p-button-group.p-button-contrast>.p-button:not(:disabled):focus,
    .p-splitbutton.p-button-contrast>.p-button:not(:disabled):focus {
        box-shadow: none;
    }

    .p-button.p-button-contrast:not(:disabled):active,
    .p-button-group.p-button-contrast>.p-button:not(:disabled):active,
    .p-splitbutton.p-button-contrast>.p-button:not(:disabled):active {
        background: #334155;
        color: #ffffff;
        border-color: #334155;
    }

    .p-button.p-button-contrast.p-button-outlined,
    .p-button-group.p-button-contrast>.p-button.p-button-outlined,
    .p-splitbutton.p-button-contrast>.p-button.p-button-outlined {
        background-color: transparent;
        color: #020617;
        border: 1px solid;
    }

    .p-button.p-button-contrast.p-button-outlined:not(:disabled):hover,
    .p-button-group.p-button-contrast>.p-button.p-button-outlined:not(:disabled):hover,
    .p-splitbutton.p-button-contrast>.p-button.p-button-outlined:not(:disabled):hover {
        background: rgba(2, 6, 23, 0.04);
        color: #020617;
        border: 1px solid;
    }

    .p-button.p-button-contrast.p-button-outlined:not(:disabled):active,
    .p-button-group.p-button-contrast>.p-button.p-button-outlined:not(:disabled):active,
    .p-splitbutton.p-button-contrast>.p-button.p-button-outlined:not(:disabled):active {
        background: rgba(2, 6, 23, 0.16);
        color: #020617;
        border: 1px solid;
    }

    .p-button.p-button-contrast.p-button-text,
    .p-button-group.p-button-contrast>.p-button.p-button-text,
    .p-splitbutton.p-button-contrast>.p-button.p-button-text {
        background-color: transparent;
        color: #020617;
        border-color: transparent;
    }

    .p-button.p-button-contrast.p-button-text:not(:disabled):hover,
    .p-button-group.p-button-contrast>.p-button.p-button-text:not(:disabled):hover,
    .p-splitbutton.p-button-contrast>.p-button.p-button-text:not(:disabled):hover {
        background: rgba(2, 6, 23, 0.04);
        border-color: transparent;
        color: #020617;
    }

    .p-button.p-button-contrast.p-button-text:not(:disabled):active,
    .p-button-group.p-button-contrast>.p-button.p-button-text:not(:disabled):active,
    .p-splitbutton.p-button-contrast>.p-button.p-button-text:not(:disabled):active {
        background: rgba(2, 6, 23, 0.16);
        border-color: transparent;
        color: #020617;
    }

    .p-button.p-button-link {
        color: #2563eb;
        background: transparent;
        border: transparent;
    }

    .p-button.p-button-link:not(:disabled):hover {
        background: transparent;
        color: #2563eb;
        border-color: transparent;
    }

    .p-button.p-button-link:not(:disabled):hover .p-button-label {
        text-decoration: underline;
    }

    .p-button.p-button-link:not(:disabled):focus {
        background: transparent;
        box-shadow: none;
        border-color: transparent;
    }

    .p-button.p-button-link:not(:disabled):active {
        background: transparent;
        color: #2563eb;
        border-color: transparent;
    }

    .p-speeddial-button.p-button.p-button-icon-only {
        width: 2.5rem;
        height: 2.5rem;
    }

    .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
        font-size: 1rem;
    }

    .p-speeddial-button.p-button.p-button-icon-only .p-icon {
        width: 1rem;
        height: 1rem;
    }

    .p-speeddial-list {
        outline: 0 none;
    }

    .p-speeddial-item.p-focus>.p-speeddial-action {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-speeddial-action {
        width: 2.5rem;
        height: 2.5rem;
        background: #f1f5f9;
        color: #475569;
    }

    .p-speeddial-action:hover {
        background: #e2e8f0;
        color: #334155;
    }

    .p-speeddial-direction-up .p-speeddial-item {
        margin: 0.25rem 0;
    }

    .p-speeddial-direction-up .p-speeddial-item:first-child {
        margin-bottom: 0.5rem;
    }

    .p-speeddial-direction-down .p-speeddial-item {
        margin: 0.25rem 0;
    }

    .p-speeddial-direction-down .p-speeddial-item:first-child {
        margin-top: 0.5rem;
    }

    .p-speeddial-direction-left .p-speeddial-item {
        margin: 0 0.25rem;
    }

    .p-speeddial-direction-left .p-speeddial-item:first-child {
        margin-right: 0.5rem;
    }

    .p-speeddial-direction-right .p-speeddial-item {
        margin: 0 0.25rem;
    }

    .p-speeddial-direction-right .p-speeddial-item:first-child {
        margin-left: 0.5rem;
    }

    .p-speeddial-circle .p-speeddial-item,
    .p-speeddial-semi-circle .p-speeddial-item,
    .p-speeddial-quarter-circle .p-speeddial-item {
        margin: 0;
    }

    .p-speeddial-circle .p-speeddial-item:first-child,
    .p-speeddial-circle .p-speeddial-item:last-child,
    .p-speeddial-semi-circle .p-speeddial-item:first-child,
    .p-speeddial-semi-circle .p-speeddial-item:last-child,
    .p-speeddial-quarter-circle .p-speeddial-item:first-child,
    .p-speeddial-quarter-circle .p-speeddial-item:last-child {
        margin: 0;
    }

    .p-speeddial-mask {
        background-color: rgba(0, 0, 0, 0.4);
    }

    .p-splitbutton {
        border-radius: 6px;
    }

    .p-splitbutton.p-button-rounded {
        border-radius: 2rem;
    }

    .p-splitbutton.p-button-rounded>.p-button {
        border-radius: 2rem;
    }

    .p-splitbutton.p-button-raised {
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }

    .p-carousel .p-carousel-content .p-carousel-prev,
    .p-carousel .p-carousel-content .p-carousel-next {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        margin: 0.5rem;
    }

    .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
    .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-carousel .p-carousel-content .p-carousel-prev:focus-visible,
    .p-carousel .p-carousel-content .p-carousel-next:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-carousel .p-carousel-indicators {
        padding: 1rem;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator button {
        background-color: #e2e8f0;
        width: 2rem;
        height: 0.5rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
        background: #cbd5e1;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-datatable .p-paginator-top {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }

    .p-datatable .p-paginator-bottom {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }

    .p-datatable .p-datatable-header {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        padding: 0.75rem 1rem;
        font-weight: 600;
    }

    .p-datatable .p-datatable-footer {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        padding: 0.75rem 1rem;
        font-weight: 600;
    }

    .p-datatable .p-datatable-thead>tr>th {
        text-align: left;
        padding: 0.75rem 1rem;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        font-weight: 600;
        color: #334155;
        background: #ffffff;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-datatable .p-datatable-tfoot>tr>td {
        text-align: left;
        padding: 0.75rem 1rem;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        font-weight: 600;
        color: #334155;
        background: #ffffff;
    }

    .p-datatable .p-sortable-column .p-sortable-column-icon {
        color: #64748b;
        margin-left: 0.5rem;
    }

    .p-datatable .p-sortable-column .p-sortable-column-badge {
        border-radius: 50%;
        height: 1rem;
        min-width: 1rem;
        line-height: 1rem;
        color: var(--primary-color);
        background: #EFF6FF;
        margin-left: 0.5rem;
    }

    .p-datatable .p-sortable-column:not(.p-highlight):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
        color: #475569;
    }

    .p-datatable .p-sortable-column.p-highlight {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
        color: var(--primary-color);
    }

    .p-datatable .p-sortable-column.p-highlight:hover {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
        color: var(--primary-color);
    }

    .p-datatable .p-sortable-column:focus-visible {
        box-shadow: 0 none;
        outline: 0 none;
    }

    .p-datatable .p-datatable-tbody>tr {
        background: #ffffff;
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        text-align: left;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        padding: 0.75rem 1rem;
    }

    .p-datatable .p-datatable-tbody>tr>td .p-row-toggler,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-datatable .p-datatable-tbody>tr>td .p-row-toggler:enabled:hover,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:enabled:hover,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:enabled:hover,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-datatable .p-datatable-tbody>tr>td .p-row-toggler:focus-visible,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:focus-visible,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:focus-visible,
    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save {
        margin-right: 0.5rem;
    }

    .p-datatable .p-datatable-tbody>tr:focus-visible {
        outline: 0.15rem solid var(--p-focus-ring-color);
        outline-offset: -0.15rem;
    }

    .p-datatable .p-datatable-tbody>tr.p-highlight {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
        box-shadow: inset 0 2px 0 0 #EFF6FF;
    }

    .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
        box-shadow: inset 0 -2px 0 0 #EFF6FF;
    }

    .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-datatable .p-column-resizer-helper {
        background: #3B82F6;
    }

    .p-datatable .p-datatable-scrollable-header,
    .p-datatable .p-datatable-scrollable-footer {
        background: #ffffff;
    }

    .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead,
    .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-tfoot,
    .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-scroller-viewport>.p-scroller>.p-datatable-table>.p-datatable-thead,
    .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-scroller-viewport>.p-scroller>.p-datatable-table>.p-datatable-tfoot {
        background-color: #ffffff;
    }

    .p-datatable .p-datatable-loading-icon {
        font-size: 2rem;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-header {
        border-width: 1px 1px 0 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-footer {
        border-width: 0 1px 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-paginator-top {
        border-width: 0 1px 0 1px;
    }

    .p-datatable.p-datatable-gridlines .p-paginator-bottom {
        border-width: 0 1px 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
        border-width: 1px 0 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:last-child {
        border-width: 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
        border-width: 1px 0 0 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:last-child {
        border-width: 1px 1px 0 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr:last-child>td {
        border-width: 1px 0 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr:last-child>td:last-child {
        border-width: 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td {
        border-width: 1px 0 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td:last-child {
        border-width: 1px 1px 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead+.p-datatable-tfoot>tr>td {
        border-width: 0 0 1px 1px;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead+.p-datatable-tfoot>tr>td:last-child {
        border-width: 0 1px 1px 1px;
    }

    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
        border-width: 0 0 1px 1px;
    }

    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td:last-child {
        border-width: 0 1px 1px 1px;
    }

    .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody>tr:last-child>td {
        border-width: 0 0 0 1px;
    }

    .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody>tr:last-child>td:last-child {
        border-width: 0 1px 0 1px;
    }

    .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
        background: #f8fafc;
    }

    .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler {
        color: var(--primary-color);
    }

    .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler:hover {
        color: var(--primary-color);
    }

    .p-datatable.p-datatable-sm .p-datatable-header {
        padding: 0.375rem 0.5rem;
    }

    .p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
        padding: 0.375rem 0.5rem;
    }

    .p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
        padding: 0.375rem 0.5rem;
    }

    .p-datatable.p-datatable-sm .p-datatable-tfoot>tr>td {
        padding: 0.375rem 0.5rem;
    }

    .p-datatable.p-datatable-sm .p-datatable-footer {
        padding: 0.375rem 0.5rem;
    }

    .p-datatable.p-datatable-lg .p-datatable-header {
        padding: 0.9375rem 1.25rem;
    }

    .p-datatable.p-datatable-lg .p-datatable-thead>tr>th {
        padding: 0.9375rem 1.25rem;
    }

    .p-datatable.p-datatable-lg .p-datatable-tbody>tr>td {
        padding: 0.9375rem 1.25rem;
    }

    .p-datatable.p-datatable-lg .p-datatable-tfoot>tr>td {
        padding: 0.9375rem 1.25rem;
    }

    .p-datatable.p-datatable-lg .p-datatable-footer {
        padding: 0.9375rem 1.25rem;
    }

    .p-dataview .p-paginator-top {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }

    .p-dataview .p-paginator-bottom {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }

    .p-dataview .p-dataview-header {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        padding: 0.75rem 1rem;
        font-weight: 600;
    }

    .p-dataview .p-dataview-content {
        background: #ffffff;
        color: #334155;
        border: 0 none;
        padding: 0;
    }

    .p-dataview .p-dataview-footer {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        padding: 0.75rem 1rem;
        font-weight: 600;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-dataview .p-dataview-loading-icon {
        font-size: 2rem;
    }

    .p-dataview .p-dataview-emptymessage {
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-column-filter-row .p-column-filter-menu-button,
    .p-column-filter-row .p-column-filter-clear-button {
        margin-left: 0.5rem;
    }

    .p-column-filter-menu-button {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-column-filter-menu-button:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-column-filter-menu-button.p-column-filter-menu-button-open,
    .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
        background: #f1f5f9;
        color: #475569;
    }

    .p-column-filter-menu-button.p-column-filter-menu-button-active,
    .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-column-filter-menu-button:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-column-filter-clear-button {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-column-filter-clear-button:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-column-filter-clear-button:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-column-filter-overlay {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        min-width: 12.5rem;
    }

    .p-column-filter-overlay .p-column-filter-row-items {
        padding: 0.25rem 0.25rem;
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
        margin: 2px 0;
        padding: 0.5rem 0.75rem;
        border: 0 none;
        color: #334155;
        background: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:first-child {
        margin-top: 0;
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
        color: #1e293b;
        background: #f1f5f9;
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: 0 none;
    }

    .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
        border-top: 1px solid #e2e8f0;
        margin: 2px 0;
    }

    .p-column-filter-overlay-menu .p-column-filter-operator {
        padding: 0.5rem 0.5rem 0 0.5rem;
        border-bottom: 0 none;
        color: #334155;
        background: #ffffff;
        margin: 0 0 0 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint {
        padding: 0 1.125rem 1.125rem 1.125rem;
        border-bottom: 1px solid #e2e8f0;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
        margin-bottom: 0.5rem;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
        margin-top: 0.5rem;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
        border-bottom: 0 none;
    }

    .p-column-filter-overlay-menu .p-column-filter-add-rule {
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-column-filter-overlay-menu .p-column-filter-buttonbar {
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-orderlist .p-orderlist-controls {
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-orderlist .p-orderlist-controls .p-button {
        margin-bottom: 0.5rem;
    }

    .p-orderlist .p-orderlist-list-container {
        background: #ffffff;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        outline-color: transparent;
    }

    .p-orderlist .p-orderlist-list-container.p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-orderlist .p-orderlist-header {
        color: #334155;
        padding: 1.125rem;
        font-weight: 600;
    }

    .p-orderlist .p-orderlist-header .p-orderlist-title {
        font-weight: 600;
    }

    .p-orderlist .p-orderlist-filter-container {
        padding: 1.125rem;
        background: #ffffff;
        border: 1px solid #e2e8f0;
        border-bottom: 0 none;
    }

    .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
        padding-right: 1.75rem;
    }

    .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
        right: 0.75rem;
        color: #94a3b8;
    }

    .p-orderlist .p-orderlist-list {
        color: #334155;
        padding: 0.25rem 0.25rem;
        outline: 0 none;
    }

    .p-orderlist .p-orderlist-list:not(:first-child) {
        border-top: 1px solid #e2e8f0;
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item {
        padding: 0.5rem 0.75rem;
        margin: 2px 0;
        border: 0 none;
        color: #334155;
        background: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item:first-child {
        margin-top: 0;
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
        color: #0f172a;
        background: #e2e8f0;
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight.p-focus {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-orderlist .p-orderlist-list .p-orderlist-empty-message {
        padding: 0.5rem 0.75rem;
        color: #334155;
    }

    .p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
        background: #f8fafc;
    }

    .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
        background: #f1f5f9;
    }

    .p-orderlist-item.cdk-drag-preview {
        padding: 0.5rem 0.75rem;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        border: 0 none;
        color: #334155;
        background: #ffffff;
        margin: 0;
    }

    .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-organizationchart .p-organizationchart-node-content.p-highlight {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
        color: #70aeff;
    }

    .p-organizationchart .p-organizationchart-line-down {
        background: #e2e8f0;
    }

    .p-organizationchart .p-organizationchart-line-left {
        border-right: 1px solid #e2e8f0;
        border-color: #e2e8f0;
    }

    .p-organizationchart .p-organizationchart-line-top {
        border-top: 1px solid #e2e8f0;
        border-color: #e2e8f0;
    }

    .p-organizationchart .p-organizationchart-node-content {
        border: 1px solid #e2e8f0;
        background: #ffffff;
        color: #334155;
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
        background: inherit;
        color: inherit;
        border-radius: 50%;
    }

    .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-paginator {
        background: #ffffff;
        color: #334155;
        border: solid #e2e8f0;
        border-width: 0;
        padding: 0.5rem 1rem;
        border-radius: 6px;
    }

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        background-color: transparent;
        border: 0 none;
        color: #64748b;
        min-width: 2.5rem;
        height: 2.5rem;
        margin: 0.143rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 50%;
    }

    .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
        background: #f1f5f9;
        border-color: transparent;
        color: #475569;
    }

    .p-paginator .p-paginator-first {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
    }

    .p-paginator .p-paginator-last {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    }

    .p-paginator .p-dropdown {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        height: 2.5rem;
    }

    .p-paginator .p-dropdown .p-dropdown-label {
        padding-right: 0;
    }

    .p-paginator .p-paginator-page-input {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .p-paginator .p-paginator-page-input .p-inputtext {
        max-width: 2.5rem;
    }

    .p-paginator .p-paginator-current {
        background-color: transparent;
        border: 0 none;
        color: #64748b;
        min-width: 2.5rem;
        height: 2.5rem;
        margin: 0.143rem;
        padding: 0 0.5rem;
    }

    .p-paginator .p-paginator-pages .p-paginator-page {
        background-color: transparent;
        border: 0 none;
        color: #64748b;
        min-width: 2.5rem;
        height: 2.5rem;
        margin: 0.143rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 50%;
    }

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background: #EFF6FF;
        border-color: #EFF6FF;
        color: var(--primary-color);
    }

    .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
        background: #f1f5f9;
        border-color: transparent;
        color: #475569;
    }

    .p-picklist .p-picklist-buttons {
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-picklist .p-picklist-buttons .p-button {
        margin-bottom: 0.5rem;
    }

    .p-picklist .p-picklist-list-wrapper {
        background: #ffffff;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        outline-color: transparent;
    }

    .p-picklist .p-picklist-list-wrapper.p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-picklist .p-picklist-header {
        color: #334155;
        padding: 1.125rem;
        font-weight: 600;
    }

    .p-picklist .p-picklist-header .p-picklist-title {
        font-weight: 600;
    }

    .p-picklist .p-picklist-filter-container {
        padding: 1.125rem;
        background: #ffffff;
        border: 1px solid #e2e8f0;
        border-bottom: 0 none;
    }

    .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
        padding-right: 1.75rem;
    }

    .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
        right: 0.75rem;
        color: #94a3b8;
    }

    .p-picklist .p-picklist-list {
        color: #334155;
        padding: 0.25rem 0.25rem;
        outline: 0 none;
    }

    .p-picklist .p-picklist-list:not(:first-child) {
        border-top: 1px solid #e2e8f0;
    }

    .p-picklist .p-picklist-list .p-picklist-item {
        padding: 0.5rem 0.75rem;
        margin: 2px 0;
        border: 0 none;
        color: #334155;
        background: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-picklist .p-picklist-list .p-picklist-item:first-child {
        margin-top: 0;
    }

    .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-picklist .p-picklist-list .p-picklist-item.p-focus {
        color: #0f172a;
        background: #e2e8f0;
    }

    .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-picklist .p-picklist-list .p-picklist-empty-message {
        padding: 0.5rem 0.75rem;
        color: #334155;
    }

    .p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
        background: #f8fafc;
    }

    .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
        background: #f1f5f9;
    }

    .p-picklist-item.cdk-drag-preview {
        padding: 0.5rem 0.75rem;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        border: 0 none;
        color: #334155;
        background: #ffffff;
        margin: 0;
    }

    .p-timeline .p-timeline-event-marker {
        border: 2px solid #e2e8f0;
        border-radius: 50%;
        width: 1.125rem;
        height: 1.125rem;
        background-color: #ffffff;
    }

    .p-timeline .p-timeline-event-connector {
        background-color: #e2e8f0;
    }

    .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
    .p-timeline.p-timeline-vertical .p-timeline-event-content {
        padding: 0 1rem;
    }

    .p-timeline.p-timeline-vertical .p-timeline-event-connector {
        width: 2px;
    }

    .p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
    .p-timeline.p-timeline-horizontal .p-timeline-event-content {
        padding: 1rem 0;
    }

    .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
        height: 2px;
    }

    .p-tree {
        border: 1px solid #e2e8f0;
        background: #ffffff;
        color: #334155;
        padding: 0 1.125rem 1.125rem 1.125rem;
        border-radius: 6px;
    }

    .p-tree .p-tree-container .p-treenode {
        padding: 0 0;
        outline: 0 none;
    }

    .p-tree .p-tree-container .p-treenode:focus>.p-treenode-content {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: 0 none;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content {
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        padding: 0.25rem 0.5rem;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
        margin-right: 0.5rem;
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
        margin-right: 0.5rem;
        color: #475569;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
        margin-right: 0.5rem;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
        color: #334155;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled .p-checkbox-box {
        background-color: #f8fafc;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
        background: #3B82F6;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box:hover {
        background-color: #f8fafc;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
        background: #2563eb;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
        color: var(--primary-color);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
        color: var(--primary-color);
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-tree .p-tree-filter-container {
        margin-bottom: 0.5rem;
    }

    .p-tree .p-tree-filter-container .p-tree-filter {
        width: 100%;
        padding-right: 1.75rem;
    }

    .p-tree .p-tree-filter-container .p-tree-filter-icon {
        right: 0.75rem;
        color: #94a3b8;
    }

    .p-tree .p-treenode-children {
        padding: 0 0 0 1rem;
    }

    .p-tree .p-tree-loading-icon {
        font-size: 2rem;
    }

    .p-tree .p-tree-loading-icon.p-icon {
        width: 2rem;
        height: 2rem;
    }

    .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
        background-color: #8cbeff;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
        border-radius: 6px;
        border: 1px solid #e2e8f0;
        background-color: #ffffff;
        color: #334155;
        padding: 0.25rem 0.5rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
        background-color: #EFF6FF;
        color: var(--primary-color);
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
        color: var(--primary-color);
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
        margin-right: 0.5rem;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
        color: #475569;
        margin-right: 0.5rem;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
        margin-right: 0.5rem;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
        background-color: inherit;
        color: inherit;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-treetable .p-paginator-top {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }

    .p-treetable .p-paginator-bottom {
        border-width: 0 0 1px 0;
        border-radius: 0;
    }

    .p-treetable .p-treetable-header {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        padding: 0.75rem 1rem;
        font-weight: 600;
    }

    .p-treetable .p-treetable-footer {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        padding: 0.75rem 1rem;
        font-weight: 600;
    }

    .p-treetable .p-treetable-thead>tr>th {
        text-align: left;
        padding: 0.75rem 1rem;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        font-weight: 600;
        color: #334155;
        background: #ffffff;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-treetable .p-treetable-tfoot>tr>td {
        text-align: left;
        padding: 0.75rem 1rem;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        font-weight: 600;
        color: #334155;
        background: #ffffff;
    }

    .p-treetable .p-sortable-column {
        outline-color: var(--p-focus-ring-color);
    }

    .p-treetable .p-sortable-column .p-sortable-column-icon {
        color: #64748b;
        margin-left: 0.5rem;
    }

    .p-treetable .p-sortable-column .p-sortable-column-badge {
        border-radius: 50%;
        height: 1rem;
        min-width: 1rem;
        line-height: 1rem;
        color: var(--primary-color);
        background: #EFF6FF;
        margin-left: 0.5rem;
    }

    .p-treetable .p-sortable-column:not(.p-highlight):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
        color: #475569;
    }

    .p-treetable .p-sortable-column.p-highlight {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
        color: var(--primary-color);
    }

    .p-treetable .p-treetable-tbody>tr {
        background: #ffffff;
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-treetable .p-treetable-tbody>tr>td {
        text-align: left;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        padding: 0.75rem 1rem;
    }

    .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        margin-right: 0.5rem;
    }

    .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler.p-icon {
        width: 2rem;
        height: 2rem;
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox {
        margin-right: 0.5rem;
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
        color: #334155;
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled .p-checkbox-box {
        background-color: #f8fafc;
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
        background: #3B82F6;
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box:hover {
        background-color: #f8fafc;
    }

    .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
        background: #2563eb;
    }

    .p-treetable .p-treetable-tbody>tr:focus-visible {
        outline: 0.15rem solid var(--p-focus-ring-color);
        outline-offset: -0.15rem;
    }

    .p-treetable .p-treetable-tbody>tr.p-highlight {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler {
        color: var(--primary-color);
    }

    .p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler:hover {
        color: var(--primary-color);
    }

    .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover .p-treetable-toggler {
        color: #1e293b;
    }

    .p-treetable .p-column-resizer-helper {
        background: #3B82F6;
    }

    .p-treetable .p-treetable-scrollable-header,
    .p-treetable .p-treetable-scrollable-footer {
        background: #ffffff;
    }

    .p-treetable .p-treetable-loading-icon {
        font-size: 2rem;
    }

    .p-treetable .p-treetable-loading-icon.p-icon {
        width: 2rem;
        height: 2rem;
    }

    .p-treetable.p-treetable-gridlines .p-datatable-header {
        border-width: 1px 1px 0 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-footer {
        border-width: 0 1px 1px 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-top {
        border-width: 0 1px 0 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-bottom {
        border-width: 0 1px 1px 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-thead>tr>th {
        border-width: 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-tbody>tr>td {
        border-width: 1px;
    }

    .p-treetable.p-treetable-gridlines .p-treetable-tfoot>tr>td {
        border-width: 1px;
    }

    .p-treetable.p-treetable-sm .p-treetable-header {
        padding: 0.65625rem 0.875rem;
    }

    .p-treetable.p-treetable-sm .p-treetable-thead>tr>th {
        padding: 0.375rem 0.5rem;
    }

    .p-treetable.p-treetable-sm .p-treetable-tbody>tr>td {
        padding: 0.375rem 0.5rem;
    }

    .p-treetable.p-treetable-sm .p-treetable-tfoot>tr>td {
        padding: 0.375rem 0.5rem;
    }

    .p-treetable.p-treetable-sm .p-treetable-footer {
        padding: 0.375rem 0.5rem;
    }

    .p-treetable.p-treetable-lg .p-treetable-header {
        padding: 0.9375rem 1.25rem;
    }

    .p-treetable.p-treetable-lg .p-treetable-thead>tr>th {
        padding: 0.9375rem 1.25rem;
    }

    .p-treetable.p-treetable-lg .p-treetable-tbody>tr>td {
        padding: 0.9375rem 1.25rem;
    }

    .p-treetable.p-treetable-lg .p-treetable-tfoot>tr>td {
        padding: 0.9375rem 1.25rem;
    }

    .p-treetable.p-treetable-lg .p-treetable-footer {
        padding: 0.9375rem 1.25rem;
    }

    .p-virtualscroller .p-virtualscroller-header {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        padding: 0.75rem 1rem;
        font-weight: 600;
    }

    .p-virtualscroller .p-virtualscroller-content {
        background: #ffffff;
        color: #334155;
        border: 0 none;
        padding: 0;
    }

    .p-virtualscroller .p-virtualscroller-footer {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
        padding: 0.75rem 1rem;
        font-weight: 600;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link {
        padding: 1.125rem 1.125rem 1.125rem 1.125rem;
        border: 0 none;
        color: #64748b;
        background: #ffffff;
        font-weight: 600;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
        margin-right: 0.5rem;
    }

    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: inset none;
    }

    .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
        background: #ffffff;
        border-color: #e2e8f0;
        color: #334155;
    }

    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
        background: #ffffff;
        border-color: #e2e8f0;
        color: #334155;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
        border-color: #e2e8f0;
        background: #ffffff;
        color: #334155;
    }

    .p-accordion .p-accordion-content {
        padding: 0 1.125rem 1.125rem 1.125rem;
        border: 0 none;
        background: #ffffff;
        color: #334155;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-accordion p-accordiontab .p-accordion-tab {
        margin-bottom: 0;
    }

    .p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
        border-radius: 0;
    }

    .p-accordion p-accordiontab .p-accordion-content {
        border-radius: 0;
    }

    .p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
        border-top: 0 none;
    }

    .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
    .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
        border-top: 0 none;
    }

    .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-accordion p-accordiontab:last-child .p-accordion-content {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-card {
        background: #ffffff;
        color: #334155;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }

    .p-card .p-card-body {
        padding: 1.5rem;
    }

    .p-card .p-card-title {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    .p-card .p-card-subtitle {
        font-weight: 400;
        margin-bottom: 0.5rem;
        color: #64748b;
    }

    .p-card .p-card-content {
        padding: 0 0 0 0;
    }

    .p-card .p-card-footer {
        padding: 0 0 0 0;
    }

    .p-divider .p-divider-content {
        background-color: #ffffff !important;
        ;
    }

    .p-divider.p-divider-horizontal {
        margin: 1rem 0 !important;
        ;
        padding: 0 1rem !important;
        ;
    }

    .p-divider.p-divider-horizontal:before {
        border-top: 1px #e2e8f0 !important;
        ;
    }

    .p-divider.p-divider-horizontal .p-divider-content {
        padding: 0 0.5rem !important;
        ;
    }

    .p-divider.p-divider-vertical {
        margin: 0 1rem !important;
        padding: 1rem 0 !important;
    }

    .p-divider.p-divider-vertical:before {
        border-left: 1px #e2e8f0 !important;
        ;
    }

    .p-divider.p-divider-vertical .p-divider-content {
        padding: 0.5rem 0 !important;
        ;
    }

    .p-fieldset {
        border: 1px solid #e2e8f0;
        background: #ffffff;
        color: #334155;
        border-radius: 6px;
    }

    .p-fieldset .p-fieldset-legend {
        padding: 1.125rem;
        border: 1px solid #e2e8f0;
        color: #334155;
        background: #ffffff;
        font-weight: 600;
        border-radius: 6px;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
        padding: 0;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
        padding: 1.125rem;
        color: #334155;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
        margin-right: 0.5rem;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
        background: #ffffff;
        border-color: #ffffff;
        color: #1e293b;
    }

    .p-fieldset .p-fieldset-content {
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-panel .p-panel-header {
        border: 1px solid #e2e8f0;
        padding: 1.125rem;
        background: #ffffff;
        color: #334155;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-panel .p-panel-header .p-panel-title {
        font-weight: 600;
    }

    .p-panel .p-panel-header .p-panel-header-icon {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-panel .p-panel-header .p-panel-header-icon:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-panel.p-panel-toggleable .p-panel-header {
        padding: 0.75rem 1.125rem;
    }

    .p-panel .p-panel-content {
        padding: 0 1.125rem 1.125rem 1.125rem;
        border: 1px solid #e2e8f0;
        background: #ffffff;
        color: #334155;
        border-top: 0 none;
    }

    .p-panel .p-panel-content:last-child {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-panel .p-panel-footer {
        padding: 0 1.125rem 1.125rem 1.125rem;
        border: 1px solid #e2e8f0;
        background: #ffffff;
        color: #334155;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top: 0 none;
    }

    .p-panel .p-panel-icons-end {
        order: 2;
        margin-left: auto;
    }

    .p-panel .p-panel-icons-start {
        order: 0;
        margin-right: 0.5rem;
    }

    .p-panel .p-panel-icons-center {
        order: 2;
        width: 100%;
        text-align: center;
    }

    .p-scrollpanel .p-scrollpanel-bar {
        background: #f1f5f9;
        border: 0 none;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-scrollpanel .p-scrollpanel-bar:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-splitter {
        border: 1px solid #e2e8f0;
        background: #ffffff;
        border-radius: 6px;
        color: #334155;
    }

    .p-splitter .p-splitter-gutter {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        background: #e2e8f0;
    }

    .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
        background: #e2e8f0;
    }

    .p-splitter .p-splitter-gutter .p-splitter-gutter-handle:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-splitter .p-splitter-gutter-resizing {
        background: #e2e8f0;
    }

    .p-tabview .p-tabview-nav-content {
        scroll-padding-inline: 2.5rem;
    }

    .p-tabview .p-tabview-nav {
        background: #ffffff;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
    }

    .p-tabview .p-tabview-nav li {
        margin-right: 0;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border: solid #e2e8f0;
        border-width: 0 0 1px 0;
        border-color: transparent transparent #e2e8f0 transparent;
        background: #ffffff;
        color: #64748b;
        padding: 1rem 1.125rem;
        font-weight: 600;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        margin: 0 0 -1px 0;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: inset none;
    }

    .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background: #ffffff;
        border-color: #e2e8f0;
        color: #334155;
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        background: #ffffff;
        border-color: #e2e8f0;
        color: #3B82F6;
    }

    .p-tabview .p-tabview-left-icon {
        margin-right: 0.5rem;
    }

    .p-tabview .p-tabview-right-icon {
        margin-left: 0.5rem;
    }

    .p-tabview .p-tabview-close {
        margin-left: 0.5rem;
    }

    .p-tabview .p-tabview-nav-btn.p-link {
        background: #ffffff;
        color: #3B82F6;
        width: 2.5rem;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        border-radius: 0;
    }

    .p-tabview .p-tabview-nav-btn.p-link:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: inset none;
    }

    .p-tabview .p-tabview-panels {
        background: #ffffff;
        padding: 0.875rem 1.125rem 1.125rem 1.125rem;
        border: 0 none;
        color: #334155;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-toolbar {
        background: #ffffff;
        border: 1px solid #e2e8f0;
        padding: 1.125rem;
        border-radius: 6px;
        gap: 0.5rem;
    }

    .p-toolbar .p-toolbar-separator {
        margin: 0 0.5rem;
    }

    .p-stepper .p-stepper-nav {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style-type: none;
        overflow-x: auto;
    }

    .p-stepper-vertical .p-stepper-nav {
        flex-direction: column;
    }

    .p-stepper-header {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        align-items: center;
    }

    .p-stepper-header:last-of-type {
        flex: initial;
    }

    .p-stepper-header .p-stepper-action {
        border: 0 none;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
    }

    .p-stepper-header .p-stepper-action:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-stepper.p-stepper-readonly .p-stepper-header {
        cursor: auto;
    }

    .p-stepper-header.p-highlight .p-stepper-action {
        cursor: default;
    }

    .p-stepper-title {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .p-stepper-number {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .p-stepper-separator {
        flex: 1 1 0;
    }

    .p-stepper .p-stepper-nav {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .p-stepper .p-stepper-header {
        padding: 0.5rem;
    }

    .p-stepper .p-stepper-header .p-stepper-action {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
        background: #ffffff;
        outline-color: transparent;
    }

    .p-stepper .p-stepper-header .p-stepper-action .p-stepper-number {
        color: #64748b;
        border: 1px solid #e2e8f0;
        border-width: 2px;
        background: #ffffff;
        min-width: 2rem;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.143rem;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-stepper .p-stepper-header .p-stepper-action .p-stepper-title {
        margin-left: 0.5rem;
        color: #334155;
        font-weight: 500;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-stepper .p-stepper-header .p-stepper-action:not(.p-disabled):focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-stepper .p-stepper-header.p-highlight .p-stepper-title {
        color: #334155;
    }

    .p-stepper .p-stepper-header:not(.p-disabled):focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
        background-color: #3B82F6;
    }

    .p-stepper .p-stepper-panels {
        background: #ffffff;
        padding: 0.875rem 1.125rem 1.125rem 1.125rem;
        color: #334155;
    }

    .p-stepper .p-stepper-separator {
        background-color: #e2e8f0;
        width: 100%;
        height: 2px;
        margin-inline-start: 1rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-stepper.p-stepper-vertical {
        display: flex;
        flex-direction: column;
    }

    .p-stepper.p-stepper-vertical .p-stepper-toggleable-content {
        display: flex;
        flex: 1 1 auto;
        background: #ffffff;
        color: #334155;
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel {
        display: flex;
        flex-direction: column;
        flex: initial;
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel.p-stepper-panel-active {
        flex: 1 1 auto;
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-header {
        flex: initial;
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-content {
        width: 100%;
        padding-left: 1rem;
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-separator {
        flex: 0 0 auto;
        width: 2px;
        height: auto;
        margin-inline-start: calc(1.75rem + 2px);
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel:has(~ .p-stepper-panel-active) .p-stepper-separator {
        background-color: #3B82F6;
    }

    .p-stepper.p-stepper-vertical .p-stepper-panel:last-of-type .p-stepper-content {
        padding-left: 3rem;
    }

    .p-confirm-popup {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
    }

    .p-confirm-popup .p-confirm-popup-content {
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-confirm-popup .p-confirm-popup-footer {
        text-align: right;
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-confirm-popup .p-confirm-popup-footer button {
        margin: 0 0.5rem 0 0;
        width: auto;
    }

    .p-confirm-popup .p-confirm-popup-footer button:last-child {
        margin: 0;
    }

    .p-confirm-popup:after {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
    }

    .p-confirm-popup:before {
        border: solid transparent;
        border-color: rgba(226, 232, 240, 0);
        border-bottom-color: #e2e8f0;
    }

    .p-confirm-popup.p-confirm-popup-flipped:after {
        border-top-color: #ffffff;
    }

    .p-confirm-popup.p-confirm-popup-flipped:before {
        border-top-color: #e2e8f0;
    }

    .p-confirm-popup .p-confirm-popup-icon {
        font-size: 1.5rem;
    }

    .p-confirm-popup .p-confirm-popup-icon.p-icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .p-confirm-popup .p-confirm-popup-message {
        margin-left: 1rem;
    }

    .p-dialog {
        border-radius: 6px;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
        border: 1px solid #e2e8f0;
    }

    .p-dialog .p-dialog-header {
        border-bottom: 0 none;
        background: #ffffff;
        color: #334155;
        padding: 1.5rem;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-dialog .p-dialog-header .p-dialog-title {
        font-weight: 600;
        font-size: 1.25rem;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        margin-right: 0.5rem;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
        margin-right: 0;
    }

    .p-dialog .p-dialog-content {
        background: #ffffff;
        color: #334155;
        padding: 0 1.5rem 1.5rem 1.5rem;
    }

    .p-dialog .p-dialog-content:last-of-type {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-dialog .p-dialog-footer {
        border-top: 0 none;
        background: #ffffff;
        color: #334155;
        padding: 0 1.5rem 1.5rem 1.5rem;
        text-align: right;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-dialog .p-dialog-footer button {
        margin: 0 0.5rem 0 0;
        width: auto;
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
        font-size: 2rem;
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
        width: 2rem;
        height: 2rem;
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
        margin-left: 1rem;
    }

    .p-overlaypanel {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
    }

    .p-overlaypanel .p-overlaypanel-content {
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-overlaypanel .p-overlaypanel-close {
        background: #3B82F6;
        color: #ffffff;
        width: 1.75rem;
        height: 1.75rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 50%;
        position: absolute;
        top: -0.875rem;
        right: -0.875rem;
    }

    .p-overlaypanel .p-overlaypanel-close:enabled:hover {
        background: #2563eb;
        color: #ffffff;
    }

    .p-overlaypanel:after {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
    }

    .p-overlaypanel:before {
        border: solid transparent;
        border-color: rgba(226, 232, 240, 0);
        border-bottom-color: #d3dce8;
    }

    .p-overlaypanel.p-overlaypanel-flipped:after {
        border-top-color: #ffffff;
    }

    .p-overlaypanel.p-overlaypanel-flipped:before {
        border-top-color: #e2e8f0;
    }

    .p-sidebar {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
    }

    .p-sidebar .p-sidebar-header {
        padding: 1.125rem;
    }

    .p-sidebar .p-sidebar-header .p-sidebar-close,
    .p-sidebar .p-sidebar-header .p-sidebar-icon {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
    .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-sidebar .p-sidebar-header .p-sidebar-close:focus-visible,
    .p-sidebar .p-sidebar-header .p-sidebar-icon:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-sidebar .p-sidebar-header+.p-sidebar-content {
        padding-top: 0;
    }

    .p-sidebar .p-sidebar-content {
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-sidebar .p-sidebar-footer {
        padding: 1.125rem;
    }

    .p-tooltip .p-tooltip-text {
        background: #334155;
        color: #ffffff;
        padding: 0.5rem 0.75rem;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }

    .p-tooltip.p-tooltip-right .p-tooltip-arrow {
        border-right-color: #334155;
    }

    .p-tooltip.p-tooltip-left .p-tooltip-arrow {
        border-left-color: #334155;
    }

    .p-tooltip.p-tooltip-top .p-tooltip-arrow {
        border-top-color: #334155;
    }

    .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
        border-bottom-color: #334155;
    }

    .p-fileupload .p-fileupload-buttonbar {
        background: #ffffff;
        padding: 1.125rem;
        border: 1px solid #e2e8f0;
        color: #334155;
        border-bottom: 0 none;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-fileupload .p-fileupload-buttonbar .p-button {
        margin-right: 0.5rem;
    }

    .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-fileupload .p-fileupload-content {
        background: #ffffff;
        padding: 0 1.125rem 1.125rem 1.125rem;
        border: 1px solid #e2e8f0;
        color: #334155;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-fileupload .p-fileupload-content.p-fileupload-highlight {
        border-color: 1px dashed #3B82F6;
        border-style: dashed;
        background-color: #EFF6FF;
    }

    .p-fileupload .p-progressbar {
        height: 0.25rem;
    }

    .p-fileupload .p-fileupload-row>div {
        padding: 0.75rem 1rem;
    }

    .p-fileupload.p-fileupload-advanced .p-message {
        margin-top: 0;
    }

    .p-fileupload-choose:not(.p-disabled):hover {
        background: #2563eb;
        color: #ffffff;
        border-color: #2563eb;
    }

    .p-fileupload-choose:not(.p-disabled):active {
        background: var(--primary-color);
        color: #ffffff;
        border-color: var(--primary-color);
    }

    .p-breadcrumb {
        background: #ffffff;
        border: 0 none;
        border-radius: 6px;
        padding: 1rem;
    }

    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
    }

    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
        color: #94a3b8;
    }

    .p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
        margin: 0 0.5rem 0 0.5rem;
        color: #94a3b8;
    }

    .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
        color: #334155;
    }

    .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
        color: #94a3b8;
    }

    .p-contextmenu {
        padding: 0.25rem 0.25rem;
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        width: 12.5rem;
    }

    .p-contextmenu .p-contextmenu-root-list {
        outline: 0 none;
    }

    .p-contextmenu .p-submenu-list {
        padding: 0.25rem 0.25rem;
        background: #ffffff;
        border: 1px solid #e2e8f0;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }

    .p-contextmenu .p-menuitem>.p-menuitem-content {
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
        color: #334155;
        padding: 0.5rem 0.75rem;
        user-select: none;
    }

    .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #94a3b8;
        margin-right: 0.5rem;
    }

    .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #94a3b8;
    }

    .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: var(--primary-color);
    }

    .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: var(--primary-color);
    }

    .p-contextmenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
        color: #334155;
        background: #e2e8f0;
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
        color: #334155;
        background: #f1f5f9;
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-contextmenu .p-menuitem-separator {
        border-top: 1px solid #e2e8f0;
        margin: 2px 0;
    }

    .p-contextmenu .p-submenu-icon {
        font-size: 0.875rem;
    }

    .p-contextmenu .p-submenu-icon.p-icon {
        width: 0.875rem;
        height: 0.875rem;
    }

    .p-dock .p-dock-list-container {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.2);
        padding: 0.5rem 0.5rem;
        border-radius: 0.5rem;
    }

    .p-dock .p-dock-list-container .p-dock-list {
        outline: 0 none;
    }

    .p-dock .p-dock-item {
        padding: 0.5rem;
        border-radius: 6px;
    }

    .p-dock .p-dock-item.p-focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: 0 none;
    }

    .p-dock .p-dock-link {
        width: 3rem;
        height: 3rem;
    }

    .p-dock.p-dock-top .p-dock-item-second-prev,
    .p-dock.p-dock-top .p-dock-item-second-next,
    .p-dock.p-dock-bottom .p-dock-item-second-prev,
    .p-dock.p-dock-bottom .p-dock-item-second-next {
        margin: 0 0.9rem;
    }

    .p-dock.p-dock-top .p-dock-item-prev,
    .p-dock.p-dock-top .p-dock-item-next,
    .p-dock.p-dock-bottom .p-dock-item-prev,
    .p-dock.p-dock-bottom .p-dock-item-next {
        margin: 0 1.3rem;
    }

    .p-dock.p-dock-top .p-dock-item-current,
    .p-dock.p-dock-bottom .p-dock-item-current {
        margin: 0 1.5rem;
    }

    .p-dock.p-dock-left .p-dock-item-second-prev,
    .p-dock.p-dock-left .p-dock-item-second-next,
    .p-dock.p-dock-right .p-dock-item-second-prev,
    .p-dock.p-dock-right .p-dock-item-second-next {
        margin: 0.9rem 0;
    }

    .p-dock.p-dock-left .p-dock-item-prev,
    .p-dock.p-dock-left .p-dock-item-next,
    .p-dock.p-dock-right .p-dock-item-prev,
    .p-dock.p-dock-right .p-dock-item-next {
        margin: 1.3rem 0;
    }

    .p-dock.p-dock-left .p-dock-item-current,
    .p-dock.p-dock-right .p-dock-item-current {
        margin: 1.5rem 0;
    }

    @media screen and (max-width: 960px) {

        .p-dock.p-dock-top .p-dock-list-container,
        .p-dock.p-dock-bottom .p-dock-list-container {
            overflow-x: auto;
            width: 100%;
        }

        .p-dock.p-dock-top .p-dock-list-container .p-dock-list,
        .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
            margin: 0 auto;
        }

        .p-dock.p-dock-left .p-dock-list-container,
        .p-dock.p-dock-right .p-dock-list-container {
            overflow-y: auto;
            height: 100%;
        }

        .p-dock.p-dock-left .p-dock-list-container .p-dock-list,
        .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
            margin: auto 0;
        }

        .p-dock .p-dock-list .p-dock-item {
            transform: none;
            margin: 0;
        }
    }

    .p-megamenu {
        padding: 0.5rem 0.5rem;
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
    }

    .p-megamenu .p-megamenu-root-list {
        outline: 0 none;
    }

    .p-megamenu .p-menuitem>.p-menuitem-content {
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
        color: #334155;
        padding: 0.5rem 0.75rem;
        user-select: none;
    }

    .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #94a3b8;
        margin-right: 0.5rem;
    }

    .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #94a3b8;
    }

    .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: var(--primary-color);
    }

    .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: var(--primary-color);
    }

    .p-megamenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
        color: #334155;
        background: #e2e8f0;
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
        color: #334155;
        background: #f1f5f9;
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-megamenu .p-megamenu-panel {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }

    .p-megamenu .p-submenu-header {
        margin: 0;
        padding: 0.5rem 0.75rem;
        color: #94a3b8;
        background: #ffffff;
        font-weight: 600;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-megamenu .p-submenu-list {
        padding: 0.25rem 0.25rem;
        width: 12.5rem;
    }

    .p-megamenu .p-submenu-list .p-menuitem-separator {
        border-top: 1px solid #e2e8f0;
        margin: 2px 0;
    }

    .p-megamenu.p-megamenu-vertical {
        width: 12.5rem;
        padding: 0.25rem 0.25rem;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content {
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link {
        padding: 0.5rem 0.75rem;
        user-select: none;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #94a3b8;
        margin-right: 0.5rem;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #94a3b8;
        margin-left: 0.5rem;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
        color: #334155;
        background: #f1f5f9;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-menu {
        padding: 0.25rem 0.25rem;
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        width: 12.5rem;
    }

    .p-menu .p-menuitem>.p-menuitem-content {
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link {
        color: #334155;
        padding: 0.5rem 0.75rem;
        user-select: none;
    }

    .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #94a3b8;
        margin-right: 0.5rem;
    }

    .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #94a3b8;
    }

    .p-menu .p-menuitem.p-highlight>.p-menuitem-content {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-menu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: var(--primary-color);
    }

    .p-menu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: var(--primary-color);
    }

    .p-menu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
        color: #334155;
        background: #e2e8f0;
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
        color: #334155;
        background: #f1f5f9;
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-menu.p-menu-overlay {
        background: #ffffff;
        border: 1px solid #e2e8f0;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-menu .p-submenu-header {
        margin: 0;
        padding: 0.5rem 0.75rem;
        color: #94a3b8;
        background: #ffffff;
        font-weight: 600;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .p-menu .p-menuitem-separator {
        border-top: 1px solid #e2e8f0;
        margin: 2px 0;
    }

    .p-menu .p-menuitem-badge {
        background: #3B82F6;
        color: #ffffff;
        font-size: 0.75rem;
        font-weight: 700;
        min-width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        border-radius: 6px;
        margin-left: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .p-menubar {
        padding: 0.5rem 0.5rem;
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
    }

    .p-menubar .p-menubar-root-list {
        outline: 0 none;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content {
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link {
        padding: 0.5rem 0.75rem;
        user-select: none;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #94a3b8;
        margin-right: 0.5rem;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #94a3b8;
        margin-left: 0.5rem;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
        color: #334155;
        background: #f1f5f9;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-menubar .p-menuitem>.p-menuitem-content {
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link {
        color: #334155;
        padding: 0.5rem 0.75rem;
        user-select: none;
    }

    .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #94a3b8;
        margin-right: 0.5rem;
    }

    .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #94a3b8;
    }

    .p-menubar .p-menuitem.p-highlight>.p-menuitem-content {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: var(--primary-color);
    }

    .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: var(--primary-color);
    }

    .p-menubar .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
        color: #334155;
        background: #e2e8f0;
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
        color: #334155;
        background: #f1f5f9;
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-menubar .p-submenu-list {
        padding: 0.25rem 0.25rem;
        background: #ffffff;
        border: 1px solid #e2e8f0;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        width: 12.5rem;
        border-radius: 6px;
    }

    .p-menubar .p-submenu-list .p-menuitem-separator {
        border-top: 1px solid #e2e8f0;
        margin: 2px 0;
    }

    .p-menubar .p-submenu-list .p-submenu-icon {
        font-size: 0.875rem;
    }

    @media screen and (max-width: 960px) {
        .p-menubar {
            position: relative;
        }

        .p-menubar .p-menubar-button {
            display: flex;
            width: 1.75rem;
            height: 1.75rem;
            color: #94a3b8;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        }

        .p-menubar .p-menubar-button:hover {
            color: #334155;
            background: #f1f5f9;
        }

        .p-menubar .p-menubar-button:focus {
            outline: 1px solid var(--p-focus-ring-color);
            outline-offset: 2px;
            box-shadow: none;
        }

        .p-menubar .p-menubar-root-list {
            position: absolute;
            display: none;
            padding: 0.25rem 0.25rem;
            background: #ffffff;
            border: 1px solid #e2e8f0;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
            width: 100%;
        }

        .p-menubar .p-menubar-root-list .p-menuitem-separator {
            border-top: 1px solid #e2e8f0;
            margin: 2px 0;
        }

        .p-menubar .p-menubar-root-list .p-submenu-icon {
            font-size: 0.875rem;
        }

        .p-menubar .p-menubar-root-list .p-menuitem {
            width: 100%;
            position: static;
        }

        .p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
            margin-left: auto;
            transition: transform 0.2s;
        }

        .p-menubar .p-menubar-root-list .p-menuitem.p-menuitem-active>.p-menuitem-content>.p-menuitem-link>.p-submenu-icon {
            transform: rotate(-180deg);
        }

        .p-menubar .p-menubar-root-list .p-submenu-list {
            width: 100%;
            position: static;
            box-shadow: none;
            border: 0 none;
        }

        .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
            transition: transform 0.2s;
            transform: rotate(90deg);
        }

        .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active>.p-menuitem-content>.p-menuitem-link>.p-submenu-icon {
            transform: rotate(-90deg);
        }

        .p-menubar .p-menubar-root-list .p-menuitem {
            width: 100%;
            position: static;
        }

        .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
            padding-left: 1.5rem;
        }

        .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
            padding-left: 2.5rem;
        }

        .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
            padding-left: 3.5rem;
        }

        .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
            padding-left: 4.5rem;
        }

        .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
            padding-left: 5.5rem;
        }

        .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
            display: flex;
            flex-direction: column;
            top: 100%;
            left: 0;
            z-index: 1;
        }
    }

    .p-panelmenu .p-panelmenu-header {
        outline: 0 none;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
        border: 0 none;
        color: #64748b;
        background: #ffffff;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
        color: #64748b;
        padding: 1.125rem 1.125rem 1.125rem 1.125rem;
        font-weight: 600;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
        margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
        margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: inset none;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
        background: #ffffff;
        border-color: #e2e8f0;
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
        background: #ffffff;
        border-color: #e2e8f0;
        color: #334155;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: 0;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
        border-color: #e2e8f0;
        background: #ffffff;
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-content {
        padding: 0.25rem 0.25rem;
        border: 0 none;
        background: #ffffff;
        color: #334155;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-panelmenu .p-panelmenu-content .p-panelmenu-root-list {
        outline: 0 none;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content {
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link {
        color: #334155;
        padding: 0.5rem 0.75rem;
        user-select: none;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #94a3b8;
        margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #94a3b8;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: var(--primary-color);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: var(--primary-color);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
        color: #334155;
        background: #e2e8f0;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
        color: #334155;
        background: #f1f5f9;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
        margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem-separator {
        border-top: 1px solid #e2e8f0;
        margin: 2px 0;
    }

    .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-list) {
        padding: 0 0 0 1rem;
    }

    .p-panelmenu .p-panelmenu-panel {
        margin-bottom: 0;
    }

    .p-panelmenu .p-panelmenu-panel .p-panelmenu-header .p-panelmenu-header-content {
        border-radius: 0;
    }

    .p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
        border-radius: 0;
    }

    .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header .p-panelmenu-header-content {
        border-top: 0 none;
    }

    .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content,
    .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
        border-top: 0 none;
    }

    .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header .p-panelmenu-header-content {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) .p-panelmenu-header-content {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-slidemenu {
        padding: 0.25rem 0.25rem;
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        width: 12.5rem;
    }

    .p-slidemenu .p-slidemenu-root-list {
        outline: 0 none;
    }

    .p-slidemenu .p-submenu-list {
        outline: 0 none;
    }

    .p-slidemenu .p-menuitem>.p-menuitem-content {
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
        color: #334155;
        padding: 0.5rem 0.75rem;
        user-select: none;
    }

    .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #94a3b8;
        margin-right: 0.5rem;
    }

    .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #94a3b8;
    }

    .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: var(--primary-color);
    }

    .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: var(--primary-color);
    }

    .p-slidemenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
        color: #334155;
        background: #e2e8f0;
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
        color: #334155;
        background: #f1f5f9;
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-slidemenu.p-slidemenu-overlay {
        background: #ffffff;
        border: 1px solid #e2e8f0;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-slidemenu .p-slidemenu-list {
        padding: 0.25rem 0.25rem;
        background: #ffffff;
        border: 1px solid #e2e8f0;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-slidemenu .p-menuitem-separator {
        border-top: 1px solid #e2e8f0;
        margin: 2px 0;
    }

    .p-slidemenu .p-slidemenu-icon {
        font-size: 0.875rem;
    }

    .p-slidemenu .p-icon {
        width: 0.875rem;
        height: 0.875rem;
    }

    .p-slidemenu .p-slidemenu-backward {
        padding: 0.5rem 0.75rem;
        color: #334155;
    }

    .p-slidemenu .p-slidemenu-backward:not(.p-disabled):focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: inset none;
    }

    .p-slidemenu .p-menuitem-badge {
        background: #3B82F6;
        color: #ffffff;
        font-size: 0.75rem;
        font-weight: 700;
        min-width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        border-radius: 6px;
        margin-left: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .p-steps .p-steps-item .p-menuitem-link {
        background: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
        background: #ffffff;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        color: #64748b;
        border: 1px solid #e2e8f0;
        background: #ffffff;
        min-width: 2rem;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.143rem;
        z-index: 1;
        border-radius: 50%;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
        margin-top: 0.5rem;
        color: #334155;
    }

    .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-steps .p-steps-item.p-highlight .p-steps-number {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-steps .p-steps-item.p-highlight .p-steps-title {
        font-weight: 500;
        color: #334155;
    }

    .p-steps .p-steps-item:before {
        content: " ";
        border-top: 1px solid #e2e8f0;
        width: 100%;
        top: 50%;
        left: 0;
        display: block;
        position: absolute;
        margin-top: -1rem;
    }

    .p-tabmenu .p-tabmenu-nav {
        background: #ffffff;
        border: 1px solid #e2e8f0;
        border-width: 0 0 1px 0;
    }

    .p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
        background: #3B82F6;
        color: #ffffff;
        font-size: 0.75rem;
        font-weight: 700;
        min-width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        border-radius: 6px;
        margin-left: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
        margin-right: 0;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        border: solid #e2e8f0;
        border-width: 0 0 1px 0;
        border-color: transparent transparent #e2e8f0 transparent;
        background: #ffffff;
        color: #64748b;
        padding: 1rem 1.125rem;
        font-weight: 600;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        margin: 0 0 -1px 0;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
        margin-right: 0.5rem;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: inset none;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
        background: #ffffff;
        border-color: #e2e8f0;
        color: #334155;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
        background: #ffffff;
        border-color: #e2e8f0;
        color: #3B82F6;
    }

    .p-tabmenu .p-tabmenu-left-icon {
        margin-right: 0.5rem;
    }

    .p-tabmenu .p-tabmenu-right-icon {
        margin-left: 0.5rem;
    }

    .p-tabmenu .p-tabmenu-nav-btn.p-link {
        background: #ffffff;
        color: #3B82F6;
        width: 2.5rem;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        border-radius: 0;
    }

    .p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: inset none;
    }

    .p-tieredmenu {
        padding: 0.25rem 0.25rem;
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        width: 12.5rem;
    }

    .p-tieredmenu.p-tieredmenu-overlay {
        background: #ffffff;
        border: 1px solid #e2e8f0;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-tieredmenu .p-tieredmenu-root-list {
        outline: 0 none;
    }

    .p-tieredmenu .p-submenu-list {
        padding: 0.25rem 0.25rem;
        background: #ffffff;
        border: 1px solid #e2e8f0;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }

    .p-tieredmenu .p-menuitem>.p-menuitem-content {
        color: #334155;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 4px;
    }

    .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
        color: #334155;
        padding: 0.5rem 0.75rem;
        user-select: none;
    }

    .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #94a3b8;
        margin-right: 0.5rem;
    }

    .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #94a3b8;
    }

    .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content {
        color: var(--primary-color);
        background: #EFF6FF;
    }

    .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: var(--primary-color);
    }

    .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: var(--primary-color);
    }

    .p-tieredmenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
        background: rgba(59, 130, 246, 0.24);
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
        color: #334155;
        background: #e2e8f0;
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
        color: #334155;
        background: #f1f5f9;
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #334155;
    }

    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #334155;
    }

    .p-tieredmenu .p-menuitem-separator {
        border-top: 1px solid #e2e8f0;
        margin: 2px 0;
    }

    .p-tieredmenu .p-submenu-icon {
        font-size: 0.875rem;
    }

    .p-tieredmenu .p-submenu-icon.p-icon {
        width: 0.875rem;
        height: 0.875rem;
    }

    .p-inline-message {
        padding: 0.5rem 0.75rem;
        margin: 0;
        border-radius: 6px;
    }

    .p-inline-message.p-inline-message-info {
        background: rgba(239, 246, 255, 0.95);
        border: solid #bfdbfe;
        border-width: 1px;
        color: #2563eb;
    }

    .p-inline-message.p-inline-message-info .p-inline-message-icon {
        color: #2563eb;
    }

    .p-inline-message.p-inline-message-success {
        background: rgba(240, 253, 244, 0.95);
        border: solid #bbf7d0;
        border-width: 1px;
        color: #16a34a;
    }

    .p-inline-message.p-inline-message-success .p-inline-message-icon {
        color: #16a34a;
    }

    .p-inline-message.p-inline-message-warn {
        background: rgba(254, 252, 232, 0.95);
        border: solid #fde68a;
        border-width: 1px;
        color: #ca8a04;
    }

    .p-inline-message.p-inline-message-warn .p-inline-message-icon {
        color: #ca8a04;
    }

    .p-inline-message.p-inline-message-error {
        background: rgba(254, 242, 242, 0.95);
        border: solid #fecaca;
        border-width: 1px;
        color: #dc2626;
    }

    .p-inline-message.p-inline-message-error .p-inline-message-icon {
        color: #dc2626;
    }

    .p-inline-message .p-inline-message-icon {
        font-size: 1rem;
        margin-right: 0.5rem;
    }

    .p-inline-message .p-icon {
        width: 1rem;
        height: 1rem;
    }

    .p-inline-message .p-inline-message-text {
        font-size: 1rem;
    }

    .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
        margin-right: 0;
    }

    .p-message {
        margin: 1rem 0;
        border-radius: 6px;
    }

    .p-message .p-message-wrapper {
        padding: 0.5rem 0.75rem;
    }

    .p-message .p-message-close {
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 50%;
        background: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-message .p-message-close:hover {
        background: rgba(255, 255, 255, 0.5);
    }

    .p-message .p-message-close:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-message.p-message-info {
        background: rgba(239, 246, 255, 0.95);
        border: solid #bfdbfe;
        border-width: 1px;
        color: #2563eb;
    }

    .p-message.p-message-info .p-message-icon {
        color: #2563eb;
    }

    .p-message.p-message-info .p-message-close {
        color: #2563eb;
    }

    .p-message.p-message-success {
        background: rgba(240, 253, 244, 0.95);
        border: solid #bbf7d0;
        border-width: 1px;
        color: #16a34a;
    }

    .p-message.p-message-success .p-message-icon {
        color: #16a34a;
    }

    .p-message.p-message-success .p-message-close {
        color: #16a34a;
    }

    .p-message.p-message-warn {
        background: rgba(254, 252, 232, 0.95);
        border: solid #fde68a;
        border-width: 1px;
        color: #ca8a04;
    }

    .p-message.p-message-warn .p-message-icon {
        color: #ca8a04;
    }

    .p-message.p-message-warn .p-message-close {
        color: #ca8a04;
    }

    .p-message.p-message-error {
        background: rgba(254, 242, 242, 0.95);
        border: solid #fecaca;
        border-width: 1px;
        color: #dc2626;
    }

    .p-message.p-message-error .p-message-icon {
        color: #dc2626;
    }

    .p-message.p-message-error .p-message-close {
        color: #dc2626;
    }

    .p-message .p-message-text {
        font-size: 1rem;
        font-weight: 500;
    }

    .p-message .p-message-icon {
        font-size: 1rem;
        margin-right: 0.5rem;
    }

    .p-message .p-icon {
        width: 1rem;
        height: 1rem;
    }

    .p-message .p-message-summary {
        font-weight: 700;
    }

    .p-message .p-message-detail {
        margin-left: 0.5rem;
    }

    .p-message.p-message-secondary {
        background: #f1f5f9;
        border: solid #e2e8f0;
        border-width: 1px;
        color: #475569;
    }

    .p-message.p-message-secondary .p-message-icon {
        color: #475569;
    }

    .p-message.p-message-secondary .p-message-close {
        color: #475569;
    }

    .p-message.p-message-contrast {
        background: #020617;
        border: solid #64748b;
        border-width: 1px;
        color: #f8fafc;
    }

    .p-message.p-message-contrast .p-message-icon {
        color: #f8fafc;
    }

    .p-message.p-message-contrast .p-message-close {
        color: #f8fafc;
    }

    .p-toast {
        opacity: 1;
    }

    .p-toast .p-toast-message {
        margin: 0 0 1rem 0;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }

    .p-toast .p-toast-message .p-toast-message-content {
        padding: 0.75rem;
        border-width: 1px;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
        margin: 0 0 0 0.5rem;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
        font-size: 1.125rem;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-icon:not(.p-toast-icon-close-icon) {
        width: 1.125rem;
        height: 1.125rem;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
        font-weight: 500;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
        margin: 0.5rem 0 0 0;
    }

    .p-toast .p-toast-message .p-toast-icon-close {
        width: 1.125rem;
        height: 1.125rem;
        border-radius: 50%;
        background: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-toast .p-toast-message .p-toast-icon-close:hover {
        background: rgba(255, 255, 255, 0.5);
    }

    .p-toast .p-toast-message .p-toast-icon-close:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-toast .p-toast-message.p-toast-message-info {
        background: rgba(239, 246, 255, 0.95);
        border: solid #bfdbfe;
        border-width: 1px;
        color: #2563eb;
    }

    .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
        color: #2563eb;
    }

    .p-toast .p-toast-message.p-toast-message-success {
        background: rgba(240, 253, 244, 0.95);
        border: solid #bbf7d0;
        border-width: 1px;
        color: #16a34a;
    }

    .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
        color: #16a34a;
    }

    .p-toast .p-toast-message.p-toast-message-warn {
        background: rgba(254, 252, 232, 0.95);
        border: solid #fde68a;
        border-width: 1px;
        color: #ca8a04;
    }

    .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
        color: #ca8a04;
    }

    .p-toast .p-toast-message.p-toast-message-error {
        background: rgba(254, 242, 242, 0.95);
        border: solid #fecaca;
        border-width: 1px;
        color: #dc2626;
    }

    .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
        color: #dc2626;
    }

    .p-toast .p-toast-message.p-toast-message-secondary {
        background: #f1f5f9;
        border: solid #e2e8f0;
        border-width: 1px;
        color: #475569;
    }

    .p-toast .p-toast-message.p-toast-message-secondary .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-secondary .p-toast-icon-close {
        color: #475569;
    }

    .p-toast .p-toast-message.p-toast-message-contrast {
        background: #020617;
        border: solid #64748b;
        border-width: 1px;
        color: #f8fafc;
    }

    .p-toast .p-toast-message.p-toast-message-contrast .p-toast-message-icon,
    .p-toast .p-toast-message.p-toast-message-contrast .p-toast-icon-close {
        color: #f8fafc;
    }

    .toast-title-margin-0 .toast-title {
        margin-top: 0px !important;
    }

    .p-galleria .p-galleria-close {
        margin: 0.5rem;
        background: transparent;
        color: #f1f5f9;
        width: 4rem;
        height: 4rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 50%;
    }

    .p-galleria .p-galleria-close .p-galleria-close-icon {
        font-size: 2rem;
    }

    .p-galleria .p-galleria-close .p-icon-wrapper .p-icon {
        width: 2rem;
        height: 2rem;
    }

    .p-galleria .p-galleria-close:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #f1f5f9;
    }

    .p-galleria .p-galleria-item-nav {
        background: transparent;
        color: #f1f5f9;
        width: 4rem;
        height: 4rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 6px;
        margin: 0 0.5rem;
    }

    .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
    .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
        font-size: 2rem;
    }

    .p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon {
        width: 2rem;
        height: 2rem;
    }

    .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
        background: rgba(255, 255, 255, 0.1);
        color: #f1f5f9;
    }

    .p-galleria .p-galleria-caption {
        background: rgba(0, 0, 0, 0.5);
        color: #f1f5f9;
        padding: 1rem;
    }

    .p-galleria .p-galleria-indicators {
        padding: 1rem;
    }

    .p-galleria .p-galleria-indicators .p-galleria-indicator button {
        background-color: #e2e8f0;
        width: 1rem;
        height: 1rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 50%;
    }

    .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
        background: #cbd5e1;
    }

    .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
    .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
        margin-right: 0.5rem;
    }

    .p-galleria.p-galleria-indicators-left .p-galleria-indicator,
    .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
        margin-bottom: 0.5rem;
    }

    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
        background: rgba(0, 0, 0, 0.5);
    }

    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
        background: rgba(255, 255, 255, 0.4);
    }

    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
        background: rgba(255, 255, 255, 0.6);
    }

    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        background: #EFF6FF;
        color: var(--primary-color);
    }

    .p-galleria .p-galleria-thumbnail-container {
        background: rgba(0, 0, 0, 0.9);
        padding: 1rem 0.25rem;
    }

    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
        margin: 0.5rem;
        background-color: transparent;
        color: #f1f5f9;
        width: 2rem;
        height: 2rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border-radius: 50%;
    }

    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #f1f5f9;
    }

    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-galleria-mask {
        --maskbg: rgba(0, 0, 0, 0.9);
    }

    .p-image-mask {
        --maskbg: rgba(0, 0, 0, 0.9);
    }

    .p-image-preview-indicator {
        background-color: transparent;
        color: #f8fafc;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-image-preview-container:hover>.p-image-preview-indicator {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .p-image-toolbar {
        padding: 1rem;
    }

    .p-image-action.p-link {
        color: #f8fafc;
        background-color: transparent;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        margin-right: 0.5rem;
    }

    .p-image-action.p-link:last-child {
        margin-right: 0;
    }

    .p-image-action.p-link:hover {
        color: #ffffff;
        background-color: rgba(255, 255, 255, 0.1);
    }

    .p-image-action.p-link i {
        font-size: 1.5rem;
    }

    .p-image-action.p-link .p-icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .p-avatar {
        background-color: #e2e8f0;
        border-radius: 6px;
    }

    .p-avatar.p-avatar-lg {
        width: 3rem;
        height: 3rem;
        font-size: 1.5rem;
    }

    .p-avatar.p-avatar-lg .p-avatar-icon {
        font-size: 1.5rem;
    }

    .p-avatar.p-avatar-xl {
        width: 4rem;
        height: 4rem;
        font-size: 2rem;
    }

    .p-avatar.p-avatar-xl .p-avatar-icon {
        font-size: 2rem;
    }

    .p-avatar-group .p-avatar {
        border: 2px solid #ffffff;
    }

    .p-badge {
        @apply bg-arena-orange-500;
        color: #ffffff;
        font-size: 0.75rem;
        font-weight: 700;
        min-width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
    }

    .p-badge.p-badge-secondary {
        background-color: #f1f5f9;
        color: #475569;
    }

    .p-badge.p-badge-contrast {
        background-color: #020617;
        color: #ffffff;
    }

    .p-badge.p-badge-success {
        background-color: #22c55e;
        color: #ffffff;
    }

    .p-badge.p-badge-info {
        background-color: #0ea5e9;
        color: #ffffff;
    }

    .p-badge.p-badge-warning {
        background-color: #f97316;
        color: #ffffff;
    }

    .p-badge.p-badge-danger {
        background-color: #ef4444;
        color: #ffffff;
    }

    .p-badge.p-badge-lg {
        font-size: 1.125rem;
        min-width: 2.25rem;
        height: 2.25rem;
        line-height: 2.25rem;
    }

    .p-badge.p-badge-xl {
        font-size: 1.5rem;
        min-width: 3rem;
        height: 3rem;
        line-height: 3rem;
    }

    .p-chip {
        background-color: #f1f5f9;
        color: #1e293b;
        border-radius: 16px;
        padding: 0 0.75rem;
    }

    .p-chip .p-chip-text {
        line-height: 1.5;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }

    .p-chip .p-chip-icon {
        margin-right: 0.5rem;
    }

    .p-chip .pi-chip-remove-icon {
        margin-left: 0.5rem;
    }

    .p-chip img {
        width: 2rem;
        height: 2rem;
        margin-left: -0.75rem;
        margin-right: 0.5rem;
    }

    .p-chip .pi-chip-remove-icon {
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-chip .pi-chip-remove-icon:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-chip .pi-chip-remove-icon:focus {
        outline: 0 none;
    }

    .p-inplace .p-inplace-display {
        padding: 0.5rem 0.75rem;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-inplace .p-inplace-display:not(.p-disabled):hover {
        background: #f1f5f9;
        color: #1e293b;
    }

    .p-inplace .p-inplace-display:focus {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-metergroup {
        display: flex;
    }

    .p-metergroup-meters {
        display: flex;
    }

    .p-metergroup-vertical .p-metergroup-meters {
        flex-direction: column;
    }

    .p-metergroup-labels {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .p-metergroup-vertical .p-metergroup-labels {
        align-items: start;
    }

    .p-metergroup-labels-vertical {
        flex-direction: column;
    }

    .p-metergroup-label {
        display: inline-flex;
        align-items: center;
    }

    .p-metergroup-label-marker {
        display: inline-flex;
    }

    .p-metergroup {
        gap: 1rem;
    }

    .p-metergroup .p-metergroup-meters {
        background: #e2e8f0;
        border-radius: 6px;
    }

    .p-metergroup .p-metergroup-meter {
        border: 0 none;
        background: #3B82F6;
    }

    .p-metergroup .p-metergroup-labels .p-metergroup-label {
        gap: 0.5rem;
    }

    .p-metergroup .p-metergroup-labels .p-metergroup-label-marker {
        background: #3B82F6;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 100%;
    }

    .p-metergroup .p-metergroup-labels .p-metergroup-label-icon {
        width: 1rem;
        height: 1rem;
    }

    .p-metergroup .p-metergroup-labels.p-metergroup-labels-vertical {
        gap: 0.5rem;
    }

    .p-metergroup .p-metergroup-labels.p-metergroup-labels-horizontal {
        gap: 1rem;
    }

    .p-metergroup.p-metergroup-horizontal {
        flex-direction: column;
    }

    .p-metergroup.p-metergroup-horizontal .p-metergroup-meters {
        height: 0.5rem;
    }

    .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:first-of-type {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:last-of-type {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-metergroup.p-metergroup-vertical {
        flex-direction: row;
    }

    .p-metergroup.p-metergroup-vertical .p-metergroup-meters {
        width: 0.5rem;
        height: 100%;
    }

    .p-metergroup.p-metergroup-vertical .p-metergroup-meter:first-of-type {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .p-metergroup.p-metergroup-vertical .p-metergroup-meter:last-of-type {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-progressbar {
        border: 0 none;
        height: 1.25rem;
        background: #e2e8f0;
        border-radius: 6px;
    }

    .p-progressbar .p-progressbar-value {
        border: 0 none;
        margin: 0;
        background: #3B82F6;
    }

    .p-progressbar .p-progressbar-label {
        color: #ffffff;
        line-height: 1.25rem;
    }

    .p-scrolltop {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-scrolltop.p-link {
        background: rgba(0, 0, 0, 0.7);
    }

    .p-scrolltop.p-link:hover {
        background: rgba(0, 0, 0, 0.8);
    }

    .p-scrolltop .p-scrolltop-icon {
        font-size: 1.5rem;
        color: #f8fafc;
    }

    .p-scrolltop .p-icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .p-skeleton {
        background-color: #e2e8f0;
        border-radius: 6px;
    }

    .p-skeleton:after {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    }

    .p-tag {
        background: #3B82F6;
        color: #ffffff;
        font-size: 0.75rem;
        font-weight: 700;
        padding: 0.25rem 0.4rem;
        border-radius: 6px;
    }

    .p-tag.p-tag-success {
        background-color: #22c55e;
        color: #ffffff;
    }

    .p-tag.p-tag-info {
        background-color: #0ea5e9;
        color: #ffffff;
    }

    .p-tag.p-tag-warning {
        background-color: #f97316;
        color: #ffffff;
    }

    .p-tag.p-tag-danger {
        background-color: #ef4444;
        color: #ffffff;
    }

    .p-tag .p-tag-icon {
        margin-right: 0.25rem;
        font-size: 0.75rem;
    }

    .p-tag .p-icon {
        width: 0.75rem;
        height: 0.75rem;
    }

    .p-tag.p-tag-secondary {
        background-color: #f1f5f9;
        color: #475569;
    }

    .p-tag.p-tag-contrast {
        background-color: #020617;
        color: #ffffff;
    }

    .p-terminal {
        background: #ffffff;
        color: #334155;
        border: 1px solid #e2e8f0;
        padding: 0 1.125rem 1.125rem 1.125rem;
    }

    .p-terminal .p-terminal-input {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
    }
}

@layer primeng {
    .p-accordion .p-accordion-header .p-accordion-header-link {
        border-radius: 6px !important;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link:hover {
        border-bottom-color: #e2e8f0;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
        transform: rotate(90deg);
    }

    .p-accordion .p-accordion-header.p-highlight .p-accordion-toggle-icon {
        transform: rotate(-180deg);
    }

    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible {
        outline-offset: -2px;
    }

    .p-accordion p-accordiontab .p-accordion-tab {
        border-bottom: 1px solid #e2e8f0;
    }

    .p-accordion p-accordiontab:last-of-type .p-accordion-tab {
        border-bottom: 0 none;
    }

    .p-autocomplete .p-autocomplete-multiple-container {
        padding: 0.25rem 0.25rem;
        gap: 0.25rem;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
        border-radius: 4px;
        margin: 0;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
        margin-left: 0.375rem;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
        margin-left: 0.5rem;
    }

    .p-autocomplete .p-autocomplete-multiple-container:has(.p-autocomplete-token) .p-autocomplete-input-token {
        margin-left: 0.5rem;
    }

    .p-autocomplete.p-disabled {
        opacity: 1;
    }

    .p-button-label {
        font-weight: 500;
    }

    .p-button.p-button-success:enabled:focus-visible {
        outline-color: #22c55e;
    }

    .p-button.p-button-info:enabled:focus-visible {
        outline-color: #0ea5e9;
    }

    .p-button.p-button-warning:enabled:focus-visible {
        outline-color: #f97316;
    }

    .p-button.p-button-help:enabled:focus-visible {
        outline-color: #a855f7;
    }

    .p-button.p-button-danger:enabled:focus-visible {
        outline-color: #ef4444;
    }

    .p-button.p-button-contrast:enabled:focus-visible {
        outline-color: #020617;
    }

    .p-button.p-button-outlined {
        border-color: var(--primary-200);
    }

    .p-button.p-button-outlined:not(:disabled):hover,
    .p-button.p-button-outlined:not(:disabled):active {
        border-color: var(--primary-200);
    }

    .p-button.p-button-outlined.p-button-secondary {
        border-color: var(--surface-200);
        color: #64748b;
    }

    .p-button.p-button-outlined.p-button-secondary:not(:disabled):hover {
        color: #64748b;
        background-color: #f1f5f9;
        border-color: var(--surface-200);
    }

    .p-button.p-button-outlined.p-button-secondary:not(:disabled):active {
        color: #64748b;
        background-color: #e2e8f0;
        border-color: var(--surface-200);
    }

    .p-button.p-button-outlined.p-button-success {
        border-color: #bbf7d0;
    }

    .p-button.p-button-outlined.p-button-success:not(:disabled):hover,
    .p-button.p-button-outlined.p-button-success:not(:disabled):active {
        border-color: #bbf7d0;
    }

    .p-button.p-button-outlined.p-button-info {
        border-color: #bae6fd;
    }

    .p-button.p-button-outlined.p-button-info:not(:disabled):hover,
    .p-button.p-button-outlined.p-button-info:not(:disabled):active {
        border-color: #bae6fd;
    }

    .p-button.p-button-outlined.p-button-warning {
        border-color: #fed7aa;
    }

    .p-button.p-button-outlined.p-button-warning:not(:disabled):hover,
    .p-button.p-button-outlined.p-button-warning:not(:disabled):active {
        border-color: #fed7aa;
    }

    .p-button.p-button-outlined.p-button-help {
        border-color: #e9d5ff;
    }

    .p-button.p-button-outlined.p-button-help:not(:disabled):hover,
    .p-button.p-button-outlined.p-button-help:not(:disabled):active {
        border-color: #e9d5ff;
    }

    .p-button.p-button-outlined.p-button-danger {
        border-color: #fecaca;
    }

    .p-button.p-button-outlined.p-button-danger:not(:disabled):hover,
    .p-button.p-button-outlined.p-button-danger:not(:disabled):active {
        border-color: #fecaca;
    }

    .p-button.p-button-outlined.p-button-contrast {
        border-color: #334155;
    }

    .p-button.p-button-outlined.p-button-contrast:not(:disabled):hover,
    .p-button.p-button-outlined.p-button-contrast:not(:disabled):active {
        border-color: #334155;
    }

    .p-button.p-button-secondary.p-button-text {
        color: #64748b;
    }

    .p-button.p-button-secondary.p-button-text:not(:disabled):hover {
        background: #f1f5f9;
        color: #64748b;
    }

    .p-button.p-button-secondary.p-button-text:not(:disabled):active {
        background: #e2e8f0;
        color: #64748b;
    }

    .p-datepicker-buttonbar .p-button {
        padding: 0.25rem 0.75rem;
        font-size: 0.875rem;
    }

    .p-datepicker .p-datepicker-group-container+.p-timepicker {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
    }

    .p-datepicker table th {
        font-weight: 500;
    }

    .p-card {
        border-radius: 12px;
        display: flex;
        flex-direction: column;
    }

    .p-card .p-card-caption {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .p-card .p-card-caption .p-card-title,
    .p-card .p-card-caption .p-card-subtitle {
        margin-bottom: 0;
    }

    .p-card .p-card-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background-color: #3B82F6;
    }

    .p-cascadeselect {
        box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
    }

    .p-cascadeselect .p-cascadeselect-label {
        box-shadow: none;
    }

    .p-cascadeselect.p-disabled {
        opacity: 1;
        background-color: #e2e8f0;
    }

    .p-cascadeselect.p-disabled .p-cascadeselect-label {
        color: #64748b;
    }

    div.p-cascadeselect-panel {
        border: 0 none;
        box-shadow: none;
    }

    .p-checkbox .p-checkbox-box {
        border-radius: 4px;
        box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
    }

    .p-checkbox .p-checkbox-box.p-focus {
        outline-offset: 2px;
    }

    .p-checkbox .p-checkbox-box.p-focus.p-highlight {
        border-color: #3B82F6;
    }

    .p-checkbox.p-checkbox-disabled {
        opacity: 1;
    }

    .p-checkbox.p-checkbox-disabled .p-checkbox-box {
        background-color: #e2e8f0;
        border: 1px solid #cbd5e1;
    }

    .p-checkbox.p-checkbox-disabled .p-checkbox-box .p-checkbox-icon {
        color: #64748b;
    }

    .p-chips .p-chips-multiple-container {
        padding: 0.25rem 0.25rem;
        gap: 0.25rem;
    }

    .p-chips .p-chips-multiple-container .p-chips-token {
        border-radius: 4px;
        margin: 0;
    }

    .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
        margin-left: 0.375rem;
    }

    .p-chips .p-chips-multiple-container .p-chips-input-token {
        margin-left: 0.5rem;
    }

    .p-chips .p-chips-multiple-container:has(.p-chips-token) .p-chips-input-token {
        margin-left: 0.5rem;
    }

    .p-chips.p-disabled .p-chips-multiple-container {
        opacity: 1;
        background-color: #e2e8f0;
    }

    .p-chip {
        border-radius: 16px;
        padding: 0.25rem 0.75rem;
    }

    .p-chip .p-chip-text {
        margin-top: 0;
        margin-bottom: 0;
    }

    .p-chip .p-chip-remove-icon {
        margin-left: 0.375rem;
    }

    .p-chip:has(.p-chip-remove-icon) {
        padding-right: 0.5rem;
    }

    .p-chip img {
        margin-left: -0.5rem;
    }

    .p-colorpicker-preview {
        padding: 0;
    }

    .p-colorpicker-preview:enabled:focus {
        outline-offset: 2px;
    }

    .p-colorpicker-preview.p-inputtext.p-disabled {
        opacity: 0.6;
    }

    .p-confirm-popup {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
        box-shadow: inset 0 2px 0 0 #3B82F6;
    }

    .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
        box-shadow: inset 0 -2px 0 0 #3B82F6;
    }

    .p-datatable .p-datatable-tbody>tr:has(+ .p-highlight)>td {
        border-bottom-color: #bedaff;
    }

    .p-datatable .p-datatable-tbody>tr.p-highlight>td {
        border-bottom-color: #bedaff;
    }

    .p-dataview-layout-options.p-selectbutton .p-button svg {
        position: relative;
    }

    .p-dialog {
        border-radius: 12px;
        background-color: #ffffff;
    }

    .p-dialog.p-dialog-maximized {
        border-radius: 0;
    }

    .p-dialog .p-dialog-header {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }

    .p-dialog .p-dialog-content:last-of-type {
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    .p-dialog .p-dialog-footer {
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    .p-dropdown {
        box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
    }

    .p-dropdown .p-dropdown-label {
        box-shadow: none;
    }

    .p-dropdown.p-disabled {
        opacity: 1;
        background-color: #e2e8f0;
    }

    .p-dropdown.p-disabled .p-dropdown-label {
        color: #64748b;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item .p-dropdown-check-icon {
        margin-left: -0.375rem;
        margin-right: 0.375rem;
    }

    .p-treetable .p-treetable-tbody>tr:has(+ .p-highlight)>td {
        border-bottom-color: #bedaff;
    }

    .p-treetable .p-treetable-tbody>tr.p-highlight>td {
        border-bottom-color: #bedaff;
    }

    .p-treetable .p-treetable-tbody>tr.p-highlight>td .p-treetable-toggler:hover {
        background-color: #EFF6FF;
        color: var(--primary-color);
    }

    .p-fieldset {
        padding: 0 1.125rem 1.125rem 1.125rem;
        margin: 0;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
        padding: 0.5rem 0.75rem;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-legend-text {
        padding: 0;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
        color: #64748b;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
        background-color: #f1f5f9;
    }

    .p-fieldset .p-fieldset-legend {
        border: 0 none;
        padding: 0;
        margin-bottom: 0.375rem;
    }

    .p-fieldset .p-fieldset-legend span {
        padding: 0.5rem 0.75rem;
    }

    .p-fieldset .p-fieldset-content {
        padding: 0;
    }

    .p-column-filter-overlay-menu {
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .p-column-filter-overlay-menu .p-column-filter-operator {
        padding: 0;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraints {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint {
        padding: 0 0 0 0;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
        margin-bottom: 0.5rem;
    }

    .p-column-filter-overlay-menu .p-column-filter-constraint:last-child .p-column-filter-remove-button {
        margin-bottom: 0;
    }

    .p-column-filter-overlay-menu .p-column-filter-add-rule {
        padding: 0;
    }

    .p-column-filter-overlay-menu .p-column-filter-buttonbar {
        padding: 0;
    }

    .p-fileupload .p-fileupload-content {
        border-top: 0 none;
        padding-top: 1.125rem;
    }

    .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        background-color: #3B82F6;
    }

    .p-inline-message-text {
        font-weight: 500;
    }

    .p-inline-message {
        backdrop-filter: blur(1.5px);
    }

    .p-inline-message.p-inline-message-info {
        box-shadow: 0px 4px 8px 0px rgba(59, 130, 246, 0.04);
    }

    .p-inline-message.p-inline-message-success {
        box-shadow: 0px 4px 8px 0px rgba(34, 197, 94, 0.04);
    }

    .p-inline-message.p-inline-message-warn {
        box-shadow: 0px 4px 8px 0px rgba(234, 179, 8, 0.04);
    }

    .p-inline-message.p-inline-message-error {
        box-shadow: 0px 4px 8px 0px rgba(239, 68, 68, 0.04);
    }

    .p-inline-message.p-inline-message-secondary {
        box-shadow: 0px 4px 8px 0px rgba(74, 85, 103, 0.04);
    }

    .p-inline-message.p-inline-message-contrast {
        box-shadow: 0px 4px 8px 0px rgba(2, 6, 23, 0.04);
    }

    .p-inputgroup-addon {
        padding: 0.5rem;
    }

    .p-inputnumber.p-inputnumber-buttons-stacked {
        position: relative;
    }

    .p-inputnumber.p-inputnumber-buttons-stacked .p-inputnumber-input {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .p-inputnumber.p-inputnumber-buttons-stacked .p-inputnumber-button-group {
        position: absolute;
        top: 1px;
        right: 1px;
        height: calc(100% - 2px);
    }

    .p-inputnumber.p-inputnumber-buttons-stacked .p-inputnumber-button {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: transparent;
        border: 0 none;
        color: #64748b;
    }

    .p-inputnumber.p-inputnumber-buttons-stacked .p-inputnumber-button:hover {
        background-color: #f1f5f9;
        color: #475569;
    }

    .p-inputnumber.p-inputnumber-buttons-stacked .p-inputnumber-button:active {
        background-color: #e2e8f0;
        color: #334155;
    }

    .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button {
        background-color: transparent;
        border: 1px solid #cbd5e1;
        color: #64748b;
    }

    .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button:hover {
        background-color: #f1f5f9;
        color: #475569;
    }

    .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button:active {
        background-color: #e2e8f0;
        color: #334155;
    }

    .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button.p-inputnumber-button-up {
        border-left: 0 none;
    }

    .p-inputnumber.p-inputnumber-buttons-horizontal .p-inputnumber-button.p-inputnumber-button-down {
        border-right: 0 none;
    }

    .p-inputnumber.p-inputnumber-buttons-vertical .p-inputnumber-button {
        background-color: transparent;
        border: 1px solid #cbd5e1;
        color: #64748b;
    }

    .p-inputnumber.p-inputnumber-buttons-vertical .p-inputnumber-button:hover {
        background-color: #f1f5f9;
        color: #475569;
    }

    .p-inputnumber.p-inputnumber-buttons-vertical .p-inputnumber-button:active {
        background-color: #e2e8f0;
        color: #334155;
    }

    .p-inputnumber.p-inputnumber-buttons-vertical .p-inputnumber-button.p-inputnumber-button-up {
        border-bottom: 0 none;
    }

    .p-inputnumber.p-inputnumber-buttons-vertical .p-inputnumber-button.p-inputnumber-button-down {
        border-top: 0 none;
    }

    .p-inputswitch .p-inputswitch-slider {
        border: 0 none;
    }

    .p-inputswitch.p-highlight p-inputswitch-slider:before {
        left: 1.25rem;
        transform: none;
    }

    .p-inputswitch.p-invalid>.p-inputswitch-slider {
        background: #f87171;
    }

    .p-inputswitch.p-invalid>.p-inputswitch-slider:before {
        background: #ffffff;
    }

    .p-inputtext {
        box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
    }

    .p-inputtext:disabled {
        opacity: 1;
        background-color: #e2e8f0;
        color: #64748b;
    }

    .p-knob svg {
        border-radius: 6px;
        outline-color: transparent;
        transition: outline-color 0.2s;
    }

    .p-knob svg:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-listbox {
        box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
    }

    .p-listbox.p-disabled {
        opacity: 1;
        background-color: #e2e8f0;
    }

    .p-listbox.p-disabled .p-listbox-list .p-listbox-item {
        color: #64748b;
    }

    .p-listbox .p-listbox-header:has(.p-checkbox) {
        padding: 0.5rem 1rem 0 1rem;
    }

    .p-message {
        backdrop-filter: blur(1.5px);
    }

    .p-message.p-message-info {
        box-shadow: 0px 4px 8px 0px rgba(59, 130, 246, 0.04);
    }

    .p-message.p-message-info .p-message-close:focus-visible {
        outline-color: #2563eb;
    }

    .p-message.p-message-info .p-message-close:hover {
        background: #dbeafe;
    }

    .p-message.p-message-success {
        box-shadow: 0px 4px 8px 0px rgba(34, 197, 94, 0.04);
    }

    .p-message.p-message-success .p-message-close:focus-visible {
        outline-color: #16a34a;
    }

    .p-message.p-message-success .p-message-close:hover {
        background: #dcfce7;
    }

    .p-message.p-message-warn {
        box-shadow: 0px 4px 8px 0px rgba(234, 179, 8, 0.04);
    }

    .p-message.p-message-warn .p-message-close:focus-visible {
        outline-color: #ca8a04;
    }

    .p-message.p-message-warn .p-message-close:hover {
        background: #fef9c3;
    }

    .p-message.p-message-error {
        box-shadow: 0px 4px 8px 0px rgba(239, 68, 68, 0.04);
    }

    .p-message.p-message-error .p-message-close:focus-visible {
        outline-color: #dc2626;
    }

    .p-message.p-message-error .p-message-close:hover {
        background: #fee2e2;
    }

    .p-message.p-message-secondary {
        box-shadow: 0px 4px 8px 0px rgba(74, 85, 103, 0.04);
    }

    .p-message.p-message-secondary .p-message-close:focus-visible {
        outline-color: #475569;
    }

    .p-message.p-message-secondary .p-message-close:hover {
        background: #e2e8f0;
    }

    .p-message.p-message-contrast {
        box-shadow: 0px 4px 8px 0px rgba(2, 6, 23, 0.04);
    }

    .p-message.p-message-contrast .p-message-close:focus-visible {
        outline-color: #f8fafc;
    }

    .p-message.p-message-contrast .p-message-close:hover {
        background: #1e293b;
    }

    .p-multiselect-panel .p-multiselect-header {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .p-multiselect {
        box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
    }

    .p-multiselect.p-disabled {
        opacity: 1;
        background-color: #e2e8f0;
    }

    .p-multiselect.p-disabled .p-multiselect-label {
        color: #64748b;
    }

    .p-multiselect.p-multiselect-chip .p-multiselect-token {
        border-radius: 4px;
        margin-right: 0.25rem;
    }

    .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
        margin-left: 0.375rem;
    }

    .p-inputwrapper-filled .p-multiselect-chip .p-multiselect-label {
        padding: 0.25rem 0.25rem;
    }

    .p-panelmenu .p-panelmenu-header {
        border-radius: 4px;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
        outline: 0 none;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content .p-panelmenu-header-action {
        background: #e2e8f0;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-text {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
        border-radius: 4px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
        color: #334155;
        padding: 0.5rem 0.75rem;
        font-weight: 600;
        border-radius: 4px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-text {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
        color: #94a3b8;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
        color: #94a3b8;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action:hover {
        background: #f1f5f9;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action:hover .p-menuitem-text {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action:hover .p-submenu-icon {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action:hover .p-menuitem-icon {
        color: #334155;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
        margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
        margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-content {
        border-radius: 6px;
        padding: 0 0 0 1rem;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:first-child {
        margin-top: 2px;
    }

    .p-panelmenu .p-panelmenu-content .p-menuitem:last-child {
        margin-top: 2px;
    }

    .p-panelmenu .p-panelmenu-panel {
        padding: 0.25rem 0.25rem;
        overflow: hidden;
        margin-bottom: 0.75rem;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
    }

    .p-password-panel {
        background-color: #ffffff;
        border: 1px solid #e2e8f0;
        padding: 0.75rem;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }

    .p-password-panel .p-password-meter {
        margin-bottom: 0.75rem;
        background: #e2e8f0;
        border-radius: 6px;
    }

    .p-password-panel .p-password-meter .p-password-strength {
        border-radius: 6px;
    }

    .p-password-panel .p-password-meter .p-password-strength.weak {
        background: #ef4444;
    }

    .p-password-panel .p-password-meter .p-password-strength.medium {
        background: #f59e0b;
    }

    .p-password-panel .p-password-meter .p-password-strength.strong {
        background: #22c55e;
    }

    .p-orderlist-controls .p-button {
        transition: opacity 0.2s, outline-color 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        color: #475569;
        background: #f1f5f9;
        border: 1px solid #f1f5f9;
    }

    .p-orderlist-controls .p-button:not(:disabled):hover {
        background: #e2e8f0;
        color: #334155;
        border-color: #e2e8f0;
    }

    .p-orderlist-controls .p-button:not(:disabled):focus {
        box-shadow: none;
    }

    .p-orderlist-controls .p-button:not(:disabled):active {
        background: #cbd5e1;
        color: #1e293b;
        border-color: #cbd5e1;
    }

    .p-orderlist .p-orderlist-header {
        background: #ffffff;
        color: #334155;
        border: 0 none;
        border-bottom: 0 none;
        padding: 0.75rem 1rem 0.5rem 1rem;
        font-weight: 600;
        color: #64748b;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-orderlist .p-orderlist-list {
        border: 1px solid #cbd5e1;
        border: 0 none;
        background: #ffffff;
        color: #334155;
        padding: 0.25rem 0.25rem;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-orderlist .p-orderlist-list .p-orderlist-item {
        border-radius: 6px;
    }

    .p-orderlist .p-orderlist-list:not(:first-child) {
        border: 0 none;
    }

    .p-orderlist .p-orderlist-filter-container {
        border: 0 none;
    }

    .p-organizationchart .p-organizationchart-node-content {
        padding: 0.75rem 1rem;
        border-radius: 6px;
    }

    .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        border: 1px solid #e2e8f0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .p-organizationchart .p-organizationchart-node-content .p-node-toggler .p-node-toggler-icon {
        position: static;
    }

    .p-organizationchart .p-organizationchart-node-content:has(.p-node-toggler) {
        padding: 0.75rem 1rem 1.25rem 1rem;
    }

    .p-organizationchart .p-organizationchart-lines :nth-child(1 of .p-organizationchart-line-left) {
        border-right: 0 none;
    }

    .p-organizationchart .p-organizationchart-lines :nth-last-child(1 of .p-organizationchart-line-left) {
        border-top-right-radius: 6px;
    }

    .p-organizationchart .p-organizationchart-lines :nth-child(1 of .p-organizationchart-line-right) {
        border-left: 1px solid #e2e8f0;
        border-top-left-radius: 6px;
    }

    .p-overlaypanel {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    .p-overlaypanel .p-overlaypanel-content {
        padding: 0.75rem;
    }

    .p-overlaypanel .p-overlaypanel-close {
        width: 1.75rem;
        height: 1.75rem;
        color: #64748b;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
    }

    .p-overlaypanel .p-overlaypanel-close:enabled:hover {
        color: #475569;
        border-color: transparent;
        background: #f1f5f9;
    }

    .p-overlaypanel .p-overlaypanel-close:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: 2px;
        box-shadow: none;
    }

    .p-panel {
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        background-color: #ffffff;
    }

    .p-panel .p-panel-header,
    .p-panel .p-panel-content,
    .p-panel .p-panel-footer {
        background: transparent;
        border: 0 none;
    }

    .p-panel:has(.p-panel-footer) .p-panel-content {
        padding-bottom: 0.875rem;
    }

    .p-picklist-buttons .p-button {
        transition: opacity 0.2s, outline-color 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        color: #475569;
        background: #f1f5f9;
        border: 1px solid #f1f5f9;
    }

    .p-picklist-buttons .p-button:not(:disabled):hover {
        background: #e2e8f0;
        color: #334155;
        border-color: #e2e8f0;
    }

    .p-picklist-buttons .p-button:not(:disabled):focus {
        box-shadow: none;
    }

    .p-picklist-buttons .p-button:not(:disabled):active {
        background: #cbd5e1;
        color: #1e293b;
        border-color: #cbd5e1;
    }

    .p-picklist .p-picklist-header {
        background: #ffffff;
        color: #334155;
        border: 1px solid #cbd5e1;
        border: 0 none;
        padding: 0.75rem 1rem 0.5rem 1rem;
        font-weight: 600;
        color: #64748b;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-picklist .p-picklist-filter-container {
        border: 0;
    }

    .p-picklist .p-picklist-list {
        border: 1px solid #cbd5e1;
        border: 0 none;
        background: #ffffff;
        color: #334155;
        padding: 0.25rem 0.25rem;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-picklist .p-picklist-list .p-picklist-item {
        border-radius: 6px;
    }

    .p-picklist .p-picklist-list:not(:first-child) {
        border: 0 none;
    }

    .p-progressbar-label {
        font-size: 0.75rem;
        font-weight: 600;
    }

    .p-radiobutton .p-radiobutton-box {
        box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
    }

    .p-radiobutton .p-radiobutton-box.p-focus {
        outline-offset: 2px;
    }

    .p-radiobutton .p-radiobutton-box.p-focus.p-highlight {
        border-color: #3B82F6;
    }

    .p-radiobutton.p-radiobutton-disabled {
        opacity: 1;
    }

    .p-radiobutton.p-radiobutton-disabled .p-radiobutton-box {
        border: 1px solid #cbd5e1;
        background-color: #e2e8f0;
    }

    .p-radiobutton.p-radiobutton-disabled .p-radiobutton-box .p-radiobutton-icon {
        background-color: #64748b;
    }

    .p-rating {
        gap: 0.25rem;
    }

    .p-selectbutton .p-button .pi,
    .p-selectbutton .p-button .p-button-label {
        position: relative;
        transition: none;
    }

    .p-selectbutton .p-button::before {
        content: "";
        background-color: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        position: absolute;
        left: 0.25rem;
        top: 0.25rem;
        width: calc(100% - 0.5rem);
        height: calc(100% - 0.5rem);
        border-radius: 4px;
    }

    .p-selectbutton .p-button.p-highlight::before {
        background: #ffffff;
        border-color: #ffffff;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
    }

    .p-selectbutton .p-button:focus-visible {
        outline: 1px solid var(--p-focus-ring-color);
        outline-offset: -1px;
        box-shadow: none;
        border-color: #94a3b8;
    }

    .p-selectbutton .p-button.p-disabled {
        opacity: 1;
        color: #94a3b8;
    }

    .p-selectbutton.p-invalid {
        box-shadow: 0 0 0 1px #f87171;
        border-radius: 6px;
    }

    .p-selectbutton.p-invalid>.p-button {
        border: 1px solid #f1f5f9;
    }

    .p-selectbutton.p-disabled {
        opacity: 1;
    }

    .p-selectbutton.p-disabled .p-button {
        color: #94a3b8;
    }

    .p-slider .p-slider-handle {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .p-slider .p-slider-handle::before {
        content: "";
        width: 16px;
        height: 16px;
        display: block;
        background-color: #ffffff;
        border-radius: 50%;
        box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
    }

    .p-slider .p-slider-handle:focus-visible {
        outline: 0 none;
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--p-focus-ring-color), 0 1px 2px 0 black;
    }

    .p-speeddial-item.p-focus>.p-speeddial-action {
        outline-color: #9dc1fb;
    }

    .p-speeddial-button.p-speeddial-rotate {
        transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-splitter-gutter-handle {
        border-radius: 6px;
    }

    .p-stepper .p-stepper-header .p-stepper-number::after {
        content: " ";
        position: absolute;
        height: 100%;
        box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    }

    .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
        background: #ffffff;
        color: #3B82F6;
    }

    .p-stepper .p-stepper-header.p-highlight .p-stepper-title {
        color: #3B82F6;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        border-width: 2px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
        font-weight: 500;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-steps .p-steps-item .p-steps-number {
        position: relative;
        font-weight: 500;
    }

    .p-steps .p-steps-item .p-steps-number::after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    }

    .p-steps .p-steps-item.p-highlight .p-steps-number {
        background: #ffffff;
        color: #3B82F6;
    }

    .p-steps .p-steps-item.p-highlight .p-steps-title {
        color: #3B82F6;
    }

    .p-steps .p-steps-item.p-disabled {
        opacity: 1;
    }

    .p-steps .p-steps-item:before {
        border-top-width: 2px;
        margin-top: calc(-1rem + 1px);
    }

    .p-steps .p-steps-item:first-child::before {
        width: calc(50% + 1rem);
        transform: translateX(100%);
    }

    .p-steps .p-steps-item:last-child::before {
        width: 50%;
    }

    .p-tabmenu .p-tabmenu-ink-bar {
        z-index: 1;
        display: block;
        position: absolute;
        bottom: -1px;
        height: 1px;
        background-color: #3B82F6;
        transition: 250ms cubic-bezier(0.35, 0, 0.25, 1);
    }

    .p-tabmenu .p-tabmenu-nav {
        position: relative;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-tabmenu .p-tabmenu-nav-container .p-tabmenu-nav-btn {
        outline: 0 none;
    }

    .p-tabview .p-tabview-ink-bar {
        z-index: 1;
        display: block;
        position: absolute;
        bottom: 0;
        height: 1px;
        background-color: #3B82F6;
        transition: 250ms cubic-bezier(0.35, 0, 0.25, 1);
    }

    .p-tabview .p-tabview-nav {
        position: relative;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    }

    .p-tabview .p-tabview-nav-btn.p-link {
        color: #64748b;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0px 0px 10px 50px rgba(255, 255, 255, 0.6);
    }

    .p-tabview .p-tabview-nav-btn.p-link:hover {
        color: #475569;
    }

    .p-tag {
        background: var(--primary-100);
        color: var(--primary-700);
    }

    .p-tag.p-tag-success {
        background: var(--green-100);
        color: var(--green-700);
    }

    .p-tag.p-tag-info {
        background: var(--blue-100);
        color: var(--blue-700);
    }

    .p-tag.p-tag-warning {
        background: var(--orange-100);
        color: var(--orange-700);
    }

    .p-tag.p-tag-danger {
        background: var(--red-100);
        color: var(--red-700);
    }

    .p-terminal {
        background: #ffffff;
        color: #334155;
        border: 1px solid #cbd5e1;
        padding: 0.5rem 0.75rem;
        border-radius: 6px;
    }

    .p-terminal .p-terminal-prompt {
        margin-right: 0.25rem;
    }

    .p-terminal .p-terminal-response {
        margin: 2px 0;
    }

    .p-timeline .p-timeline-event-marker {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .p-timeline .p-timeline-event-marker::before {
        content: " ";
        border-radius: 50%;
        width: 0.375rem;
        height: 0.375rem;
        background-color: #3B82F6;
    }

    .p-timeline .p-timeline-event-marker::after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    }

    .p-toast .p-toast-message {
        backdrop-filter: blur(1.5px);
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
        font-size: 0.875rem;
    }

    .p-toast .p-toast-message .p-toast-message-content .p-toast-icon-close {
        width: 1.75rem;
        height: 1.75rem;
        position: relative;
    }

    .p-toast .p-toast-message.p-toast-message-info {
        box-shadow: 0px 4px 8px 0px rgba(59, 130, 246, 0.04);
    }

    .p-toast .p-toast-message.p-toast-message-info .p-toast-detail {
        color: #334155;
    }

    .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
        outline-color: #2563eb;
    }

    .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close:hover {
        background: #dbeafe;
    }

    .p-toast .p-toast-message.p-toast-message-success {
        box-shadow: 0px 4px 8px 0px rgba(34, 197, 94, 0.04);
    }

    .p-toast .p-toast-message.p-toast-message-success .p-toast-detail {
        color: #334155;
    }

    .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
        outline-color: #16a34a;
    }

    .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close:hover {
        background: #dcfce7;
    }

    .p-toast .p-toast-message.p-toast-message-warn {
        box-shadow: 0px 4px 8px 0px rgba(234, 179, 8, 0.04);
    }

    .p-toast .p-toast-message.p-toast-message-warn .p-toast-detail {
        color: #334155;
    }

    .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
        outline-color: #ca8a04;
    }

    .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close:hover {
        background: #fef9c3;
    }

    .p-toast .p-toast-message.p-toast-message-error {
        box-shadow: 0px 4px 8px 0px rgba(239, 68, 68, 0.04);
    }

    .p-toast .p-toast-message.p-toast-message-error .p-toast-detail {
        color: #334155;
    }

    .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
        outline-color: #dc2626;
    }

    .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close:hover {
        background: #fee2e2;
    }

    .p-toast .p-toast-message.p-toast-message-secondary {
        box-shadow: 0px 4px 8px 0px rgba(74, 85, 103, 0.04);
    }

    .p-toast .p-toast-message.p-toast-message-secondary .p-toast-icon-close {
        outline-color: #dc2626;
    }

    .p-toast .p-toast-message.p-toast-message-secondary .p-toast-icon-close:hover {
        background: #e2e8f0;
    }

    .p-toast .p-toast-message.p-toast-message-contrast {
        box-shadow: 0px 4px 8px 0px rgba(2, 6, 23, 0.04);
    }

    .p-toast .p-toast-message.p-toast-message-contrast .p-toast-icon-close {
        outline-color: #dc2626;
    }

    .p-toast .p-toast-message.p-toast-message-contrast .p-toast-icon-close:hover {
        background: #1e293b;
    }

    .p-togglebutton.p-button {
        padding: 0.25rem;
    }

    .p-togglebutton.p-button .p-button-label {
        padding: 0.25rem 0.5rem;
    }

    .p-togglebutton.p-button .p-button-icon {
        padding: 0.35rem 0.25rem;
    }

    .p-togglebutton.p-button .p-button-label,
    .p-togglebutton.p-button .p-button-icon {
        position: relative;
        transition: none;
    }

    .p-togglebutton.p-button::before {
        content: "";
        background-color: transparent;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
        position: absolute;
        left: 0.25rem;
        top: 0.25rem;
        width: calc(100% - 0.5rem);
        height: calc(100% - 0.5rem);
        border-radius: 4px;
    }

    .p-togglebutton.p-highlight .p-button::before {
        background: #ffffff;
        border-color: #ffffff;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
    }

    .p-togglebutton.p-highlight .p-button-label {
        background: #ffffff;
        border-color: #ffffff;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
    }

    .p-togglebutton.p-highlight:has(.p-button-icon) .p-button-icon {
        margin-right: 0;
        background: #ffffff;
        box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.02), -2px 2px 2px 0px rgba(0, 0, 0, 0.04);
        border: none;
        border-radius: 4px 0px 0px 4px;
    }

    .p-togglebutton.p-highlight:has(.p-button-icon) .p-button-label {
        background: #ffffff;
        box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.02), 2px 2px 2px 0px rgba(0, 0, 0, 0.04);
        border-radius: 0px 4px 4px 0px;
    }

    .p-togglebutton:has(:not(.p-highlight)):has(.p-button-icon) .p-button-icon {
        margin-right: 0;
        border: none;
        border-radius: 4px 0px 0px 4px;
    }

    .p-togglebutton:has(:not(.p-highlight)):has(.p-button-icon) .p-button-label {
        border-radius: 0px 4px 4px 0px;
    }

    .p-togglebutton.p-disabled {
        opacity: 1;
        color: #94a3b8;
    }

    .p-togglebutton.p-disabled .p-button-icon {
        color: #94a3b8;
    }

    .p-toolbar {
        padding: 0.75rem;
    }

    .p-tree {
        border: 0 none;
        padding: 1rem;
    }

    .p-tree .p-tree-container .p-treenode {
        margin: 2px 0;
    }

    .p-tree .p-tree-container .p-treenode:focus>.p-treenode-content {
        outline-offset: -2px;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
        background: none;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover {
        background-color: #EFF6FF;
    }

    .p-tree .p-tree-container>.p-treenode:first-child {
        margin-top: 0;
    }

    .p-tree .p-tree-container>.p-treenode:last-child {
        margin-bottom: 0;
    }

    .p-treeselect-panel .p-tree {
        padding: 0.25rem 0.25rem;
    }

    .p-treeselect {
        box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
    }

    .p-treeselect.p-disabled {
        opacity: 1;
        background-color: #e2e8f0;
    }

    .p-treeselect.p-disabled .p-treeselect-label {
        color: #64748b;
    }

    .p-treeselect.p-treeselect-chip .p-treeselect-label-container .p-treeselect-token {
        border-radius: 4px;
    }
}