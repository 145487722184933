.mat-ripple {
    overflow: hidden;
    position: relative
}

.mat-ripple:not(:empty) {
    transform: translateZ(0)
}

.mat-ripple.mat-ripple-unbounded {
    overflow: visible
}

.mat-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform: scale3d(0, 0, 0);
    background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1))
}

.cdk-high-contrast-active .mat-ripple-element {
    display: none
}

.cdk-drag-preview .mat-ripple-element,
.cdk-drag-placeholder .mat-ripple-element {
    display: none
}

.cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    left: 0
}

[dir=rtl] .cdk-visually-hidden {
    left: auto;
    right: 0
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1000
}

.cdk-overlay-container:empty {
    display: none
}

.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000
}

.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%
}

.cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: .6
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, .32)
}

.cdk-overlay-transparent-backdrop {
    transition: visibility 1ms linear, opacity 1ms linear;
    visibility: hidden;
    opacity: 1
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0;
    visibility: visible
}

.cdk-overlay-backdrop-noop-animation {
    transition: none
}

.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px
}

.cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: scroll
}

textarea.cdk-textarea-autosize {
    resize: none
}

textarea.cdk-textarea-autosize-measuring {
    padding: 2px 0 !important;
    box-sizing: content-box !important;
    height: auto !important;
    overflow: hidden !important
}

textarea.cdk-textarea-autosize-measuring-firefox {
    padding: 2px 0 !important;
    box-sizing: content-box !important;
    height: 0 !important
}

@keyframes cdk-text-field-autofill-start {
    /*!*/
}

@keyframes cdk-text-field-autofill-end {
    /*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
    animation: cdk-text-field-autofill-start 0s 1ms
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
    animation: cdk-text-field-autofill-end 0s 1ms
}

.mat-focus-indicator {
    position: relative
}

.mat-focus-indicator::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    box-sizing: border-box;
    pointer-events: none;
    display: var(--mat-focus-indicator-display, none);
    border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
    border-radius: var(--mat-focus-indicator-border-radius, 4px)
}

.mat-focus-indicator:focus::before {
    content: ""
}

.cdk-high-contrast-active {
    --mat-focus-indicator-display: block
}

.mat-mdc-focus-indicator {
    position: relative
}

.mat-mdc-focus-indicator::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    box-sizing: border-box;
    pointer-events: none;
    display: var(--mat-mdc-focus-indicator-display, none);
    border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
    border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px)
}

.mat-mdc-focus-indicator:focus::before {
    content: ""
}

.cdk-high-contrast-active {
    --mat-mdc-focus-indicator-display: block
}

.mat-app-background {
    background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
    color: var(--mat-app-text-color, var(--mat-app-on-background, inherit))
}

.mat-elevation-z0,
.mat-mdc-elevation-specific.mat-elevation-z0 {
    box-shadow: var(--mat-app-elevation-shadow-level-0, none)
}

.mat-elevation-z1,
.mat-mdc-elevation-specific.mat-elevation-z1 {
    box-shadow: var(--mat-app-elevation-shadow-level-1, none)
}

.mat-elevation-z2,
.mat-mdc-elevation-specific.mat-elevation-z2 {
    box-shadow: var(--mat-app-elevation-shadow-level-2, none)
}

.mat-elevation-z3,
.mat-mdc-elevation-specific.mat-elevation-z3 {
    box-shadow: var(--mat-app-elevation-shadow-level-3, none)
}

.mat-elevation-z4,
.mat-mdc-elevation-specific.mat-elevation-z4 {
    box-shadow: var(--mat-app-elevation-shadow-level-4, none)
}

.mat-elevation-z5,
.mat-mdc-elevation-specific.mat-elevation-z5 {
    box-shadow: var(--mat-app-elevation-shadow-level-5, none)
}

.mat-elevation-z6,
.mat-mdc-elevation-specific.mat-elevation-z6 {
    box-shadow: var(--mat-app-elevation-shadow-level-6, none)
}

.mat-elevation-z7,
.mat-mdc-elevation-specific.mat-elevation-z7 {
    box-shadow: var(--mat-app-elevation-shadow-level-7, none)
}

.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
    box-shadow: var(--mat-app-elevation-shadow-level-8, none)
}

.mat-elevation-z9,
.mat-mdc-elevation-specific.mat-elevation-z9 {
    box-shadow: var(--mat-app-elevation-shadow-level-9, none)
}

.mat-elevation-z10,
.mat-mdc-elevation-specific.mat-elevation-z10 {
    box-shadow: var(--mat-app-elevation-shadow-level-10, none)
}

.mat-elevation-z11,
.mat-mdc-elevation-specific.mat-elevation-z11 {
    box-shadow: var(--mat-app-elevation-shadow-level-11, none)
}

.mat-elevation-z12,
.mat-mdc-elevation-specific.mat-elevation-z12 {
    box-shadow: var(--mat-app-elevation-shadow-level-12, none)
}

.mat-elevation-z13,
.mat-mdc-elevation-specific.mat-elevation-z13 {
    box-shadow: var(--mat-app-elevation-shadow-level-13, none)
}

.mat-elevation-z14,
.mat-mdc-elevation-specific.mat-elevation-z14 {
    box-shadow: var(--mat-app-elevation-shadow-level-14, none)
}

.mat-elevation-z15,
.mat-mdc-elevation-specific.mat-elevation-z15 {
    box-shadow: var(--mat-app-elevation-shadow-level-15, none)
}

.mat-elevation-z16,
.mat-mdc-elevation-specific.mat-elevation-z16 {
    box-shadow: var(--mat-app-elevation-shadow-level-16, none)
}

.mat-elevation-z17,
.mat-mdc-elevation-specific.mat-elevation-z17 {
    box-shadow: var(--mat-app-elevation-shadow-level-17, none)
}

.mat-elevation-z18,
.mat-mdc-elevation-specific.mat-elevation-z18 {
    box-shadow: var(--mat-app-elevation-shadow-level-18, none)
}

.mat-elevation-z19,
.mat-mdc-elevation-specific.mat-elevation-z19 {
    box-shadow: var(--mat-app-elevation-shadow-level-19, none)
}

.mat-elevation-z20,
.mat-mdc-elevation-specific.mat-elevation-z20 {
    box-shadow: var(--mat-app-elevation-shadow-level-20, none)
}

.mat-elevation-z21,
.mat-mdc-elevation-specific.mat-elevation-z21 {
    box-shadow: var(--mat-app-elevation-shadow-level-21, none)
}

.mat-elevation-z22,
.mat-mdc-elevation-specific.mat-elevation-z22 {
    box-shadow: var(--mat-app-elevation-shadow-level-22, none)
}

.mat-elevation-z23,
.mat-mdc-elevation-specific.mat-elevation-z23 {
    box-shadow: var(--mat-app-elevation-shadow-level-23, none)
}

.mat-elevation-z24,
.mat-mdc-elevation-specific.mat-elevation-z24 {
    box-shadow: var(--mat-app-elevation-shadow-level-24, none)
}

html {
    --mat-app-background-color: #fdfbff;
    --mat-app-text-color: #1a1b1f;
    --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
    --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    --mat-ripple-color: rgba(26, 27, 31, 0.1);
    --mat-option-selected-state-label-text-color: #131c2b;
    --mat-option-label-text-color: #1a1b1f;
    --mat-option-hover-state-layer-color: rgba(26, 27, 31, 0.08);
    --mat-option-focus-state-layer-color: rgba(26, 27, 31, 0.12);
    --mat-option-selected-state-layer-color: #dae2f9;
    --mat-option-label-text-font: Roboto, sans-serif;
    --mat-option-label-text-line-height: 1.25rem;
    --mat-option-label-text-size: 1rem;
    --mat-option-label-text-tracking: 0.006rem;
    --mat-option-label-text-weight: 400;
    --mat-optgroup-label-text-color: #44474e;
    --mat-optgroup-label-text-font: Roboto, sans-serif;
    --mat-optgroup-label-text-line-height: 1.25rem;
    --mat-optgroup-label-text-size: 0.875rem;
    --mat-optgroup-label-text-tracking: 0.006rem;
    --mat-optgroup-label-text-weight: 500;
    --mat-full-pseudo-checkbox-selected-icon-color: #005cbb;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
    --mat-full-pseudo-checkbox-unselected-icon-color: #44474e;
    --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fdfbff;
    --mat-full-pseudo-checkbox-disabled-unselected-icon-color: rgba(26, 27, 31, 0.38);
    --mat-full-pseudo-checkbox-disabled-selected-icon-color: rgba(26, 27, 31, 0.38);
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #005cbb;
    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: rgba(26, 27, 31, 0.38);
    --mdc-elevated-card-container-color: #fdfbff;
    --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mdc-elevated-card-container-shape: 12px;
    --mdc-outlined-card-container-color: #fdfbff;
    --mdc-outlined-card-outline-color: #c4c6d0;
    --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mdc-outlined-card-container-shape: 12px;
    --mdc-outlined-card-outline-width: 1px;
    --mat-card-subtitle-text-color: #1a1b1f;
    --mat-card-title-text-font: Roboto, sans-serif;
    --mat-card-title-text-line-height: 1.75rem;
    --mat-card-title-text-size: 1.375rem;
    --mat-card-title-text-tracking: 0;
    --mat-card-title-text-weight: 400;
    --mat-card-subtitle-text-font: Roboto, sans-serif;
    --mat-card-subtitle-text-line-height: 1.5rem;
    --mat-card-subtitle-text-size: 1rem;
    --mat-card-subtitle-text-tracking: 0.009rem;
    --mat-card-subtitle-text-weight: 500;
    --mdc-linear-progress-active-indicator-color: #005cbb;
    --mdc-linear-progress-track-color: #e0e2ec;
    --mdc-linear-progress-active-indicator-height: 4px;
    --mdc-linear-progress-track-height: 4px;
    --mdc-linear-progress-track-shape: 0;
    --mdc-plain-tooltip-container-color: #2f3033;
    --mdc-plain-tooltip-supporting-text-color: #f2f0f4;
    --mdc-plain-tooltip-supporting-text-line-height: 1rem;
    --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
    --mdc-plain-tooltip-supporting-text-size: 0.75rem;
    --mdc-plain-tooltip-supporting-text-weight: 400;
    --mdc-plain-tooltip-supporting-text-tracking: 0.025rem;
    --mdc-plain-tooltip-container-shape: 4px;
    --mdc-filled-text-field-caret-color: #005cbb;
    --mdc-filled-text-field-focus-active-indicator-color: #005cbb;
    --mdc-filled-text-field-focus-label-text-color: #005cbb;
    --mdc-filled-text-field-container-color: #e0e2ec;
    --mdc-filled-text-field-disabled-container-color: rgba(26, 27, 31, 0.04);
    --mdc-filled-text-field-label-text-color: #44474e;
    --mdc-filled-text-field-hover-label-text-color: #44474e;
    --mdc-filled-text-field-disabled-label-text-color: rgba(26, 27, 31, 0.38);
    --mdc-filled-text-field-input-text-color: #1a1b1f;
    --mdc-filled-text-field-disabled-input-text-color: rgba(26, 27, 31, 0.38);
    --mdc-filled-text-field-input-text-placeholder-color: #44474e;
    --mdc-filled-text-field-error-hover-label-text-color: #410002;
    --mdc-filled-text-field-error-focus-label-text-color: #ba1a1a;
    --mdc-filled-text-field-error-label-text-color: #ba1a1a;
    --mdc-filled-text-field-active-indicator-color: #44474e;
    --mdc-filled-text-field-disabled-active-indicator-color: rgba(26, 27, 31, 0.38);
    --mdc-filled-text-field-hover-active-indicator-color: #1a1b1f;
    --mdc-filled-text-field-error-active-indicator-color: #ba1a1a;
    --mdc-filled-text-field-error-focus-active-indicator-color: #ba1a1a;
    --mdc-filled-text-field-error-hover-active-indicator-color: #410002;
    --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
    --mdc-filled-text-field-label-text-size: 1rem;
    --mdc-filled-text-field-label-text-tracking: 0.031rem;
    --mdc-filled-text-field-label-text-weight: 400;
    --mdc-filled-text-field-active-indicator-height: 1px;
    --mdc-filled-text-field-focus-active-indicator-height: 2px;
    --mdc-filled-text-field-container-shape: 4px;
    --mdc-outlined-text-field-caret-color: #005cbb;
    --mdc-outlined-text-field-focus-outline-color: #005cbb;
    --mdc-outlined-text-field-focus-label-text-color: #005cbb;
    --mdc-outlined-text-field-label-text-color: #44474e;
    --mdc-outlined-text-field-hover-label-text-color: #1a1b1f;
    --mdc-outlined-text-field-disabled-label-text-color: rgba(26, 27, 31, 0.38);
    --mdc-outlined-text-field-input-text-color: #1a1b1f;
    --mdc-outlined-text-field-disabled-input-text-color: rgba(26, 27, 31, 0.38);
    --mdc-outlined-text-field-input-text-placeholder-color: #44474e;
    --mdc-outlined-text-field-error-focus-label-text-color: #ba1a1a;
    --mdc-outlined-text-field-error-label-text-color: #ba1a1a;
    --mdc-outlined-text-field-error-hover-label-text-color: #410002;
    --mdc-outlined-text-field-outline-color: #74777f;
    --mdc-outlined-text-field-disabled-outline-color: rgba(26, 27, 31, 0.12);
    --mdc-outlined-text-field-hover-outline-color: #1a1b1f;
    --mdc-outlined-text-field-error-focus-outline-color: #ba1a1a;
    --mdc-outlined-text-field-error-hover-outline-color: #410002;
    --mdc-outlined-text-field-error-outline-color: #ba1a1a;
    --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
    --mdc-outlined-text-field-label-text-size: 1rem;
    --mdc-outlined-text-field-label-text-tracking: 0.031rem;
    --mdc-outlined-text-field-label-text-weight: 400;
    --mdc-outlined-text-field-outline-width: 1px;
    --mdc-outlined-text-field-focus-outline-width: 2px;
    --mdc-outlined-text-field-container-shape: 4px;
    --mat-form-field-focus-select-arrow-color: #005cbb;
    --mat-form-field-disabled-input-text-placeholder-color: rgba(26, 27, 31, 0.38);
    --mat-form-field-state-layer-color: #1a1b1f;
    --mat-form-field-error-text-color: #ba1a1a;
    --mat-form-field-select-option-text-color: #1a1b1f;
    --mat-form-field-select-disabled-option-text-color: rgba(26, 27, 31, 0.38);
    --mat-form-field-leading-icon-color: #44474e;
    --mat-form-field-disabled-leading-icon-color: rgba(26, 27, 31, 0.38);
    --mat-form-field-trailing-icon-color: #44474e;
    --mat-form-field-disabled-trailing-icon-color: rgba(26, 27, 31, 0.38);
    --mat-form-field-error-focus-trailing-icon-color: #ba1a1a;
    --mat-form-field-error-hover-trailing-icon-color: #410002;
    --mat-form-field-error-trailing-icon-color: #ba1a1a;
    --mat-form-field-enabled-select-arrow-color: #44474e;
    --mat-form-field-disabled-select-arrow-color: rgba(26, 27, 31, 0.38);
    --mat-form-field-hover-state-layer-opacity: 0.08;
    --mat-form-field-container-text-font: Roboto, sans-serif;
    --mat-form-field-container-text-line-height: 1.5rem;
    --mat-form-field-container-text-size: 1rem;
    --mat-form-field-container-text-tracking: 0.031rem;
    --mat-form-field-container-text-weight: 400;
    --mat-form-field-subscript-text-font: Roboto, sans-serif;
    --mat-form-field-subscript-text-line-height: 1rem;
    --mat-form-field-subscript-text-size: 0.75rem;
    --mat-form-field-subscript-text-tracking: 0.025rem;
    --mat-form-field-subscript-text-weight: 400;
    --mat-form-field-container-height: 56px;
    --mat-form-field-filled-label-display: block;
    --mat-form-field-container-vertical-padding: 16px;
    --mat-form-field-filled-with-label-container-padding-top: 24px;
    --mat-form-field-filled-with-label-container-padding-bottom: 8px;
    --mat-form-field-focus-state-layer-opacity: 0;
    --mat-select-panel-background-color: #efedf1;
    --mat-select-enabled-trigger-text-color: #1a1b1f;
    --mat-select-disabled-trigger-text-color: rgba(26, 27, 31, 0.38);
    --mat-select-placeholder-text-color: #44474e;
    --mat-select-enabled-arrow-color: #44474e;
    --mat-select-disabled-arrow-color: rgba(26, 27, 31, 0.38);
    --mat-select-focused-arrow-color: #005cbb;
    --mat-select-invalid-arrow-color: #ba1a1a;
    --mat-select-trigger-text-font: Roboto, sans-serif;
    --mat-select-trigger-text-line-height: 1.5rem;
    --mat-select-trigger-text-size: 1rem;
    --mat-select-trigger-text-tracking: 0.031rem;
    --mat-select-trigger-text-weight: 400;
    --mat-select-arrow-transform: translateY(-8px);
    --mat-select-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    --mat-autocomplete-background-color: #efedf1;
    --mat-autocomplete-container-shape: 4px;
    --mat-autocomplete-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    --mdc-dialog-container-color: #fdfbff;
    --mdc-dialog-subhead-color: #1a1b1f;
    --mdc-dialog-supporting-text-color: #44474e;
    --mdc-dialog-subhead-font: Roboto, sans-serif;
    --mdc-dialog-subhead-line-height: 2rem;
    --mdc-dialog-subhead-size: 1.5rem;
    --mdc-dialog-subhead-weight: 400;
    --mdc-dialog-subhead-tracking: 0;
    --mdc-dialog-supporting-text-font: Roboto, sans-serif;
    --mdc-dialog-supporting-text-line-height: 1.25rem;
    --mdc-dialog-supporting-text-size: 0.875rem;
    --mdc-dialog-supporting-text-weight: 400;
    --mdc-dialog-supporting-text-tracking: 0.016rem;
    --mdc-dialog-container-shape: 28px;
    --mat-dialog-container-elevation-shadow: none;
    --mat-dialog-container-max-width: 560px;
    --mat-dialog-container-small-max-width: calc(100vw - 32px);
    --mat-dialog-container-min-width: 280px;
    --mat-dialog-actions-alignment: flex-end;
    --mat-dialog-actions-padding: 16px 24px;
    --mat-dialog-content-padding: 20px 24px;
    --mat-dialog-with-actions-content-padding: 20px 24px 0;
    --mat-dialog-headline-padding: 6px 24px 13px;
    --mdc-chip-outline-color: #74777f;
    --mdc-chip-disabled-outline-color: rgba(26, 27, 31, 0.12);
    --mdc-chip-focus-outline-color: #44474e;
    --mdc-chip-hover-state-layer-opacity: 0.08;
    --mdc-chip-selected-hover-state-layer-opacity: 0.08;
    --mdc-chip-disabled-label-text-color: rgba(26, 27, 31, 0.38);
    --mdc-chip-elevated-selected-container-color: #dae2f9;
    --mdc-chip-flat-disabled-selected-container-color: rgba(26, 27, 31, 0.12);
    --mdc-chip-focus-state-layer-color: #44474e;
    --mdc-chip-hover-state-layer-color: #44474e;
    --mdc-chip-selected-hover-state-layer-color: #131c2b;
    --mdc-chip-focus-state-layer-opacity: 0.12;
    --mdc-chip-selected-focus-state-layer-color: #131c2b;
    --mdc-chip-selected-focus-state-layer-opacity: 0.12;
    --mdc-chip-label-text-color: #44474e;
    --mdc-chip-selected-label-text-color: #131c2b;
    --mdc-chip-with-icon-icon-color: #44474e;
    --mdc-chip-with-icon-disabled-icon-color: #1a1b1f;
    --mdc-chip-with-icon-selected-icon-color: #131c2b;
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #1a1b1f;
    --mdc-chip-with-trailing-icon-trailing-icon-color: #44474e;
    --mdc-chip-label-text-font: Roboto, sans-serif;
    --mdc-chip-label-text-line-height: 1.25rem;
    --mdc-chip-label-text-size: 0.875rem;
    --mdc-chip-label-text-tracking: 0.006rem;
    --mdc-chip-label-text-weight: 500;
    --mdc-chip-container-height: 32px;
    --mdc-chip-container-shape-radius: 8px;
    --mdc-chip-with-avatar-avatar-shape-radius: 24px;
    --mdc-chip-with-avatar-avatar-size: 24px;
    --mdc-chip-with-icon-icon-size: 18px;
    --mdc-chip-outline-width: 1px;
    --mdc-chip-with-avatar-disabled-avatar-opacity: 0.38;
    --mdc-chip-flat-selected-outline-width: 0;
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 0.38;
    --mdc-chip-with-icon-disabled-icon-opacity: 0.38;
    --mdc-chip-elevated-container-color: transparent;
    --mat-chip-trailing-action-state-layer-color: #44474e;
    --mat-chip-selected-trailing-action-state-layer-color: #131c2b;
    --mat-chip-trailing-action-hover-state-layer-opacity: 0.08;
    --mat-chip-trailing-action-focus-state-layer-opacity: 0.12;
    --mat-chip-selected-disabled-trailing-icon-color: #1a1b1f;
    --mat-chip-selected-trailing-icon-color: #131c2b;
    --mat-chip-disabled-container-opacity: 1;
    --mat-chip-trailing-action-opacity: 1;
    --mat-chip-trailing-action-focus-opacity: 1;
    --mdc-switch-selected-focus-state-layer-opacity: 0.12;
    --mdc-switch-selected-hover-state-layer-opacity: 0.08;
    --mdc-switch-selected-pressed-state-layer-opacity: 0.12;
    --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
    --mdc-switch-unselected-hover-state-layer-opacity: 0.08;
    --mdc-switch-unselected-pressed-state-layer-opacity: 0.12;
    --mdc-switch-selected-focus-state-layer-color: #005cbb;
    --mdc-switch-selected-handle-color: #ffffff;
    --mdc-switch-selected-hover-state-layer-color: #005cbb;
    --mdc-switch-selected-pressed-state-layer-color: #005cbb;
    --mdc-switch-selected-focus-handle-color: #d7e3ff;
    --mdc-switch-selected-hover-handle-color: #d7e3ff;
    --mdc-switch-selected-pressed-handle-color: #d7e3ff;
    --mdc-switch-selected-focus-track-color: #005cbb;
    --mdc-switch-selected-hover-track-color: #005cbb;
    --mdc-switch-selected-pressed-track-color: #005cbb;
    --mdc-switch-selected-track-color: #005cbb;
    --mdc-switch-disabled-selected-handle-color: #fdfbff;
    --mdc-switch-disabled-selected-icon-color: #1a1b1f;
    --mdc-switch-disabled-selected-track-color: #1a1b1f;
    --mdc-switch-disabled-unselected-handle-color: #1a1b1f;
    --mdc-switch-disabled-unselected-icon-color: #e0e2ec;
    --mdc-switch-disabled-unselected-track-color: #e0e2ec;
    --mdc-switch-selected-icon-color: #001b3f;
    --mdc-switch-unselected-focus-handle-color: #44474e;
    --mdc-switch-unselected-focus-state-layer-color: #1a1b1f;
    --mdc-switch-unselected-focus-track-color: #e0e2ec;
    --mdc-switch-unselected-handle-color: #74777f;
    --mdc-switch-unselected-hover-handle-color: #44474e;
    --mdc-switch-unselected-hover-state-layer-color: #1a1b1f;
    --mdc-switch-unselected-hover-track-color: #e0e2ec;
    --mdc-switch-unselected-icon-color: #e0e2ec;
    --mdc-switch-unselected-pressed-handle-color: #44474e;
    --mdc-switch-unselected-pressed-state-layer-color: #1a1b1f;
    --mdc-switch-unselected-pressed-track-color: #e0e2ec;
    --mdc-switch-unselected-track-color: #e0e2ec;
    --mdc-switch-disabled-selected-icon-opacity: 0.38;
    --mdc-switch-disabled-track-opacity: 0.12;
    --mdc-switch-disabled-unselected-icon-opacity: 0.38;
    --mdc-switch-handle-shape: 9999px;
    --mdc-switch-selected-icon-size: 16px;
    --mdc-switch-track-height: 32px;
    --mdc-switch-track-shape: 9999px;
    --mdc-switch-track-width: 52px;
    --mdc-switch-unselected-icon-size: 16px;
    --mdc-switch-state-layer-size: 40px;
    --mat-switch-track-outline-color: #74777f;
    --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
    --mat-switch-label-text-color: #1a1b1f;
    --mat-switch-label-text-font: Roboto, sans-serif;
    --mat-switch-label-text-line-height: 1.25rem;
    --mat-switch-label-text-size: 0.875rem;
    --mat-switch-label-text-tracking: 0.016rem;
    --mat-switch-label-text-weight: 400;
    --mat-switch-disabled-selected-handle-opacity: 1;
    --mat-switch-disabled-unselected-handle-opacity: 0.38;
    --mat-switch-unselected-handle-size: 16px;
    --mat-switch-selected-handle-size: 24px;
    --mat-switch-pressed-handle-size: 28px;
    --mat-switch-with-icon-handle-size: 24px;
    --mat-switch-selected-handle-horizontal-margin: 0 24px;
    --mat-switch-selected-with-icon-handle-horizontal-margin: 0 24px;
    --mat-switch-selected-pressed-handle-horizontal-margin: 0 22px;
    --mat-switch-unselected-handle-horizontal-margin: 0 8px;
    --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
    --mat-switch-unselected-pressed-handle-horizontal-margin: 0 2px;
    --mat-switch-visible-track-opacity: 1;
    --mat-switch-hidden-track-opacity: 0;
    --mat-switch-visible-track-transition: opacity 75ms;
    --mat-switch-hidden-track-transition: opacity 75ms;
    --mat-switch-track-outline-width: 2px;
    --mat-switch-selected-track-outline-width: 2px;
    --mat-switch-selected-track-outline-color: transparent;
    --mat-switch-disabled-unselected-track-outline-width: 2px;
    --mdc-radio-disabled-selected-icon-color: #1a1b1f;
    --mdc-radio-disabled-unselected-icon-color: #1a1b1f;
    --mdc-radio-unselected-hover-icon-color: #1a1b1f;
    --mdc-radio-unselected-icon-color: #44474e;
    --mdc-radio-unselected-pressed-icon-color: #1a1b1f;
    --mdc-radio-selected-focus-icon-color: #005cbb;
    --mdc-radio-selected-hover-icon-color: #005cbb;
    --mdc-radio-selected-icon-color: #005cbb;
    --mdc-radio-selected-pressed-icon-color: #005cbb;
    --mdc-radio-state-layer-size: 40px;
    --mdc-radio-disabled-selected-icon-opacity: 0.38;
    --mdc-radio-disabled-unselected-icon-opacity: 0.38;
    --mat-radio-ripple-color: #1a1b1f;
    --mat-radio-checked-ripple-color: #005cbb;
    --mat-radio-disabled-label-color: rgba(26, 27, 31, 0.38);
    --mat-radio-label-text-color: #1a1b1f;
    --mat-radio-label-text-font: Roboto, sans-serif;
    --mat-radio-label-text-line-height: 1.25rem;
    --mat-radio-label-text-size: 0.875rem;
    --mat-radio-label-text-tracking: 0.016rem;
    --mat-radio-label-text-weight: 400;
    --mat-radio-touch-target-display: block;
    --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mdc-slider-handle-color: #005cbb;
    --mdc-slider-focus-handle-color: #005cbb;
    --mdc-slider-hover-handle-color: #005cbb;
    --mdc-slider-active-track-color: #005cbb;
    --mdc-slider-inactive-track-color: #e0e2ec;
    --mdc-slider-with-tick-marks-inactive-container-color: #44474e;
    --mdc-slider-with-tick-marks-active-container-color: #ffffff;
    --mdc-slider-disabled-active-track-color: #1a1b1f;
    --mdc-slider-disabled-handle-color: #1a1b1f;
    --mdc-slider-disabled-inactive-track-color: #1a1b1f;
    --mdc-slider-label-container-color: #005cbb;
    --mdc-slider-label-label-text-color: #ffffff;
    --mdc-slider-with-overlap-handle-outline-color: #ffffff;
    --mdc-slider-with-tick-marks-disabled-container-color: #1a1b1f;
    --mdc-slider-label-label-text-font: Roboto, sans-serif;
    --mdc-slider-label-label-text-size: 0.75rem;
    --mdc-slider-label-label-text-line-height: 1rem;
    --mdc-slider-label-label-text-tracking: 0.031rem;
    --mdc-slider-label-label-text-weight: 500;
    --mdc-slider-active-track-height: 4px;
    --mdc-slider-active-track-shape: 9999px;
    --mdc-slider-handle-height: 20px;
    --mdc-slider-handle-shape: 9999px;
    --mdc-slider-handle-width: 20px;
    --mdc-slider-inactive-track-height: 4px;
    --mdc-slider-inactive-track-shape: 9999px;
    --mdc-slider-with-overlap-handle-outline-width: 1px;
    --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
    --mdc-slider-with-tick-marks-container-shape: 9999px;
    --mdc-slider-with-tick-marks-container-size: 2px;
    --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;
    --mat-slider-ripple-color: #005cbb;
    --mat-slider-hover-state-layer-color: rgba(0, 92, 187, 0.05);
    --mat-slider-focus-state-layer-color: rgba(0, 92, 187, 0.2);
    --mat-slider-value-indicator-width: 28px;
    --mat-slider-value-indicator-height: 28px;
    --mat-slider-value-indicator-caret-display: none;
    --mat-slider-value-indicator-border-radius: 50% 50% 50% 0;
    --mat-slider-value-indicator-padding: 0;
    --mat-slider-value-indicator-text-transform: rotate(45deg);
    --mat-slider-value-indicator-container-transform: translateX(-50%) rotate(-45deg);
    --mat-slider-value-indicator-opacity: 1;
    --mat-menu-item-label-text-color: #1a1b1f;
    --mat-menu-item-icon-color: #44474e;
    --mat-menu-item-hover-state-layer-color: rgba(26, 27, 31, 0.08);
    --mat-menu-item-focus-state-layer-color: rgba(26, 27, 31, 0.12);
    --mat-menu-container-color: #efedf1;
    --mat-menu-divider-color: #e0e2ec;
    --mat-menu-item-label-text-font: Roboto, sans-serif;
    --mat-menu-item-label-text-size: 0.875rem;
    --mat-menu-item-label-text-tracking: 0.006rem;
    --mat-menu-item-label-text-line-height: 1.25rem;
    --mat-menu-item-label-text-weight: 500;
    --mat-menu-container-shape: 4px;
    --mat-menu-divider-bottom-spacing: 8px;
    --mat-menu-divider-top-spacing: 8px;
    --mat-menu-item-spacing: 12px;
    --mat-menu-item-icon-size: 24px;
    --mat-menu-item-leading-spacing: 12px;
    --mat-menu-item-trailing-spacing: 12px;
    --mat-menu-item-with-icon-leading-spacing: 12px;
    --mat-menu-item-with-icon-trailing-spacing: 12px;
    --mat-menu-base-elevation-level: 2;
    --mdc-list-list-item-container-color: transparent;
    --mdc-list-list-item-leading-avatar-color: #d7e3ff;
    --mdc-list-list-item-disabled-state-layer-color: #1a1b1f;
    --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
    --mdc-list-list-item-label-text-color: #1a1b1f;
    --mdc-list-list-item-supporting-text-color: #44474e;
    --mdc-list-list-item-leading-icon-color: #44474e;
    --mdc-list-list-item-trailing-supporting-text-color: #44474e;
    --mdc-list-list-item-trailing-icon-color: #44474e;
    --mdc-list-list-item-selected-trailing-icon-color: #005cbb;
    --mdc-list-list-item-disabled-label-text-color: #1a1b1f;
    --mdc-list-list-item-disabled-leading-icon-color: #1a1b1f;
    --mdc-list-list-item-disabled-trailing-icon-color: #1a1b1f;
    --mdc-list-list-item-hover-label-text-color: #1a1b1f;
    --mdc-list-list-item-focus-label-text-color: #1a1b1f;
    --mdc-list-list-item-hover-state-layer-color: #1a1b1f;
    --mdc-list-list-item-hover-state-layer-opacity: 0.08;
    --mdc-list-list-item-focus-state-layer-color: #1a1b1f;
    --mdc-list-list-item-focus-state-layer-opacity: 0.12;
    --mdc-list-list-item-label-text-font: Roboto, sans-serif;
    --mdc-list-list-item-label-text-line-height: 1.5rem;
    --mdc-list-list-item-label-text-size: 1rem;
    --mdc-list-list-item-label-text-tracking: 0.031rem;
    --mdc-list-list-item-label-text-weight: 400;
    --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
    --mdc-list-list-item-supporting-text-line-height: 1.25rem;
    --mdc-list-list-item-supporting-text-size: 0.875rem;
    --mdc-list-list-item-supporting-text-tracking: 0.016rem;
    --mdc-list-list-item-supporting-text-weight: 400;
    --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
    --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
    --mdc-list-list-item-trailing-supporting-text-size: 0.688rem;
    --mdc-list-list-item-trailing-supporting-text-tracking: 0.031rem;
    --mdc-list-list-item-trailing-supporting-text-weight: 500;
    --mdc-list-list-item-one-line-container-height: 48px;
    --mdc-list-list-item-two-line-container-height: 64px;
    --mdc-list-list-item-three-line-container-height: 88px;
    --mdc-list-list-item-container-shape: 0;
    --mdc-list-list-item-leading-avatar-shape: 9999px;
    --mdc-list-list-item-leading-icon-size: 24px;
    --mdc-list-list-item-leading-avatar-size: 40px;
    --mdc-list-list-item-trailing-icon-size: 24px;
    --mdc-list-list-item-disabled-label-text-opacity: 0.3;
    --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
    --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
    --mat-list-active-indicator-color: #dae2f9;
    --mat-list-list-item-leading-icon-start-space: 16px;
    --mat-list-list-item-leading-icon-end-space: 16px;
    --mat-list-active-indicator-shape: 9999px;
    --mat-paginator-container-text-color: #1a1b1f;
    --mat-paginator-container-background-color: #fdfbff;
    --mat-paginator-enabled-icon-color: #44474e;
    --mat-paginator-disabled-icon-color: rgba(26, 27, 31, 0.38);
    --mat-paginator-container-text-font: Roboto, sans-serif;
    --mat-paginator-container-text-line-height: 1rem;
    --mat-paginator-container-text-size: 0.75rem;
    --mat-paginator-container-text-tracking: 0.025rem;
    --mat-paginator-container-text-weight: 400;
    --mat-paginator-select-trigger-text-size: 0.75rem;
    --mat-paginator-container-size: 56px;
    --mat-paginator-form-field-container-height: 40px;
    --mat-paginator-form-field-container-vertical-padding: 8px;
    --mat-paginator-touch-target-display: block;
    --mdc-secondary-navigation-tab-container-height: 48px;
    --mdc-tab-indicator-active-indicator-color: #005cbb;
    --mdc-tab-indicator-active-indicator-height: 2px;
    --mdc-tab-indicator-active-indicator-shape: 0;
    --mat-tab-header-divider-color: #e0e2ec;
    --mat-tab-header-pagination-icon-color: #1a1b1f;
    --mat-tab-header-inactive-label-text-color: #1a1b1f;
    --mat-tab-header-active-label-text-color: #1a1b1f;
    --mat-tab-header-active-ripple-color: #1a1b1f;
    --mat-tab-header-inactive-ripple-color: #1a1b1f;
    --mat-tab-header-inactive-focus-label-text-color: #1a1b1f;
    --mat-tab-header-inactive-hover-label-text-color: #1a1b1f;
    --mat-tab-header-active-focus-label-text-color: #1a1b1f;
    --mat-tab-header-active-hover-label-text-color: #1a1b1f;
    --mat-tab-header-active-focus-indicator-color: #005cbb;
    --mat-tab-header-active-hover-indicator-color: #005cbb;
    --mat-tab-header-label-text-font: Roboto, sans-serif;
    --mat-tab-header-label-text-size: 0.875rem;
    --mat-tab-header-label-text-tracking: 0.006rem;
    --mat-tab-header-label-text-line-height: 1.25rem;
    --mat-tab-header-label-text-weight: 500;
    --mat-tab-header-divider-height: 1px;
    --mdc-checkbox-disabled-selected-checkmark-color: #fdfbff;
    --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
    --mdc-checkbox-selected-hover-state-layer-opacity: 0.08;
    --mdc-checkbox-selected-pressed-state-layer-opacity: 0.12;
    --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
    --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;
    --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.12;
    --mdc-checkbox-selected-pressed-icon-color: #005cbb;
    --mdc-checkbox-disabled-selected-icon-color: rgba(26, 27, 31, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(26, 27, 31, 0.38);
    --mdc-checkbox-selected-checkmark-color: #ffffff;
    --mdc-checkbox-selected-focus-icon-color: #005cbb;
    --mdc-checkbox-selected-hover-icon-color: #005cbb;
    --mdc-checkbox-selected-icon-color: #005cbb;
    --mdc-checkbox-unselected-focus-icon-color: #1a1b1f;
    --mdc-checkbox-unselected-hover-icon-color: #1a1b1f;
    --mdc-checkbox-unselected-icon-color: #44474e;
    --mdc-checkbox-selected-focus-state-layer-color: #005cbb;
    --mdc-checkbox-selected-hover-state-layer-color: #005cbb;
    --mdc-checkbox-selected-pressed-state-layer-color: #1a1b1f;
    --mdc-checkbox-unselected-focus-state-layer-color: #1a1b1f;
    --mdc-checkbox-unselected-hover-state-layer-color: #1a1b1f;
    --mdc-checkbox-unselected-pressed-state-layer-color: #005cbb;
    --mdc-checkbox-state-layer-size: 40px;
    --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
    --mat-checkbox-label-text-color: #1a1b1f;
    --mat-checkbox-label-text-font: Roboto, sans-serif;
    --mat-checkbox-label-text-line-height: 1.25rem;
    --mat-checkbox-label-text-size: 0.875rem;
    --mat-checkbox-label-text-tracking: 0.016rem;
    --mat-checkbox-label-text-weight: 400;
    --mat-checkbox-touch-target-display: block;
    --mdc-text-button-label-text-color: #005cbb;
    --mdc-text-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
    --mdc-text-button-label-text-font: Roboto, sans-serif;
    --mdc-text-button-label-text-size: 0.875rem;
    --mdc-text-button-label-text-tracking: 0.006rem;
    --mdc-text-button-label-text-weight: 500;
    --mdc-text-button-container-height: 40px;
    --mdc-text-button-container-shape: 9999px;
    --mdc-protected-button-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mdc-protected-button-hover-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mdc-protected-button-container-color: #fdfbff;
    --mdc-protected-button-label-text-color: #005cbb;
    --mdc-protected-button-disabled-container-color: rgba(26, 27, 31, 0.12);
    --mdc-protected-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
    --mdc-protected-button-label-text-font: Roboto, sans-serif;
    --mdc-protected-button-label-text-size: 0.875rem;
    --mdc-protected-button-label-text-tracking: 0.006rem;
    --mdc-protected-button-label-text-weight: 500;
    --mdc-protected-button-container-height: 40px;
    --mdc-protected-button-container-shape: 9999px;
    --mdc-filled-button-container-color: #005cbb;
    --mdc-filled-button-label-text-color: #ffffff;
    --mdc-filled-button-disabled-container-color: rgba(26, 27, 31, 0.12);
    --mdc-filled-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
    --mdc-filled-button-label-text-font: Roboto, sans-serif;
    --mdc-filled-button-label-text-size: 0.875rem;
    --mdc-filled-button-label-text-tracking: 0.006rem;
    --mdc-filled-button-label-text-weight: 500;
    --mdc-filled-button-container-height: 40px;
    --mdc-filled-button-container-shape: 9999px;
    --mdc-outlined-button-disabled-outline-color: rgba(26, 27, 31, 0.12);
    --mdc-outlined-button-disabled-label-text-color: rgba(26, 27, 31, 0.38);
    --mdc-outlined-button-label-text-color: #005cbb;
    --mdc-outlined-button-outline-color: #74777f;
    --mdc-outlined-button-label-text-font: Roboto, sans-serif;
    --mdc-outlined-button-label-text-size: 0.875rem;
    --mdc-outlined-button-label-text-tracking: 0.006rem;
    --mdc-outlined-button-label-text-weight: 500;
    --mdc-outlined-button-container-height: 40px;
    --mdc-outlined-button-outline-width: 1px;
    --mdc-outlined-button-container-shape: 9999px;
    --mat-text-button-state-layer-color: #005cbb;
    --mat-text-button-disabled-state-layer-color: #44474e;
    --mat-text-button-ripple-color: rgba(0, 92, 187, 0.12);
    --mat-text-button-hover-state-layer-opacity: 0.08;
    --mat-text-button-focus-state-layer-opacity: 0.12;
    --mat-text-button-pressed-state-layer-opacity: 0.12;
    --mat-text-button-touch-target-display: block;
    --mat-text-button-horizontal-padding: 12px;
    --mat-text-button-with-icon-horizontal-padding: 16px;
    --mat-text-button-icon-spacing: 8px;
    --mat-text-button-icon-offset: -4px;
    --mat-protected-button-state-layer-color: #005cbb;
    --mat-protected-button-disabled-state-layer-color: #44474e;
    --mat-protected-button-ripple-color: rgba(0, 92, 187, 0.12);
    --mat-protected-button-hover-state-layer-opacity: 0.08;
    --mat-protected-button-focus-state-layer-opacity: 0.12;
    --mat-protected-button-pressed-state-layer-opacity: 0.12;
    --mat-protected-button-touch-target-display: block;
    --mat-protected-button-horizontal-padding: 24px;
    --mat-protected-button-icon-spacing: 8px;
    --mat-protected-button-icon-offset: -8px;
    --mat-filled-button-state-layer-color: #ffffff;
    --mat-filled-button-disabled-state-layer-color: #44474e;
    --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.12);
    --mat-filled-button-hover-state-layer-opacity: 0.08;
    --mat-filled-button-focus-state-layer-opacity: 0.12;
    --mat-filled-button-pressed-state-layer-opacity: 0.12;
    --mat-filled-button-touch-target-display: block;
    --mat-filled-button-horizontal-padding: 24px;
    --mat-filled-button-icon-spacing: 8px;
    --mat-filled-button-icon-offset: -8px;
    --mat-outlined-button-state-layer-color: #005cbb;
    --mat-outlined-button-disabled-state-layer-color: #44474e;
    --mat-outlined-button-ripple-color: rgba(0, 92, 187, 0.12);
    --mat-outlined-button-hover-state-layer-opacity: 0.08;
    --mat-outlined-button-focus-state-layer-opacity: 0.12;
    --mat-outlined-button-pressed-state-layer-opacity: 0.12;
    --mat-outlined-button-touch-target-display: block;
    --mat-outlined-button-horizontal-padding: 24px;
    --mat-outlined-button-icon-spacing: 8px;
    --mat-outlined-button-icon-offset: -8px;
    --mdc-icon-button-icon-color: #44474e;
    --mdc-icon-button-disabled-icon-color: rgba(26, 27, 31, 0.38);
    --mdc-icon-button-state-layer-size: 40px;
    --mdc-icon-button-icon-size: 24px;
    --mat-icon-button-state-layer-color: #44474e;
    --mat-icon-button-disabled-state-layer-color: #44474e;
    --mat-icon-button-ripple-color: rgba(68, 71, 78, 0.12);
    --mat-icon-button-hover-state-layer-opacity: 0.08;
    --mat-icon-button-focus-state-layer-opacity: 0.12;
    --mat-icon-button-pressed-state-layer-opacity: 0.12;
    --mat-icon-button-touch-target-display: block;
    --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-extended-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --mdc-extended-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-extended-fab-label-text-font: Roboto, sans-serif;
    --mdc-extended-fab-label-text-size: 0.875rem;
    --mdc-extended-fab-label-text-tracking: 0.006rem;
    --mdc-extended-fab-label-text-weight: 500;
    --mdc-extended-fab-container-height: 56px;
    --mdc-extended-fab-container-shape: 16px;
    --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --mdc-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-fab-container-color: #d7e3ff;
    --mdc-fab-container-shape: 16px;
    --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-fab-small-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --mdc-fab-small-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-fab-small-container-color: #d7e3ff;
    --mdc-fab-small-container-shape: 12px;
    --mat-fab-foreground-color: #001b3f;
    --mat-fab-state-layer-color: #001b3f;
    --mat-fab-ripple-color: rgba(0, 27, 63, 0.12);
    --mat-fab-hover-state-layer-opacity: 0.08;
    --mat-fab-focus-state-layer-opacity: 0.12;
    --mat-fab-pressed-state-layer-opacity: 0.12;
    --mat-fab-disabled-state-container-color: rgba(26, 27, 31, 0.12);
    --mat-fab-disabled-state-foreground-color: rgba(26, 27, 31, 0.38);
    --mat-fab-touch-target-display: block;
    --mat-fab-small-foreground-color: #001b3f;
    --mat-fab-small-state-layer-color: #001b3f;
    --mat-fab-small-ripple-color: rgba(0, 27, 63, 0.12);
    --mat-fab-small-hover-state-layer-opacity: 0.08;
    --mat-fab-small-focus-state-layer-opacity: 0.12;
    --mat-fab-small-pressed-state-layer-opacity: 0.12;
    --mat-fab-small-disabled-state-container-color: rgba(26, 27, 31, 0.12);
    --mat-fab-small-disabled-state-foreground-color: rgba(26, 27, 31, 0.38);
    --mdc-snackbar-container-color: #2f3033;
    --mdc-snackbar-supporting-text-color: #f2f0f4;
    --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
    --mdc-snackbar-supporting-text-line-height: 1.25rem;
    --mdc-snackbar-supporting-text-size: 0.875rem;
    --mdc-snackbar-supporting-text-weight: 400;
    --mdc-snackbar-container-shape: 4px;
    --mat-snack-bar-button-color: #abc7ff;
    --mat-table-background-color: #fdfbff;
    --mat-table-header-headline-color: #1a1b1f;
    --mat-table-row-item-label-text-color: #1a1b1f;
    --mat-table-row-item-outline-color: #74777f;
    --mat-table-header-headline-font: Roboto, sans-serif;
    --mat-table-header-headline-line-height: 1.25rem;
    --mat-table-header-headline-size: 0.875rem;
    --mat-table-header-headline-weight: 500;
    --mat-table-header-headline-tracking: 0.006rem;
    --mat-table-row-item-label-text-font: Roboto, sans-serif;
    --mat-table-row-item-label-text-line-height: 1.25rem;
    --mat-table-row-item-label-text-size: 0.875rem;
    --mat-table-row-item-label-text-weight: 400;
    --mat-table-row-item-label-text-tracking: 0.016rem;
    --mat-table-footer-supporting-text-font: Roboto, sans-serif;
    --mat-table-footer-supporting-text-line-height: 1.25rem;
    --mat-table-footer-supporting-text-size: 0.875rem;
    --mat-table-footer-supporting-text-weight: 400;
    --mat-table-footer-supporting-text-tracking: 0.016rem;
    --mat-table-header-container-height: 56px;
    --mat-table-footer-container-height: 52px;
    --mat-table-row-item-container-height: 52px;
    --mat-table-row-item-outline-width: 1px;
    --mdc-circular-progress-active-indicator-color: #005cbb;
    --mdc-circular-progress-active-indicator-width: 4px;
    --mdc-circular-progress-size: 48px;
    --mat-badge-background-color: #ba1a1a;
    --mat-badge-text-color: #ffffff;
    --mat-badge-disabled-state-background-color: rgba(186, 26, 26, 0.38);
    --mat-badge-disabled-state-text-color: #ffffff;
    --mat-badge-text-font: Roboto, sans-serif;
    --mat-badge-text-size: 0.688rem;
    --mat-badge-text-weight: 500;
    --mat-badge-large-size-text-size: 0.688rem;
    --mat-badge-container-shape: 9999px;
    --mat-badge-container-size: 16px;
    --mat-badge-small-size-container-size: 6px;
    --mat-badge-large-size-container-size: 16px;
    --mat-badge-legacy-container-size: unset;
    --mat-badge-legacy-small-size-container-size: unset;
    --mat-badge-legacy-large-size-container-size: unset;
    --mat-badge-container-offset: -12px 0;
    --mat-badge-small-size-container-offset: -6px 0;
    --mat-badge-large-size-container-offset: -12px 0;
    --mat-badge-container-overlap-offset: -12px;
    --mat-badge-small-size-container-overlap-offset: -6px;
    --mat-badge-large-size-container-overlap-offset: -12px;
    --mat-badge-container-padding: 0 4px;
    --mat-badge-small-size-container-padding: 0;
    --mat-badge-large-size-container-padding: 0 4px;
    --mat-badge-small-size-text-size: 0;
    --mat-bottom-sheet-container-text-color: #1a1b1f;
    --mat-bottom-sheet-container-background-color: #f5f3f7;
    --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
    --mat-bottom-sheet-container-text-line-height: 1.5rem;
    --mat-bottom-sheet-container-text-size: 1rem;
    --mat-bottom-sheet-container-text-tracking: 0.031rem;
    --mat-bottom-sheet-container-text-weight: 400;
    --mat-bottom-sheet-container-shape: 28px;
    --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
    --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
    --mat-standard-button-toggle-text-color: #1a1b1f;
    --mat-standard-button-toggle-state-layer-color: #1a1b1f;
    --mat-standard-button-toggle-selected-state-background-color: #dae2f9;
    --mat-standard-button-toggle-selected-state-text-color: #131c2b;
    --mat-standard-button-toggle-disabled-state-text-color: rgba(26, 27, 31, 0.38);
    --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(26, 27, 31, 0.38);
    --mat-standard-button-toggle-disabled-selected-state-background-color: rgba(26, 27, 31, 0.12);
    --mat-standard-button-toggle-divider-color: #74777f;
    --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
    --mat-standard-button-toggle-label-text-line-height: 1.25rem;
    --mat-standard-button-toggle-label-text-size: 0.875rem;
    --mat-standard-button-toggle-label-text-tracking: 0.006rem;
    --mat-standard-button-toggle-label-text-weight: 500;
    --mat-standard-button-toggle-height: 40px;
    --mat-standard-button-toggle-shape: 9999px;
    --mat-standard-button-toggle-background-color: transparent;
    --mat-standard-button-toggle-disabled-state-background-color: transparent;
    --mat-datepicker-calendar-date-selected-state-text-color: #ffffff;
    --mat-datepicker-calendar-date-selected-state-background-color: #005cbb;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(26, 27, 31, 0.38);
    --mat-datepicker-calendar-date-today-selected-state-outline-color: #005cbb;
    --mat-datepicker-calendar-date-focus-state-background-color: rgba(26, 27, 31, 0.12);
    --mat-datepicker-calendar-date-hover-state-background-color: rgba(26, 27, 31, 0.08);
    --mat-datepicker-toggle-active-state-icon-color: #44474e;
    --mat-datepicker-calendar-date-in-range-state-background-color: #d7e3ff;
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #e0e0ff;
    --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #dae2f9;
    --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #565e71;
    --mat-datepicker-toggle-icon-color: #44474e;
    --mat-datepicker-calendar-body-label-text-color: #1a1b1f;
    --mat-datepicker-calendar-period-button-text-color: #44474e;
    --mat-datepicker-calendar-period-button-icon-color: #44474e;
    --mat-datepicker-calendar-navigation-button-icon-color: #44474e;
    --mat-datepicker-calendar-header-text-color: #44474e;
    --mat-datepicker-calendar-date-today-outline-color: #005cbb;
    --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(26, 27, 31, 0.38);
    --mat-datepicker-calendar-date-text-color: #1a1b1f;
    --mat-datepicker-calendar-date-disabled-state-text-color: rgba(26, 27, 31, 0.38);
    --mat-datepicker-calendar-date-preview-state-outline-color: #005cbb;
    --mat-datepicker-range-input-separator-color: #1a1b1f;
    --mat-datepicker-range-input-disabled-state-separator-color: rgba(26, 27, 31, 0.38);
    --mat-datepicker-range-input-disabled-state-text-color: rgba(26, 27, 31, 0.38);
    --mat-datepicker-calendar-container-background-color: #e9e8ec;
    --mat-datepicker-calendar-container-text-color: #1a1b1f;
    --mat-datepicker-calendar-text-font: Roboto, sans-serif;
    --mat-datepicker-calendar-text-size: 1rem;
    --mat-datepicker-calendar-body-label-text-size: 0.875rem;
    --mat-datepicker-calendar-body-label-text-weight: 500;
    --mat-datepicker-calendar-period-button-text-size: 0.875rem;
    --mat-datepicker-calendar-period-button-text-weight: 500;
    --mat-datepicker-calendar-header-text-size: 0.875rem;
    --mat-datepicker-calendar-header-text-weight: 500;
    --mat-datepicker-calendar-container-shape: 16px;
    --mat-datepicker-calendar-container-touch-shape: 28px;
    --mat-datepicker-calendar-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mat-datepicker-calendar-header-divider-color: transparent;
    --mat-datepicker-calendar-date-outline-color: transparent;
    --mat-divider-color: #74777f;
    --mat-divider-width: 1px;
    --mat-expansion-container-background-color: #fdfbff;
    --mat-expansion-container-text-color: #1a1b1f;
    --mat-expansion-actions-divider-color: #74777f;
    --mat-expansion-header-hover-state-layer-color: rgba(26, 27, 31, 0.08);
    --mat-expansion-header-focus-state-layer-color: rgba(26, 27, 31, 0.12);
    --mat-expansion-header-disabled-state-text-color: rgba(26, 27, 31, 0.38);
    --mat-expansion-header-text-color: #1a1b1f;
    --mat-expansion-header-description-color: #44474e;
    --mat-expansion-header-indicator-color: #44474e;
    --mat-expansion-header-text-font: Roboto, sans-serif;
    --mat-expansion-header-text-size: 1rem;
    --mat-expansion-header-text-weight: 500;
    --mat-expansion-header-text-line-height: 1.5rem;
    --mat-expansion-header-text-tracking: 0.009rem;
    --mat-expansion-container-text-font: Roboto, sans-serif;
    --mat-expansion-container-text-line-height: 1.5rem;
    --mat-expansion-container-text-size: 1rem;
    --mat-expansion-container-text-tracking: 0.031rem;
    --mat-expansion-container-text-weight: 400;
    --mat-expansion-header-collapsed-state-height: 48px;
    --mat-expansion-header-expanded-state-height: 64px;
    --mat-expansion-container-shape: 12px;
    --mat-expansion-legacy-header-indicator-display: none;
    --mat-expansion-header-indicator-display: inline-block;
    --mat-grid-list-tile-header-primary-text-size: 400 1rem / 1.5rem Roboto, sans-serif;
    --mat-grid-list-tile-header-secondary-text-size: 400 0.875rem / 1.25rem Roboto, sans-serif;
    --mat-grid-list-tile-footer-primary-text-size: 400 1rem / 1.5rem Roboto, sans-serif;
    --mat-grid-list-tile-footer-secondary-text-size: 400 0.875rem / 1.25rem Roboto, sans-serif;
    --mat-icon-color: inherit;
    --mat-sidenav-container-background-color: #fdfbff;
    --mat-sidenav-container-text-color: #44474e;
    --mat-sidenav-content-background-color: #fdfbff;
    --mat-sidenav-content-text-color: #1a1b1f;
    --mat-sidenav-scrim-color: rgba(45, 48, 56, 0.4);
    --mat-sidenav-container-shape: 16px;
    --mat-sidenav-container-elevation-shadow: none;
    --mat-sidenav-container-width: 360px;
    --mat-sidenav-container-divider-color: transparent;
    --mat-stepper-header-icon-foreground-color: #fdfbff;
    --mat-stepper-header-selected-state-icon-background-color: #005cbb;
    --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
    --mat-stepper-header-edit-state-icon-background-color: #005cbb;
    --mat-stepper-header-edit-state-icon-foreground-color: #ffffff;
    --mat-stepper-container-color: #fdfbff;
    --mat-stepper-line-color: #74777f;
    --mat-stepper-header-hover-state-layer-color: rgba(47, 48, 51, 0.08);
    --mat-stepper-header-focus-state-layer-color: rgba(47, 48, 51, 0.12);
    --mat-stepper-header-label-text-color: #44474e;
    --mat-stepper-header-optional-label-text-color: #44474e;
    --mat-stepper-header-selected-state-label-text-color: #44474e;
    --mat-stepper-header-error-state-label-text-color: #ba1a1a;
    --mat-stepper-header-icon-background-color: #44474e;
    --mat-stepper-header-error-state-icon-foreground-color: #ba1a1a;
    --mat-stepper-container-text-font: Roboto, sans-serif;
    --mat-stepper-header-label-text-font: Roboto, sans-serif;
    --mat-stepper-header-label-text-size: 0.875rem;
    --mat-stepper-header-label-text-weight: 500;
    --mat-stepper-header-error-state-label-text-size: 0.875rem;
    --mat-stepper-header-selected-state-label-text-size: 0.875rem;
    --mat-stepper-header-selected-state-label-text-weight: 500;
    --mat-stepper-header-height: 72px;
    --mat-stepper-header-focus-state-layer-shape: 12px;
    --mat-stepper-header-hover-state-layer-shape: 12px;
    --mat-stepper-header-error-state-icon-background-color: transparent;
    --mat-sort-arrow-color: #1a1b1f;
    --mat-toolbar-container-background-color: #fdfbff;
    --mat-toolbar-container-text-color: #1a1b1f;
    --mat-toolbar-title-text-font: Roboto, sans-serif;
    --mat-toolbar-title-text-line-height: 1.75rem;
    --mat-toolbar-title-text-size: 1.375rem;
    --mat-toolbar-title-text-tracking: 0;
    --mat-toolbar-title-text-weight: 400;
    --mat-toolbar-standard-height: 64px;
    --mat-toolbar-mobile-height: 56px;
    --mat-tree-container-background-color: #fdfbff;
    --mat-tree-node-text-color: #1a1b1f;
    --mat-tree-node-text-font: Roboto, sans-serif;
    --mat-tree-node-text-size: 1rem;
    --mat-tree-node-text-weight: 400;
    --mat-tree-node-min-height: 48px
}

.mat-theme-loaded-marker {
    display: none
}

.mat-primary {
    --mat-stepper-header-selected-state-icon-background-color: #005cbb;
    --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
    --mat-stepper-header-edit-state-icon-background-color: #005cbb;
    --mat-stepper-header-edit-state-icon-foreground-color: #ffffff
}

.mat-primary.mat-icon {
    --mat-icon-color: #005cbb
}

.mat-primary.mat-mdc-checkbox {
    --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
    --mat-checkbox-label-text-color: #1a1b1f
}

.mat-primary.mat-mdc-slide-toggle {
    --mat-switch-track-outline-color: #74777f;
    --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
    --mat-switch-label-text-color: #1a1b1f
}

.mat-primary.mat-mdc-button-base {
    --mdc-icon-button-icon-color: #005cbb;
    --mat-icon-button-state-layer-color: #005cbb;
    --mat-icon-button-ripple-color: rgba(0, 92, 187, 0.12)
}

.mat-primary .mdc-list-item__start,
.mat-primary .mdc-list-item__end {
    --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
    --mat-checkbox-label-text-color: #1a1b1f
}

.mat-badge {
    --mat-badge-background-color: #005cbb;
    --mat-badge-text-color: #ffffff;
    --mat-badge-disabled-state-background-color: rgba(0, 92, 187, 0.38);
    --mat-badge-disabled-state-text-color: #ffffff
}

.mat-accent {
    --mat-option-selected-state-label-text-color: #00006e;
    --mat-option-selected-state-layer-color: #e0e0ff;
    --mdc-circular-progress-active-indicator-color: #343dff;
    --mat-full-pseudo-checkbox-selected-icon-color: #343dff;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #343dff;
    --mat-stepper-header-selected-state-icon-background-color: #343dff;
    --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
    --mat-stepper-header-edit-state-icon-background-color: #343dff;
    --mat-stepper-header-edit-state-icon-foreground-color: #ffffff
}

.mat-accent.mat-icon {
    --mat-icon-color: #343dff
}

.mat-accent.mat-mdc-checkbox {
    --mdc-checkbox-selected-pressed-icon-color: #343dff;
    --mdc-checkbox-selected-checkmark-color: #ffffff;
    --mdc-checkbox-selected-focus-icon-color: #343dff;
    --mdc-checkbox-selected-hover-icon-color: #343dff;
    --mdc-checkbox-selected-icon-color: #343dff;
    --mdc-checkbox-selected-focus-state-layer-color: #343dff;
    --mdc-checkbox-selected-hover-state-layer-color: #343dff;
    --mdc-checkbox-unselected-pressed-state-layer-color: #343dff;
    --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
    --mat-checkbox-label-text-color: #1a1b1f
}

.mat-accent.mat-mdc-slider {
    --mdc-slider-handle-color: #343dff;
    --mdc-slider-focus-handle-color: #343dff;
    --mdc-slider-hover-handle-color: #343dff;
    --mdc-slider-active-track-color: #343dff;
    --mdc-slider-with-tick-marks-active-container-color: #ffffff;
    --mdc-slider-label-container-color: #343dff;
    --mdc-slider-label-label-text-color: #ffffff;
    --mdc-slider-with-overlap-handle-outline-color: #ffffff;
    --mat-slider-ripple-color: #343dff;
    --mat-slider-hover-state-layer-color: rgba(52, 61, 255, 0.05);
    --mat-slider-focus-state-layer-color: rgba(52, 61, 255, 0.2)
}

.mat-accent.mat-mdc-tab-group,
.mat-accent.mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: #343dff;
    --mat-tab-header-active-focus-indicator-color: #343dff;
    --mat-tab-header-active-hover-indicator-color: #343dff
}

.mat-accent.mat-mdc-slide-toggle {
    --mdc-switch-selected-focus-state-layer-color: #343dff;
    --mdc-switch-selected-handle-color: #ffffff;
    --mdc-switch-selected-hover-state-layer-color: #343dff;
    --mdc-switch-selected-pressed-state-layer-color: #343dff;
    --mdc-switch-selected-focus-handle-color: #e0e0ff;
    --mdc-switch-selected-hover-handle-color: #e0e0ff;
    --mdc-switch-selected-pressed-handle-color: #e0e0ff;
    --mdc-switch-selected-focus-track-color: #343dff;
    --mdc-switch-selected-hover-track-color: #343dff;
    --mdc-switch-selected-pressed-track-color: #343dff;
    --mdc-switch-selected-track-color: #343dff;
    --mdc-switch-selected-icon-color: #00006e;
    --mat-switch-track-outline-color: #74777f;
    --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
    --mat-switch-label-text-color: #1a1b1f
}

.mat-accent.mat-mdc-form-field {
    --mat-select-focused-arrow-color: #343dff
}

.mat-accent.mat-mdc-radio-button {
    --mdc-radio-selected-focus-icon-color: #343dff;
    --mdc-radio-selected-hover-icon-color: #343dff;
    --mdc-radio-selected-icon-color: #343dff;
    --mdc-radio-selected-pressed-icon-color: #343dff;
    --mat-radio-checked-ripple-color: #343dff
}

.mat-accent.mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: #343dff;
    --mdc-linear-progress-track-color: #e0e0ff
}

.mat-accent.mat-mdc-form-field {
    --mdc-filled-text-field-caret-color: #343dff;
    --mdc-filled-text-field-focus-active-indicator-color: #343dff;
    --mdc-filled-text-field-focus-label-text-color: #343dff;
    --mdc-outlined-text-field-caret-color: #343dff;
    --mdc-outlined-text-field-focus-outline-color: #343dff;
    --mdc-outlined-text-field-focus-label-text-color: #343dff;
    --mat-form-field-focus-select-arrow-color: #343dff
}

.mat-accent.mat-datepicker-content {
    --mat-datepicker-calendar-date-selected-state-text-color: #ffffff;
    --mat-datepicker-calendar-date-selected-state-background-color: #343dff;
    --mat-datepicker-calendar-date-today-selected-state-outline-color: #343dff;
    --mat-datepicker-calendar-date-in-range-state-background-color: #e0e0ff;
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #d7e3ff;
    --mat-datepicker-calendar-date-today-outline-color: #343dff;
    --mat-datepicker-calendar-date-preview-state-outline-color: #343dff
}

.mat-accent.mat-mdc-button-base {
    --mdc-text-button-label-text-color: #343dff;
    --mdc-protected-button-label-text-color: #343dff;
    --mdc-filled-button-container-color: #343dff;
    --mdc-filled-button-label-text-color: #ffffff;
    --mdc-outlined-button-label-text-color: #343dff;
    --mat-text-button-state-layer-color: #343dff;
    --mat-text-button-ripple-color: rgba(52, 61, 255, 0.12);
    --mat-protected-button-state-layer-color: #343dff;
    --mat-protected-button-ripple-color: rgba(52, 61, 255, 0.12);
    --mat-filled-button-state-layer-color: #ffffff;
    --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.12);
    --mat-outlined-button-state-layer-color: #343dff;
    --mat-outlined-button-ripple-color: rgba(52, 61, 255, 0.12);
    --mdc-icon-button-icon-color: #343dff;
    --mat-icon-button-state-layer-color: #343dff;
    --mat-icon-button-ripple-color: rgba(52, 61, 255, 0.12)
}

.mat-accent.mat-mdc-standard-chip {
    --mdc-chip-elevated-selected-container-color: #e0e0ff;
    --mdc-chip-selected-hover-state-layer-color: #00006e;
    --mdc-chip-selected-focus-state-layer-color: #00006e;
    --mdc-chip-selected-label-text-color: #00006e;
    --mdc-chip-with-icon-selected-icon-color: #00006e;
    --mat-chip-selected-trailing-action-state-layer-color: #00006e;
    --mat-chip-selected-trailing-icon-color: #00006e
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
    --mdc-checkbox-selected-pressed-icon-color: #343dff;
    --mdc-checkbox-selected-checkmark-color: #ffffff;
    --mdc-checkbox-selected-focus-icon-color: #343dff;
    --mdc-checkbox-selected-hover-icon-color: #343dff;
    --mdc-checkbox-selected-icon-color: #343dff;
    --mdc-checkbox-selected-focus-state-layer-color: #343dff;
    --mdc-checkbox-selected-hover-state-layer-color: #343dff;
    --mdc-checkbox-unselected-pressed-state-layer-color: #343dff;
    --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
    --mat-checkbox-label-text-color: #1a1b1f;
    --mdc-radio-selected-focus-icon-color: #343dff;
    --mdc-radio-selected-hover-icon-color: #343dff;
    --mdc-radio-selected-icon-color: #343dff;
    --mdc-radio-selected-pressed-icon-color: #343dff;
    --mat-radio-checked-ripple-color: #343dff
}

.mat-accent.mat-mdc-fab,
.mat-accent.mat-mdc-mini-fab {
    --mdc-fab-container-color: #e0e0ff;
    --mdc-fab-small-container-color: #e0e0ff;
    --mat-fab-foreground-color: #00006e;
    --mat-fab-state-layer-color: #00006e;
    --mat-fab-ripple-color: rgba(0, 0, 110, 0.12);
    --mat-fab-small-foreground-color: #00006e;
    --mat-fab-small-state-layer-color: #00006e;
    --mat-fab-small-ripple-color: rgba(0, 0, 110, 0.12)
}

.mat-badge-accent {
    --mat-badge-background-color: #343dff;
    --mat-badge-text-color: #ffffff;
    --mat-badge-disabled-state-background-color: rgba(52, 61, 255, 0.38);
    --mat-badge-disabled-state-text-color: #ffffff
}

.mat-warn {
    --mat-option-selected-state-label-text-color: #410002;
    --mat-option-selected-state-layer-color: #ffdad6;
    --mdc-circular-progress-active-indicator-color: #ba1a1a;
    --mat-full-pseudo-checkbox-selected-icon-color: #ba1a1a;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ba1a1a;
    --mat-stepper-header-selected-state-icon-background-color: #ba1a1a;
    --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
    --mat-stepper-header-edit-state-icon-background-color: #ba1a1a;
    --mat-stepper-header-edit-state-icon-foreground-color: #ffffff
}

.mat-warn.mat-icon {
    --mat-icon-color: #ba1a1a
}

.mat-warn.mat-mdc-checkbox {
    --mdc-checkbox-selected-pressed-icon-color: #ba1a1a;
    --mdc-checkbox-selected-checkmark-color: #ffffff;
    --mdc-checkbox-selected-focus-icon-color: #ba1a1a;
    --mdc-checkbox-selected-hover-icon-color: #ba1a1a;
    --mdc-checkbox-selected-icon-color: #ba1a1a;
    --mdc-checkbox-selected-focus-state-layer-color: #ba1a1a;
    --mdc-checkbox-selected-hover-state-layer-color: #ba1a1a;
    --mdc-checkbox-unselected-pressed-state-layer-color: #ba1a1a;
    --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
    --mat-checkbox-label-text-color: #1a1b1f
}

.mat-warn.mat-mdc-slider {
    --mdc-slider-handle-color: #ba1a1a;
    --mdc-slider-focus-handle-color: #ba1a1a;
    --mdc-slider-hover-handle-color: #ba1a1a;
    --mdc-slider-active-track-color: #ba1a1a;
    --mdc-slider-with-tick-marks-active-container-color: #ffffff;
    --mdc-slider-label-container-color: #ba1a1a;
    --mdc-slider-label-label-text-color: #ffffff;
    --mdc-slider-with-overlap-handle-outline-color: #ffffff;
    --mat-slider-ripple-color: #ba1a1a;
    --mat-slider-hover-state-layer-color: rgba(186, 26, 26, 0.05);
    --mat-slider-focus-state-layer-color: rgba(186, 26, 26, 0.2)
}

.mat-warn.mat-mdc-tab-group,
.mat-warn.mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: #ba1a1a;
    --mat-tab-header-active-focus-indicator-color: #ba1a1a;
    --mat-tab-header-active-hover-indicator-color: #ba1a1a
}

.mat-warn.mat-mdc-slide-toggle {
    --mdc-switch-selected-focus-state-layer-color: #ba1a1a;
    --mdc-switch-selected-handle-color: #ffffff;
    --mdc-switch-selected-hover-state-layer-color: #ba1a1a;
    --mdc-switch-selected-pressed-state-layer-color: #ba1a1a;
    --mdc-switch-selected-focus-handle-color: #ffdad6;
    --mdc-switch-selected-hover-handle-color: #ffdad6;
    --mdc-switch-selected-pressed-handle-color: #ffdad6;
    --mdc-switch-selected-focus-track-color: #ba1a1a;
    --mdc-switch-selected-hover-track-color: #ba1a1a;
    --mdc-switch-selected-pressed-track-color: #ba1a1a;
    --mdc-switch-selected-track-color: #ba1a1a;
    --mdc-switch-selected-icon-color: #410002;
    --mat-switch-track-outline-color: #74777f;
    --mat-switch-disabled-unselected-track-outline-color: #1a1b1f;
    --mat-switch-label-text-color: #1a1b1f
}

.mat-warn.mat-mdc-form-field {
    --mat-select-focused-arrow-color: #ba1a1a
}

.mat-warn.mat-mdc-radio-button {
    --mdc-radio-selected-focus-icon-color: #ba1a1a;
    --mdc-radio-selected-hover-icon-color: #ba1a1a;
    --mdc-radio-selected-icon-color: #ba1a1a;
    --mdc-radio-selected-pressed-icon-color: #ba1a1a;
    --mat-radio-checked-ripple-color: #ba1a1a
}

.mat-warn.mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: #ba1a1a;
    --mdc-linear-progress-track-color: #ffdad6
}

.mat-warn.mat-mdc-form-field {
    --mdc-filled-text-field-caret-color: #ba1a1a;
    --mdc-filled-text-field-focus-active-indicator-color: #ba1a1a;
    --mdc-filled-text-field-focus-label-text-color: #ba1a1a;
    --mdc-outlined-text-field-caret-color: #ba1a1a;
    --mdc-outlined-text-field-focus-outline-color: #ba1a1a;
    --mdc-outlined-text-field-focus-label-text-color: #ba1a1a;
    --mat-form-field-focus-select-arrow-color: #ba1a1a
}

.mat-warn.mat-datepicker-content {
    --mat-datepicker-calendar-date-selected-state-text-color: #ffffff;
    --mat-datepicker-calendar-date-selected-state-background-color: #ba1a1a;
    --mat-datepicker-calendar-date-today-selected-state-outline-color: #ba1a1a;
    --mat-datepicker-calendar-date-in-range-state-background-color: #ffdad6;
    --mat-datepicker-calendar-date-today-outline-color: #ba1a1a;
    --mat-datepicker-calendar-date-preview-state-outline-color: #ba1a1a
}

.mat-warn.mat-mdc-button-base {
    --mdc-text-button-label-text-color: #ba1a1a;
    --mdc-protected-button-label-text-color: #ba1a1a;
    --mdc-filled-button-container-color: #ba1a1a;
    --mdc-filled-button-label-text-color: #ffffff;
    --mdc-outlined-button-label-text-color: #ba1a1a;
    --mat-text-button-state-layer-color: #ba1a1a;
    --mat-text-button-ripple-color: rgba(186, 26, 26, 0.12);
    --mat-protected-button-state-layer-color: #ba1a1a;
    --mat-protected-button-ripple-color: rgba(186, 26, 26, 0.12);
    --mat-filled-button-state-layer-color: #ffffff;
    --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.12);
    --mat-outlined-button-state-layer-color: #ba1a1a;
    --mat-outlined-button-ripple-color: rgba(186, 26, 26, 0.12);
    --mdc-icon-button-icon-color: #ba1a1a;
    --mat-icon-button-state-layer-color: #ba1a1a;
    --mat-icon-button-ripple-color: rgba(186, 26, 26, 0.12)
}

.mat-warn.mat-mdc-standard-chip {
    --mdc-chip-elevated-selected-container-color: #ffdad6;
    --mdc-chip-selected-hover-state-layer-color: #410002;
    --mdc-chip-selected-focus-state-layer-color: #410002;
    --mdc-chip-selected-label-text-color: #410002;
    --mdc-chip-with-icon-selected-icon-color: #410002;
    --mat-chip-selected-trailing-action-state-layer-color: #410002;
    --mat-chip-selected-trailing-icon-color: #410002
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
    --mdc-checkbox-selected-pressed-icon-color: #ba1a1a;
    --mdc-checkbox-selected-checkmark-color: #ffffff;
    --mdc-checkbox-selected-focus-icon-color: #ba1a1a;
    --mdc-checkbox-selected-hover-icon-color: #ba1a1a;
    --mdc-checkbox-selected-icon-color: #ba1a1a;
    --mdc-checkbox-selected-focus-state-layer-color: #ba1a1a;
    --mdc-checkbox-selected-hover-state-layer-color: #ba1a1a;
    --mdc-checkbox-unselected-pressed-state-layer-color: #ba1a1a;
    --mat-checkbox-disabled-label-color: rgba(26, 27, 31, 0.38);
    --mat-checkbox-label-text-color: #1a1b1f;
    --mdc-radio-selected-focus-icon-color: #ba1a1a;
    --mdc-radio-selected-hover-icon-color: #ba1a1a;
    --mdc-radio-selected-icon-color: #ba1a1a;
    --mdc-radio-selected-pressed-icon-color: #ba1a1a;
    --mat-radio-checked-ripple-color: #ba1a1a
}

.mat-display-large,
.mat-typography .mat-display-large,
.mat-typography h1 {
    font: 400 3.562rem / 4rem Roboto, sans-serif;
    letter-spacing: -0.016rem;
    margin: 0 0 .5em
}

.mat-display-medium,
.mat-typography .mat-display-medium,
.mat-typography h2 {
    font: 400 2.812rem / 3.25rem Roboto, sans-serif;
    letter-spacing: 0;
    margin: 0 0 .5em
}

.mat-display-small,
.mat-typography .mat-display-small,
.mat-typography h3 {
    font: 400 2.25rem / 2.75rem Roboto, sans-serif;
    letter-spacing: 0;
    margin: 0 0 .5em
}

.mat-headline-large,
.mat-typography .mat-headline-large,
.mat-typography h4 {
    font: 400 2rem / 2.5rem Roboto, sans-serif;
    letter-spacing: 0;
    margin: 0 0 .5em
}

.mat-headline-medium,
.mat-typography .mat-headline-medium,
.mat-typography h5 {
    font: 400 1.75rem / 2.25rem Roboto, sans-serif;
    letter-spacing: 0;
    margin: 0 0 .5em
}

.mat-headline-small,
.mat-typography .mat-headline-small,
.mat-typography h6 {
    font: 400 1.5rem / 2rem Roboto, sans-serif;
    letter-spacing: 0;
    margin: 0 0 .5em
}

.mat-title-large,
.mat-typography .mat-title-large {
    font: 400 1.375rem / 1.75rem Roboto, sans-serif;
    letter-spacing: 0
}

.mat-title-medium,
.mat-typography .mat-title-medium {
    font: 500 1rem / 1.5rem Roboto, sans-serif;
    letter-spacing: .009rem
}

.mat-title-small,
.mat-typography .mat-title-small {
    font: 500 .875rem / 1.25rem Roboto, sans-serif;
    letter-spacing: .006rem
}

.mat-body-large,
.mat-typography .mat-body-large,
.mat-typography {
    font: 400 1rem / 1.5rem Roboto, sans-serif;
    letter-spacing: .031rem
}

.mat-body-large p,
.mat-typography .mat-body-large p,
.mat-typography p {
    margin: 0 0 .75em
}

.mat-body-medium,
.mat-typography .mat-body-medium {
    font: 400 .875rem / 1.25rem Roboto, sans-serif;
    letter-spacing: .016rem
}

.mat-body-small,
.mat-typography .mat-body-small {
    font: 400 .75rem / 1rem Roboto, sans-serif;
    letter-spacing: .025rem
}

.mat-label-large,
.mat-typography .mat-label-large {
    font: 500 .875rem / 1.25rem Roboto, sans-serif;
    letter-spacing: .006rem
}

.mat-label-medium,
.mat-typography .mat-label-medium {
    font: 500 .75rem / 1rem Roboto, sans-serif;
    letter-spacing: .031rem
}

.mat-label-small,
.mat-typography .mat-label-small {
    font: 500 .688rem / 1rem Roboto, sans-serif;
    letter-spacing: .031rem
}