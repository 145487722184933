@import './driverjs.css';
.driver-popover {
    border-radius: 12px !important;
}


.driver-popover-next-btn {
    border-radius: 12px !important;
    padding: 0.5rem 1rem !important;
    background-color: #FF6A2A !important;
    color: white !important;
    font-weight: 500 !important;
    text-shadow: none !important;
    border: none !important;


}


.driver-popover-prev-btn {
    border-radius: 12px !important;
    padding: 0.5rem 1rem !important;
    background-color: #ffff !important;
    color: black !important;
    font-weight: 500 !important;
    text-shadow: none !important;
    border: 0.5px solid gray !important;
}